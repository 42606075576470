import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Booking } from 'src/app/_shared/booking/ model/booking';
import { User } from 'src/app/_shared/user/model/user';
import { UserService } from 'src/app/_shared/user/service/user.service';
import { ShowService } from '../service/show.service';
import { LocalStorageService } from 'src/app/_shared/service/localstorage.service';

@Component({
  selector: 'mp-all-movies',
  templateUrl: './all-movies.component.html',
  styleUrls: ['./all-movies.component.scss']
})
export class AllMoviesComponent implements OnInit {

  nowShowingMovies: any;
  fetchPreference: any;
  preferredGenre: any;
  nowshowing: any;
  city: string;
  user: User;
  loading: boolean = true;
  imageLoader = true;
  @ViewChild('selectFormat') selectFormat: TemplateRef<any>;
  @Input() public eventgenreFilters: Subject<any>;
  upcomingScroll: any;
  modalConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: ' modal-dialog-centered ',
  };
  popularMovieList: any = [];
  isPopularMoviesError: boolean = false;
  isRecommmended: boolean = false;
  constructor(
    public showService: ShowService, 
    private router: Router,
    public userService: UserService, 
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.checkQueryparam();
    this.getPopularMovies();
  }

  checkQueryparam(){
    this.route.queryParams.subscribe(params => {
      this.isRecommmended = params['isRecommended'];
    });
  }
  getPopularMovies() {
    this.loading = true;
    this.popularMovieList = [];
    this.isPopularMoviesError = false;
    let movies: any = this.localStorageService.getItem('popularMovies')
    if(movies.length > 0){
      this.loading = false;
      this.isPopularMoviesError = false;
      this.popularMovieList = movies;
      if(this.isRecommmended){
        this.sortMovies(this.popularMovieList)
      }
    }else{
      this.getAllPopularMoviesfromAPI();
    }
  }

  getAllPopularMoviesfromAPI() {
    this.loading = true;
    this.popularMovieList = [];
    this.isPopularMoviesError = false;
    this.showService.getAllMovies().subscribe({
      next: (response) => {
        this.loading = false;
        if(response.movies.length > 0){
          this.isPopularMoviesError = false;
          this.popularMovieList = response.movies;
          this.localStorageService.setItem('popularMovies', this.popularMovieList)
          if(this.isRecommmended){
            this.sortMovies(this.popularMovieList)
          }
        }else{
          this.isPopularMoviesError = true;
        }
      },
      error: (err) => {
        this.loading = false;
        this.isPopularMoviesError = true;
        console.log("erro in now showing fetching::", err);
      }
    });
  }

  onMovieSelect(movie: any) {
    const booking = {} as Booking;
    Object.assign(booking, movie);
    booking.city = this.city;
    console.log(booking);
    this.showService.setBooking(booking);
    // if (movie.groupId > 0 && movie.movieChoice.length > 1) {
    //   this.modalService.show(this.selectFormat, this.modalConfig);
    // }
    // else {
      this.router.navigate(["/shows"]);
    // }
  }

  sortMovies(data: any){
    data.sort((a: any, b: any) => {
      const dateA = new Date(a.releaseDate.split('-').reverse().join('-'));
      const dateB = new Date(b.releaseDate.split('-').reverse().join('-'));
    
      return dateB.getTime() - dateA.getTime();
    });
  }
}

