// local-storage.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  // Set data in local storage
  setItem(key: any, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Get data from local storage
  getItem(key: any):any {
    let item: any = localStorage.getItem(key);
    let val: any = JSON.parse(item)
    return val
  }

  // Clear data from local storage
  clear(): void {
    localStorage.clear();
  }
}
