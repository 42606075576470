<div class="container-fluid py-2 position-relative bg-0F1628 d-none d-lg-flex">
    <div class="row col-xl-11 py-5">
        <div class="offset-1 col-xl-12">
            <div class="row align-items-start">
                <div class="col-xl-3 text-center" style="align-self: center;">
                    <img class="w-100 mw-100" src="assets/images/img/homelogo.png" (click)="route()" alt="logo">
                </div>
                <div class="col-xl-2 mt-4  pointer footer_space">
                    <ul class="">
                        <li class="list-unstyled"><a class="go-book foot-link " (click)="route()">Home</a>
                        </li>
                        <li class="list-unstyled mt-1"><a class="go-book foot-link"
                                (click)="switchShow('venues')">Cinemas</a></li>
                    </ul>
                </div>
                <div class="col-xl-2 mt-4 pointer">
                    <ul class="">
                        <li class="list-unstyled"><a class="go-book foot-link  " (click)="termsandConditions()">Terms &
                                Conditions</a></li>
                        <li class="list-unstyled mt-1"><a class="go-book foot-link " (click)="privacyPolicy()">Privacy
                                Policies</a></li>
                    </ul>
                </div>
                <div class="col-xl-2 mt-4  pointer">
                    <ul class="">
                        <li class="list-unstyled"><a class="go-book foot-link  " (click)="faq()">Faq</a></li>
                        <li class="list-unstyled"><a class="go-book foot-link " (click)="contactus()">Contact
                                Us</a></li>
                    </ul>
                </div>
                <div class="col-xl-3 mt-3">
                    <p class="go-book text-white text-capitalize font-1">Subscribe Now</p>
                    <form class="flex form" role="form" method="post" accept-charset="UTF-8" noValidate
                        [formGroup]="loginForm">
                        <div class="form-group">
                            <input id="inputEmail"
                                class="col-xl-12 bg-020A1C border-secondary shadow-none border go-light text-white rounded-5 mb-3 font-weight-bolder"
                                type="email" name="email" formControlName="email" placeholder="Enter Email "
                                aria-label=" Enter Email " required>
                            <div class="position-absolute">
                                <span class="text-danger" *ngIf="
                        loginForm.controls['email'].hasError('required') &&
                        (loginForm.controls['email'].dirty ||
                        loginForm.controls['email'].touched)
                      ">This field is required</span>
                                <span class="text-danger" *ngIf="
                        loginForm.controls['email'].hasError('pattern') &&
                        (loginForm.controls['email'].dirty ||
                        loginForm.controls['email'].touched)
                      ">Please enter valid email address
                                </span>
                            </div>
                        </div>
                        <button class="btn btn-primary go-book lead rounded-pill px-3 mx-3 h " type="submit"
                            (click)="goSubscribe()">Go</button>

                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid py-4 position-relative bg-0F1628 border-top border-light border-2 d-none d-lg-block">
    <div class="row align-items-center text-center">
        <div class="col-xl-12">
            <p class="go-book text-white mb-0 font-0-875" style="color: #a4a5a8">@2021 Right Reserved</p>
        </div>
    </div>
</div>
<!-- <ng-template #contactus>
    <mp-contact-us></mp-contact-us>
</ng-template> -->
<ng-template #venueTemplate>
    <div class="modal-bg bg-373D4A" id="venue" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="venueLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-custom-60">
            <div
                class="modal-content shadow-custom-9 bg-3e4453 border-bottom border-primary border-2 border-top-0 border-left-0 border-right-0">
                <div class="container-fluid py-4 bg-373D4A">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <span class="modal-title go-medium text-primary text-center font-weight-bolder h3"
                                id="venueLabel">Venue</span>
                            <button type="button" class="close fs-20 text-right text-9F9F9F text-shadow-0"
                                (click)="close()" (click)="clearText()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="!venueService.isErrorVenuebyCity else showVenues">
                    <div class="modal-body text-left px-5 border-top border-primary border-2">
                        <div class="row">
                            <div class="col-xl-10 mx-auto">
                                <div class="input-group my-3">
                                    <input
                                        class="form-control p-4 go-book text-primary font-weight-bolder border border-primary border-right-0 bg-secondary border-2 border-radius-top-left font-1-4"
                                        type="search" placeholder="Search" id="example-search-input" [(ngModel)]="query"
                                        autocomplete="off">
                                    <span class="input-group-append">
                                        <div
                                            class="input-group-text bg-secondary border-2 border-primary border-left-0 border-radius-top-right">
                                            <img src="assets/images/img/search-primary.svg">
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-12 my-4 fix-height overflow-auto">
                                <div class="row">
                                    <ng-container *ngIf="venueService.venuescity | async as venuescity">
                                        <div class="col-xl-4"
                                            *ngFor="let venuess of venuescity | search:query:'venueName'">
                                            <div *ngIf="venuess.success!= false">
                                                <div class="bg-373d4b">
                                                    <p class="pt-3 pb-3 pl-5 form-check-label go-book lead text-white pointer"
                                                        (click)="onVenueSelect(venuess.venueId, venuess.venueName,venuess.addressLine1,venuess.addressLine2)">
                                                        {{venuess.venueName}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div *ngIf="venuess.success== false">
                                                <p class="pt-3 pb-3 pl-5 form-check-label go-book lead text-white">
                                                    No Result Found
                                                </p>
                                                <img class=" w-50 mx-50 "
                                                    src="../../../../assets/images/img/no-date.svg">
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #showVenues>
                    <div class="modal-body text-left px-5 border-top border-primary border-2 my-10">
                        <div class="row">
                            <div class="col-xl-12 text-center">
                                <p class="go-medium text-white h4">No Venue Available</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>