<h1 class="margin-left-50 mt-2 text-white">{{ 'app.common.error.oops' | translate }}</h1>
<div class="align-self-center error-container">
    <span class="status-code">500</span>
    <img
        class="align-self-center"
        src="../../../../assets/images/img/500.svg"
    />
</div>

<h1 class="margin-left-40 text-white">{{ 'app.common.error.500' | translate }}</h1>
