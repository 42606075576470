<div class="container-fluid px-lg-5 mt-2">
    <div class="row px-4 bg-020A1C">
        <div class="col-xl-12">
            <!-- header -->
            <div class="row align-items-start">
                <div class="col-xl-4 col-4 logo smLogo" style="align-self: center;">
                    <img class="mw-100 w-75 pointer desktop" (click)="route()"
                        src="assets/images/img/{{companyId}}/homelogo.png" alt="logo">
                    <img class="pointer mobile" *ngIf="showLogo" (click)="route()"
                        src="assets/images/img/{{companyId}}/homelogo.png" alt="logo">
                    <img class="pointer mobileView" *ngIf="HideLogo" (click)="route()"
                        src="assets/images/img/{{companyId}}/logo.svg" alt="logo">
                </div>
                <div class="col-xl-6 col-6 ml-auto text-right">
                    <div class="d-inline-block align-middle px-3" *ngIf="showSearch">
                        <!-- search bar  -->
                        <!-- <div class="input-group col-xl-9 ml-5 d-none d-xl-flex ml-auto">
                            <input
                                class="form-control border-right-0 border border-radius-right-0 rounded-6 border-primary go-light p-4 font-weight-bolder rounded bg-0F1628 text-white webKit"
                                autocomplete="off" type="search" placeholder="Search" id="example-search-input" (keyup)="
                    SearchBar()" [(ngModel)]="query" (click)="searchdropdown=true" [ngClass]="
                    query !== undefined && query != ''
      ? 'search'
      : 'displaynone'
  " (input)="updateSearch($event)" (mouseleave)="mouseLeave()" [value]="query" onblur="this.value=''">
                            <span class="input-group-append">
                                <div
                                    class="input-group-text bg-0F1628 border-left-0 border-radius-left-0 rounded-6 border-primary">
                                    <img class="mw-100 w-90 " src="./assets/images/img/search.svg">
                                </div>
                            </span>
                        </div> -->

                        <!-- search popup- -->

                        <div *ngIf="searchdropdown" (mouseleave)="searchdropdown = false">
                            <div class="position-absolute bg-3e4453 shadow-custom-9 hdr-sea-sp overflow-auto font-1-2">
                                <div class="row no-gutters align-items-start">
                                    <div class="row tab ">
                                        <ul class="nav  border-0 offset-lg-1 col-xl-12 col hm-tab-transition "
                                            id="myTab" role="tablist">
                                            <div class="col-xl-6 pl-2  border-bottom border-707070">
                                                <li class="nav-item text-center col-lg-2 col-6" role="presentation">
                                                    <a class="nav-link bg-transparent border-0  text-white "
                                                        id="Movies-tab" role="tab" aria-controls="Movies"
                                                        aria-selected="true" (click)="switchShow('Movies')" [ngClass]="{
                                                    'active': activeElem == 'Movies'
                                                }">
                                                        <div class="hm-tab-ico text-left text-ADADBE ">
                                                            <p class="mt-1 mb-0 lead go-book font-weight-bold">
                                                                Movies
                                                            </p>
                                                        </div>

                                                    </a>
                                                </li>
                                            </div>
                                            <div class="col-xl-6 pl-2 border-bottom border-707070">
                                                <li class="nav-item text-center col-lg-2 col-6" role="presentation">
                                                    <a class="nav-link bg-transparent border-0 text-white "
                                                        id="Venue-tab" role="tab" aria-controls="Venue"
                                                        aria-selected="false" (click)="switchShow('Venue')" [ngClass]="{
                                                        'active': activeElem == 'Venue'
                                                    }">
                                                        <div class="hm-tab-ico text-left text-ADADBE">
                                                            <p class="mt-1 mb-0 lead go-book font-weight-bold pointer">
                                                                Venue
                                                            </p>

                                                        </div>

                                                    </a>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                    <div class="tab-content col-xl-10 pl-2  tab_space">
                                        <div class="tab-pane " [ngClass]="{
                                            'active': activeElem == 'Movies'}" id="Movies" role="tabpanel"
                                            aria-labelledby="Movies-tab">
                                            <div *ngFor="let movie of nowShowingMovies|search:query:'movieName'">

                                                <div class="pl-4 px-3 py-4  text-left">
                                                    <div *ngIf="movie.success!=false">
                                                        <p class="go-book text-white mb-0 pointer"
                                                            (click)="searchMovie(movie)"
                                                            [innerHTML]="movie.movieName | highlight:searchTerm">
                                                            {{movie.movieName }} </p>
                                                    </div>
                                                    <div *ngIf="movie.success== false">
                                                        <p class="go-book text-primary mb-0">
                                                            No Movie Found
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>


                                        <div class="tab-pane" [ngClass]="{'active': activeElem == 'Venue'}" id="Venue"
                                            role="tabpanel" aria-labelledby="Venue-tab">
                                            <div *ngIf="venueService.venuescity | async as venuescity">
                                                <div *ngFor="let venuess of venuescity | search:query:'venueName'"
                                                    class="pl-4 px-3 py-4 text-left">
                                                    <div *ngIf="venuess.success!= false">

                                                        <p class="go-book text-white mb-0 pointer"
                                                            (click)="searchVenue(venuess.venueId, venuess.venueName, venuess.addressLine1, venuess.addressLine2)"
                                                            [innerHTML]="venuess.venueName | highlight:searchTerm">
                                                            {{venuess.venueName}}</p>

                                                    </div>
                                                    <div *ngIf="venuess.success== false">
                                                        <p class="go-book text-primary mb-0">
                                                            No Venue Found
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-inline-block align-middle">
                        <!-- <h5 class="go-book text-white font-weight-bold">EN</h5> -->
                        <!-- <div id="selected-lang" class="go-book text-white font-weight-bold" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                {{ language }}
                            </div> -->
                    </div>
                    <span class="btn-group" dropdown triggers="mouseover" #dp="bs-dropdown" *ngIf="hideLogin">

                        <span *ngIf="isLoggedIn" class="pointer d-none d-lg-block">
                            <a class="margin-space lead go-book text-white multiline-ellipsis position-relative" href
                                id="basic-link" dropdownToggle (click)="false" (mouseleave)="dp.hide()"
                                aria-controls="basic-link-dropdown">Hi, {{profilename}} </a>
                            <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu dropdown text-white"
                                role="menu" (mouseleave)="dp.hide()">
                                <li role="menuitem"><a class="dropdown-item lead go-book dropdown-text"
                                        (click)="navigateToProfile('profile')">{{'app.profile.profile'| translate}}</a>
                                </li>
                                <li class="divider dropdown-divider"></li>
                                <li role="menuitem"><a class="dropdown-item lead go-book text-black dropdown-text"
                                        (click)="navigateToProfile('bookingHistory')">Booking History</a>
                                </li>
                                <li class="divider dropdown-divider"></li>
                                <li role="menuitem"><a class="dropdown-item lead go-book dropdown-text"
                                        (click)="logoutUser()">{{'app.login.logout'| translate}}</a></li>
                            </ul>
                            <img class="logged-img" src="../../../assets/images/img/user.svg" alt="user">
                        </span>
                    </span>
                    <div class="d-inline-block align-middle px-2" *ngIf="hideLogin">
                        <ng-template [ngIf]="!isLoggedIn">
                            <img class="w-100 mw-100 pointer" src="../../../assets/images/img/Login.svg" alt="user"
                                (click)="openModal(login,'login')">
                            <div class="d-flex flex-column">
                                <div class="align-self-center">
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <!-- Signup Confirmation Modal -->

                    <!-- Signin Confirmation Modal -->

                    <!-- OTP Modal -->

                    <!-- Locations Modal -->

                    <!-- Locations search Modal -->

                    <!-- Locations Empty Modal -->

                    <!-- Language and Genre Modal -->

                    <!-- No Venue Modal -->

                    <!-- Venue Modal -->

                    <!-- <div *ngIf="hideLocation" class="d-inline-block align-middle text-center pointer"
                        (click)="openModal(location,'location')">
                        <img src="../../../assets/images/img/location.svg" alt="location">
                        <p class="go-book text-white mb-0 font-weight-bolder pointer">
                            {{locationText?locationText:"Location"}}</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #login>
    <mp-login [modalRef]="modalRef" (isLoggedIn)="getUserDetails()"></mp-login>
</ng-template>
<ng-template #login>
    <mp-login></mp-login>
</ng-template>
<!-- <ng-template #location>
    <mp-view-locations (emitLocation)="locationChange($event)"></mp-view-locations>
</ng-template> -->