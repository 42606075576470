<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
  <div
    class="modal-content shadow-custom-9 bg-3e4453 border-bottom border-primary border-2 border-top-0 border-left-0 border-right-0">
    <div class="container-fluid py-4 bg-transparent">
      <form class="form-class" role="form" method="post" accept-charset="UTF-8" id="forgot-nav" noValidate
        [formGroup]="forgotResetPasswordForm">

        <div class="container-fluid py-4 bg-373D4A">
          <div class="modal-body text-left border-top border-primary bg-373D4A">
            <!-- <div class="jumbotron"> -->

            <h2 class="reset modal-title go-medium text-primary text-center font-weight-bolder h3">Reset Password</h2>


            <div class="col-xl-6 mx-auto">
              <p class="go-light text-white font-weight-bolder mb-3">
              </p>
            </div>
            <div class="col-xl-3 mx-auto p-4  border-2 ">
              <img class="w-100 mw-100" src="assets/images/img/logo.svg" alt="logo">
            </div>

            <div class="col-xl-12 text-left text-white font-weight-bolder ml-3 my-4 form-group">
              <label for="password" class="color">Password</label>
              <input type="password" class="form-control border-0 shadow-sm px-8 input-text mt-1"
                placeholder="New Password" formControlName="password" required />
              <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                <div *ngIf="f.password.errors.required">Password is required.</div>
              </div>
            </div>
            <br />
            <div class="col-xl-12 text-left text-white font-weight-bolder ml-3 my-4 form-group">
              <label for="confirmpassword" class="color"> Confirm Password </label>
              <input type="password" class="form-control border-0 shadow-sm px-8 input-text mt-1"
                placeholder="Conform Password" formControlName="confirmpassword" required compare="password" />
              <div *ngIf="f.confirmpassword.touched && f.confirmpassword.invalid" class="alert alert-danger">
                <div *ngIf="f.confirmpassword.errors.required">
                  Password is required.
                </div>
                <div *ngIf="f.confirmpassword.errors.confirmedValidator">
                  Password and Confirm Password must be match.
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end div-mt">
              <button class="btn btn-primary go-book lead font-weight-bolder px-4 mb-3" data-toggle="modal"
                (click)="forgotPasswordReset()">
                Reset Password
              </button>
            </div>
            <!-- </div> -->
          </div>
        </div>

      </form>
    </div>
  </div>
</div>