import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ShowService } from '../../dashboard/service/show.service';
import { RecommendedMoviesService } from '../../profile/service/recommended-movies.service';
import {
    DomSanitizer
} from '@angular/platform-browser';
import { ModalService } from 'src/app/_core/service/modal.service';
import { MovieService } from '../service/movie.service';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/_core/state/store.service';
import { StoreType } from 'src/app/_core/constants/store-type.enum';
import { Booking } from 'src/app/_shared/booking/ model/booking';
import { BookingService } from 'src/app/_shared/booking/service/booking.service';
import { Subscription } from 'rxjs';
import { MovieStateService } from 'src/app/_shared/movie/service/movie-state.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/_shared/user/service/user.service';
import { Review } from '../../profile/model/review';
import { DatePipe } from '@angular/common';
import { UserReviewService } from '../../profile/service/user-review.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from 'src/app/_shared/user/model/user';
import { ExternalLibraryService } from '../../booking/seat-layout/util';
import { PaymentService } from '../../booking/service/payment.service';
import { finalize } from 'rxjs/operators';
import { ProfileService } from '../../profile/service/profile.service';

@Component({
    selector: 'mp-movie',
    templateUrl: './movie.component.html',
    styleUrls: ['./movie.component.scss'],
})
export class MovieComponent implements OnInit, OnDestroy {
    movieId: number;
    largePoster: string = "";
    movieTrailerUrl: any;
    recommendedMovie: any;
    movie: any;
    movies: any;
    booking: Booking
    navTab = "summary";
    subscription: Subscription = new Subscription();
    @ViewChild('trailerPlayer') trailerPlayer: TemplateRef<any>;
    @ViewChild("adduserreview") adduserreview: TemplateRef<any>;
    @ViewChild("loginTemplate") loginTemplate: TemplateRef<any>;
    @ViewChild("userInfoTemplate") userInfoTemplate: TemplateRef<any>;
    addReview: boolean;
    rating: number;
    description: string;
    titleComment: string;
    userId: string;
    login_form: FormGroup;
    userReviewForm:boolean=false; 
    slideConfig = {
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow:
            '<span class="slick-prev  position-absolute"><img class="mw-100" src="./assets/images/img/slider-left.svg"></span>',
        nextArrow:
            '<span class="slick-next  position-absolute"><img class="mw-100" src="./assets/images/img/slider-right.svg"></span>',
        autoplay: false,
    };
    slides = [342, 453, 846, 855, 234, 564, 744, 243];
    checkbookbutton: boolean;
    homebutton: boolean;
    showmore: boolean;
    showless = true;
    selectedIndex = 1;
    movieDetails: any;
    modalRef: BsModalRef;
    bookingId: any;
    razorpayResponse: any;
    showModal: boolean = false;
    bookingReferenceId: any;
    errorMsgs: any;
    navi: any;
    movieUrl: any;
    primaryNumber: any = '';
    primaryEmail: any = '';
    isUserinfoSubmitted: boolean = false;
    isLoading: boolean = false;
    user: User;
    profilename: string;
    isUserInfo: boolean = false;
    purchaseHistoryData:any;
    isLoggedIn:boolean = false;
    transactionDetails: any;
    amount: number = 59;
    constructor(
        public movieService: MovieService,
        public showService: ShowService,
        private modelService: ModalService,
        public recommendedMoviesService: RecommendedMoviesService,
        public domSanitizer: DomSanitizer,
        public movieStateService: MovieStateService,
        private route: Router,
        public userService: UserService,
        private storeService: StoreService,
        private bookingService: BookingService,
        private datePipe: DatePipe, fb: FormBuilder,
        public userReview: UserReviewService,
        private razorpayService: ExternalLibraryService,
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        public paymentService: PaymentService,
        private router: Router,
        private profileService: ProfileService
    ) {
        this.login_form = fb.group({
            title: [
                null,
                Validators.compose([
                    Validators.required,

                ])
            ],
            description: [
                null,
                Validators.compose([
                    Validators.required,

                ])
            ]
        });
        this.bookingService.get().subscribe(booking => this.booking = booking);
    }
    ngOnInit(): void {
        this.userService.getUser().subscribe((users) => {
            let user = users[0];
            this.userId = user.userId;
        });
        if(this.userId != null && this.userId != "_ANONYMOUS_USER")
        {
            this.userReviewForm=true;
        }
        this.addReview = false;
        this.movieId = this.booking.movieId;
        this.checkbookbutton = JSON.parse(this.storeService.get(
            'btnflag',
            StoreType.LOCAL
        ));
        this.movieService.getMovieDetails(this.movieId);
        this.getRecommendedMovie();
        this.alterDescriptionText();
        let movieStateSub = this.movieStateService.getMovie(this.movieId).subscribe((movie) => {
            if (null != movie[0] && undefined != movie[0]) {
                this.movie = movie[0];
                if (this.movie.posterUrl.indexOf('~') != -1) {
                    this.largePoster = this.movie.posterUrl.slice(this.movie.posterUrl.indexOf('~') + 1, this.movie.posterUrl.length);
                    this.storeService.put("posters", this.largePoster, StoreType.LOCAL);
                }
            } else {
                this.largePoster = this.storeService.get("posters", StoreType.LOCAL);
            }
        });
        this.movieTrailerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
            this.booking.trailerUrl);
        this.subscription.add(movieStateSub)
        this.getUserDetails();
        this.getMovieFare();
    }
    alterDescriptionText() {
        this.movieService.getMovieDetails(this.movieId);
        this.movieService.movie.subscribe((movie) => {
            this.movie = movie;
            console.log(movie)
            console.log("total reviews" + this.movie.criticReviews.length)
            if (this.movie.criticReviews.length == 0 || this.movie.criticReviews.length == 1 || this.movie.criticReviews == null) {
                this.showless = false;
                this.showmore = false;
            } else if (this.movie.criticReviews.length > this.selectedIndex) {
                this.showmore = true;
                this.showless = false;
            } else {
                this.showmore = false;
                this.showless = true
            }
            this.movieDetails = this.movie.criticReviews;
        });

    }
    showMoreDetails() {
        this.selectedIndex = this.movieDetails.length;
        this.showmore = false;
        this.showless = true;
    }
    showLessDetails() {
        this.selectedIndex = 1;
        this.showmore = true;
        this.showless = false;
    }
    navigatehomes() {
        this.checkbookbutton = JSON.parse(this.storeService.get(
            'btnflag',
            StoreType.LOCAL
        ));
        if (!this.checkbookbutton) {
            this.route.navigate(['/']);
        } else {
            this.route.navigate(['/shows']);
        }

    }
    async getRecommendedMovie() {
        let city = this.storeService.get(
            'moviepanda.location',
            StoreType.LOCAL
        );
        this.recommendedMoviesService.getRecommendedMovies(
            this.movieId,
            city
        );
        this.recommendedMoviesService.recommandedMovie.subscribe(
            (response) => {
                this.recommendedMovie = response;
            }
        );
    }
    onMovieSelect() { }

    navigateRecommendedMovie(recomanded: any, movieId: number) {
        this.booking.recommanded = recomanded;
        this.booking.movieId = movieId;
        this.bookingService.update(this.booking);
        this.route.routeReuseStrategy.shouldReuseRoute = () => false;
        this.route.onSameUrlNavigation = 'reload';
        this.route.navigate(['/shows']);
        this.modelService.hide();
    }
    movieModalClose() {
        this.modelService.hide();
    }


    showMovieShowDetails() {
        this.route.navigate(['/shows']);
    }
    switchTab(viewname: any) {
        if (viewname === 'summary') {
            this.addReview = false;
            console.log("summary");
            this.navTab = "summary";
        } if (viewname === 'criticReview') {
            this.addReview = false;
            console.log("criticReview");
            this.navTab = "criticReview";
        } if (viewname === 'userReview') {
            this.storeService.put('userReview', viewname, StoreType.LOCAL);
            console.log("userReview");
            this.navTab = "userReview";
        }
    }

    
    onClickPlay(templateName: TemplateRef<any>) {
        let config = {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: false,
            class: 'my-modal modal-dialog-centered modal-lg',
        };
        this.modelService.show(templateName, config);
    }

    checkUserInfo(){
        if(this.userId != null && this.userId != "_ANONYMOUS_USER"){
            this.isLoggedIn = true;
            this.openLoginModal(this.userInfoTemplate, '');
        }else{
            this.isLoggedIn = false;
            this.openLoginModal(this.loginTemplate, 'login');

        }
    }

    openLoginModal(template: TemplateRef<any>, componentName: String) {
        if(!this.isLoggedIn){
            this.modelService.hide();
            let config = {
                animated: true,
                keyboard: true,
                backdrop: true,
                ignoreBackdropClick: true,
                class: componentName == 'location' ? 'modal-dialog-centered modal-custom' : ' modal-dialog-centered ',
            }; 
            this.modelService.show(template, config);
        }else{
            if(this.user?.primaryEmail){
                this.primaryEmail = this.user?.primaryEmail
            }
            if(this.user?.primaryPhoneNumber){
                this.primaryNumber = this.user?.primaryPhoneNumber
            }
            if(this.user?.primaryEmail && this.user?.primaryPhoneNumber){
                //proceed to payment
                //   this.route.navigate(['/booking/summary']);
               this.transactionData('0');
            }else{
                this.modelService.hide();
                let config = {
                    animated: true,
                    keyboard: true,
                    backdrop: true,
                    ignoreBackdropClick: true,
                    class: componentName == 'location' ? 'modal-dialog-centered modal-custom' : ' modal-dialog-centered ',
                }; 
                this.modelService.show(template, config);
            }
        }
    }

    async transactionData(loadType: any){
        this.modelService.hide();
        this.isLoading = true;
        let payload: any ={
            "totalAmount": this.amount,
            "userId": this.userId,
            "source": "web",
            "movieId": 103,
            "location": "New York",
            "customerName": this.user?.firstName,
            "customerEmail": loadType == '0'? this.user?.primaryEmail : this.primaryEmail,
            "customerPhone": loadType == '0'?this.user?.primaryPhoneNumber: this.primaryNumber,
            "paymentMode":1
        }
        console.log(payload);
        await this.movieService.purchaseTransctionDetails(payload);
        this.movieService.transactionDetails.subscribe((data) => {
            this.transactionDetails = data;
            this.isLoading = false;
            this.payWithRazor();
        });
    }

    /** ====== After successfull payment =======*/
    /** starts here */
    async purchaseHistory(){
        let currentDate : any = new Date();
        let validDate: Date = new Date(currentDate);
        validDate.setDate(validDate.getDate() + 30);
        console.log('CurrenDate---------->', currentDate, 'ValidTo ------->', validDate)
        let payload: any ={
            "purchaseId": this.updatedPaymentData?.purchaseId,
            "userId": this.userId,
            "movieId": 103,
            "movieName": this.movie?.movieName, 
            "purchasedOn": currentDate,
            "totalAmount": this.updatedPaymentData?.orderAmount,
            "validTo": validDate
        }
            await this.movieService.purchaseHistory(payload);
            this.movieService.purchaseHistoryData.subscribe((data: any) => {
            this.purchaseHistoryData = data
            console.log(data)
            this.movieUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(data.playbackUrl);
            console.log(this.movieUrl)
            });
    }

    async updatePaymentDetails(){
        let updatePaymentPayload: any = {
            "razorpayPaymentId": this.razorpayResponse.razorpay_payment_id,
            "razorpaySignature": this.razorpayResponse.razorpay_signature,
            "razorpayOrderId": this.razorpayResponse.razorpay_order_id
        }
        await this.movieService.updatePaymentDetails(updatePaymentPayload);
        this.movieService.updatedPaymentData.subscribe((data: any)=>{
            this.updatedPaymentData = data;
            console.log(data)
            this.purchaseHistory();
        })
    }
    /** Ends here */

    // Razorpay
    payWithRazor() {
        this.modelService.hide();
        const RAZORPAY_OPTIONS: any = {
          "key": this.transactionDetails?.key,
          "order_id": this.transactionDetails?.razorpayOrderId,
          "amount": this.transactionDetails?.amount,
          "currency": this.transactionDetails?.customerCurrency,
          "name": "MOVIE PANDA",
          "image": "assets/images/img/logo.svg",
    
          "prefill": {
            "name": this.transactionDetails?.customerName,
            "email": this.transactionDetails?.customerEmail,
            "contact": this.transactionDetails?.customerPhone
          },
    
          "modal": {},
          "theme": {
            "color": "#272b37"
          }
    
        };
        // RAZORPAY_OPTIONS.amount = 2000,
        let rzp;
        RAZORPAY_OPTIONS['modal.ondismiss'] = this.razorpayReload.bind(this);
        RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
        this.ngZone.runOutsideAngular(() => { rzp = new this.razorpayService.nativeWindow.Razorpay(RAZORPAY_OPTIONS); rzp.open(); }
        );
    }
    
    public razorpayReload() {
        window.location.reload();
    }

    updatedPaymentData: any;
    public async razorPaySuccessHandler(response) {
        console.log("razor ppay syccess handler");
        // this.razorpayResponse = `Razorpay Response`;
        this.showModal = true;
        this.cd.detectChanges();
        // document.getElementById('razorpay-response').style.display = 'block';
        this.razorpayResponse = response;
        console.log("method", JSON.stringify(response));
        await this.updatePaymentDetails();

        this.storeService.put("razorpay_order_id", response.razorpay_order_id, StoreType.LOCAL);
        this.storeService.put("razorpay_payment_id", response.razorpay_payment_id, StoreType.LOCAL);
        this.storeService.put("razorpay_signature", response.razorpay_signature, StoreType.LOCAL);
    
        this.paymentService.getRazorpayPaymentDetail();
        let routeToConfirmation = false;
        this.paymentService.razorPayment.pipe(
          finalize(() => {
            console.log("finalize method called" + routeToConfirmation);
            if (routeToConfirmation) {
              this.ngZone.run(() =>
                this.router.navigate(['/ticketconfirmation'], { queryParams: { bookingReferenceId: this.bookingReferenceId, bookingId: this.bookingId } }).then(() => {
                  window.location.reload();
                }))
            }
          }),
        ).subscribe((res: any) => {
          this.bookingReferenceId = res.bookingReferenceId;
          this.bookingId = res.bookingId
          if (res.status.statusCode === "1001") {
            routeToConfirmation = true;
            console.log('completing the payment details');
            this.paymentService.razorPayment.complete();
          }
          else {
            this.errorMsgs = this.navi;
            routeToConfirmation = false;
          }
        });
    }

    review() {
        this.addReview = true;
    }

    proceedToPay(){
        this.isUserinfoSubmitted = true;
        if(this.primaryEmail && this.primaryNumber){
            this.transactionData('1');
            // this.route.navigate(['/booking/summary']);
        }else{
            // this.isUserinfo= false;
            console.log('Please enter all details')
        }

        setTimeout(() => {
            this.isUserinfoSubmitted= false;
        }, 3000);
    }
    close() {
        this.modelService.hide(this.modalRef);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    userReviewComments(movieId: number) {
        this.userService.getUser().subscribe((users) => {
            let user = users[0];
            this.userId = user.userId;

        });
         if (this.login_form.valid) {
        const review = new Review();
        review.movieId = movieId;
        review.title = this.titleComment;
        review.comments = this.description;
        review.userId = this.userId;
        review.companyId=this.storeService.get('companyId', StoreType.LOCAL);
        review.systemId="moviepanda";
        const todaysDate = new Date();
        const currentDate = this.datePipe.transform(todaysDate, "dd-MMM-yyyy");
        review.reviewDate = currentDate;
        review.rating = this.rating;
        this.userReview.addUserReview(review);
        this.movieService.getMovieDetails(this.movieId);
        this.userReview.userreview.subscribe(() => {
            if (this.userReview.isErrorFetchingUserReview === false) {
                var viewname = this.storeService.get('userReview', StoreType.LOCAL);
                if (viewname) {
                    this.navTab = 'userReview';
                    this.ngOnInit();
                }
            }
        })
    }
    }

    onClick(rating: number): void {
        this.rating = rating;
    }

    getUserDetails() {
        this.isUserInfo= false;
        this.userService.getUser().subscribe((users) => {
            if (users[0]) {
                this.isUserInfo= true;
                this.user = users[0];
                this.storeService.put(
                    'primaryEmail',
                    this.user.primaryEmail,
                    StoreType.LOCAL
                );
                this.storeService.put(
                    'primaryPhoneNumber',
                    this.user.primaryPhoneNumber,
                    StoreType.LOCAL
                );
                this.storeService.put(
                    'userId',
                    this.user.userId,
                    StoreType.LOCAL
                );
                if (this.user.userId != null && this.user.userId != '_ANONYMOUS_USER') {
                    // this.onClickPlay(this.disclaimer)
                    console.log(this.user)
                    this.profilename = this.user.firstName;
                    this.isLoggedIn = true;
                    if (this.user.lastName) {
                        this.profilename += ' ' + this.user.lastName;
                    }
                } else {
                    this.profilename = 'Guest';
                }
            }
        });
    }

    updatedPlaybackData: any;
    updatePlaybackUrl(){
        if(this.purchaseHistoryData?.userPurchaseId){
            let date = new Date()
            let payload: any ={
                "movieId": 103,
                "userId": this.userId,
                "userPurchaseId": this.purchaseHistoryData?.userPurchaseId,
                "playbackStarted": date
            }
            this.movieService.updatePlaybackUrl(payload);
            this.movieService.updatedPlaybackData.subscribe((data: any) => {
            this.updatedPlaybackData = data
            console.log(data)
            });
        }

    }

    updatePhoneNumberEmail(){
        let userData : any ={
            "primaryEmail": this.primaryEmail,
            "primaryPhoneNumber": this.primaryNumber
        }
        this.profileService.updateProfile(userData, 2);
        this.profileService.user.subscribe((data: any) =>{
            console.log(data)
        })
    }

    movieFareData: any;
    selectedMovieQuality: any = '';
    async getMovieFare(){
        await this.movieService.getMovieFare(this.movieId);
        this.movieService.movieFareData.subscribe((data: any)=>{
            this.movieFareData = data.movieQualityDetails; 
            console.log(data)
        })
    }
}
