<!-- <mp-header (outputSelectTab)="tab($event)"></mp-header>

<div class="jumbotron jumbotron-fluid position-relative">
  <div class="user one ml-5 mt-5"></div>
</div>

<div class="d-flex justify-content-end alignmargin">
  <tabset class="w-100" #staticTabs>
    <tab heading='{{"app.profile.profile" | translate}}'>
      <mp-profile></mp-profile>
    </tab>
    <tab heading='{{"app.profile.preference" | translate}}'>
      <mp-preference></mp-preference>
    </tab>
    <tab heading='{{"app.profile.booking history" | translate}}'>
      <mp-history></mp-history>
    </tab>
    <tab heading='{{"app.booking.wallet" | translate}}' >
      <mp-wallet ></mp-wallet>
    </tab>
  </tabset>
</div> -->
<mp-header (outputSelectTab)="tab($event)"></mp-header>
<div class="container-fluid">
    <div class="row px-lg-5 bg-0F1628 shadow-custom-5 py-lg-3">
        <div class="col-xl-12">
            <div class="row align-items-center">
                <div class="col-xl-1">
                    <div class="back-btn position-relative z-index-99">
                        <svg id="Component_49_32" data-name="Component 49 – 32" xmlns="http://www.w3.org/2000/svg"
                            width="38" height="38" viewBox="0 0 38 38">
                            <circle id="Ellipse_39" data-name="Ellipse 39" [routerLink]="['/']" class="cls-1" cx="19"
                                cy="19" r="19">
                            </circle>
                            <g id="Group_450" data-name="Group 450" transform="translate(-134.505 -201.219)">
                                <path id="Polygon_9" data-name="Polygon 9" class="cls-2" d="M5.5,0,11,6H0Z"
                                    transform="translate(142.505 226.219) rotate(-90)"></path>
                                <rect id="Rectangle_60" data-name="Rectangle 60" class="cls-2" width="17.492"
                                    height="2.018" transform="translate(147.683 219.709)"></rect>
                            </g>
                        </svg>
                    </div>
                </div>
                <!-- <div class="col-xl-3 position-absolute text-center">
                    <img class="w-35" src="assets/images/img/user.svg">
                </div> -->
                <div class="offset-lg-2 col-xl-6 text-center">
                    <span class="go-book text-white h4 font-weight-bolder">
                        Profile
                    </span>
                </div>
                <div class="col-xl-3 text-right d-none d-lg-block">
                    <img class="mw-100 w-9 hdr-ico outline-none" [routerLink]="['/']" src="assets/images/img/home.svg">
                </div>
            </div>
        </div>
    </div>
    <div class="row px-lg-5 shadow-custom-5">
        <div class="col-xl-12">
            <div class="">
                <div class="container-fluid">
                    <div>
                        <div class="row tab">
                            <div class="col-xl-8 col-12">
                                <ul class="nav nav-tabss nav-justified col-xl-10 border-0 mt-lg-6 hm-tabss" id="myTab"
                                    role="tablist">
                                    <li class="nav-item text-center pro-ul-tab col-4" role="presentation">
                                        <a class="nav-link bg-transparent border-0 py-3 text-white active show"
                                            id="home-tab" role="tab" aria-controls="home" aria-selected="true"
                                            (click)="switchShow('profile')" [ngClass]="{
                                              'active': activeElem == 'profile'
                                          }">
                                            <div class="hm-tab-ico pro-tab">
                                                <span class="mt-1 font-1-1 go-medium pointer">Profile</span>
                                            </div>
                                        </a>
                                    </li>
                                    <!-- <li class="nav-item text-center pro-ul-tab mx-lg-3 col-4" role="presentation">
                                        <a class="nav-link bg-transparent border-0 py-3 text-white" id="profile-tab"
                                            role="tab" aria-controls="profile" aria-selected="false"
                                            (click)="switchShow('preference')" [ngClass]="{
                                              'active': activeElem == 'preference'
                                          }">
                                            <div class="hm-tab-ico pro-tab">
                                                <span class="mt-1 font-1-1 go-medium pointer">Preference</span>
                                            </div>
                                        </a>
                                    </li> -->
                                    <li class="nav-item text-center pro-ul-tab col-4" role="presentation">
                                        <a class="nav-link bg-transparent border-0 py-3 text-white" id="messages-tab"
                                            role="tab" aria-controls="messages" aria-selected="false"
                                            (click)="switchShow('bookingHistory')" [ngClass]="{
                                              'active': activeElem == 'bookingHistory'
                                          }">
                                            <div class="hm-tab-ico pro-tab">
                                                <span class="mt-1 font-1-1 go-medium pointer">Purchase History</span>
                                            </div>
                                        </a>
                                    </li>
                                    <!-- <li class="nav-item text-center pro-ul-tab" role="presentation">
                    <a class="nav-link bg-transparent border-0 py-3 text-white" id="wallet-tab" role="tab"
                      aria-controls="wallet" aria-selected="false" (click)="switchShow('wallet')" [ngClass]="{
                                              'active': activeElem == 'wallet'
                                          }">
                      <div class="hm-tab-ico">
                        <h5 class="mt-1 font-1-1 go-medium pointer">Wallet</h5>
                      </div>
                    </a>
                  </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row mb-6">
                            <div class="col-xl-12">
                                <div class="tab-content">
                                    <div [ngClass]="{
                                          'active': activeElem == 'profile'}" class="tab-pane active show" id="home"
                                        role="tabpanel" aria-labelledby="home-tab">
                                        <mp-profile *ngIf="activeElem == 'profile'"></mp-profile>
                                        <!-- <div class="row align-items-start">
                                                <div class="col-xl-8 pt-5 pb-3 px-5 shadow-custom hm-pos-bg">
                                                    <div class="row no-gutters">
                                                        <div class="offset-1 col-xl-10">
                                                            <form>
                                                                <div class="form-group row">
                                                                    <div class="col-xl-5">
                                                                        <input type="text" class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary rounded" placeholder="First Name">
                                                                    </div>
                                                                    <div class="offset-2 col-xl-5">
                                                                        <input type="text" class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary rounded" placeholder="Last Name">
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row my-5">
                                                                    <div class="col-xl-5">
                                                                        <input type="email" class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary rounded" placeholder="Email">
                                                                    </div>
                                                                    <div class="offset-2 col-xl-5">
                                                                        <input type="tel" class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary rounded" placeholder="Phone">
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row my-5">
                                                                    <div class="col-xl-5">
                                                                        <input type="date" class="form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary rounded" placeholder="Birthday">
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-xl-12">
                                                                        <p class="go-ligt lead text-white font-weight-bolder ">Gender</p>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <div class="form-check form-check-inline col-xl-1">
                                                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1">
                                                                        <label class="form-check-label form-check-label go-light text-white font-weight-bolder ml-2" for="exampleRadios1">
                                                                            Male
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline col-xl-1">
                                                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                                                        <label class="form-check-label form-check-label go-light text-white font-weight-bolder ml-2" for="exampleRadios2">
                                                                            Female
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group my-5">
                                                                    <div class="form-check form-check-inline col-xl-2">
                                                                        <button type="submit" class="btn btn-primary go-book lead font-weight-bolder col-xl-12">Submit</button>
                                                                    </div>
                                                                    <div class="form-check form-check-inline col-xl-2">
                                                                        <button type="submit" class="btn btn-secondary go-book lead font-weight-bolder col-xl-12">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4">
                                                    <div class="row">
                                                        <div class="offset-1 col-xl-11">
                                                            <img class="w-100 mw-100" src="assets/images/img/profile_panda.png">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                    </div>
                                    <div [ngClass]="{
                                          'active': activeElem == 'preference'}" class="tab-pane" id="profile"
                                        role="tabpanel" aria-labelledby="profile-tab">
                                        <!-- preference -->
                                        <mp-preference *ngIf="activeElem == 'preference'"></mp-preference>
                                    </div>
                                    <div [ngClass]="{
                                          'active': activeElem == 'bookingHistory'}" class="tab-pane" id="messages"
                                        role="tabpanel" aria-labelledby="messages-tab">
                                        <mp-history *ngIf="activeElem == 'bookingHistory'"></mp-history>
                                    </div>
                                    <div [ngClass]="{
                                          'active': activeElem == 'wallet'}" class="tab-pane" id="wallet"
                                        role="tabpanel" aria-labelledby="wallet-tab">
                                        <mp-wallet *ngIf="activeElem == 'wallet'"></mp-wallet>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<mp-footer></mp-footer>