<!-- Signin Confirmation Modal -->
<div *ngIf="!passwordOtp && !switchtoForgotPassword">
    <div class="modal-bg" id="signin" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="signinLabel" aria-hidden="true">
        <div class="modal-dialog-centered modal-dialog-scrollable modal-custom-30">
            <div
                class="modal-content shadow-custom-9 bg-modal-lin border-bottom border-primary border-4 border-top-0 border-left-0 border-right-0">
                <div class="container-fluid pt-4 pb-2 bg-0F1628 ">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <span class="modal-title go-medium text-primary text-center h3"
                                id="signinLabel">{{header}}</span>
                            <button type="button"
                                class="close fs-20 text-right text-9F9F9F text-shadow-0 position-absolute r-7"
                                aria-label="Close" (click)="close()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-left border-top border-primary bg-020A1C ">
                    <div class="">
                        <div class="col-xl-12 text-center mb-4">
                            <div class="col-xl-10 mx-auto">
                                <p class="go-light text-white mb-2">
                                    Welcome to MOVIEPANDA
                                </p>
                                <p class="go-light text-white mb-1">
                                    For Signin Please fill out all fields below
                                </p>
                            </div>
                            <div class="col-xl-3 mx-auto logo">
                                <img class="w-100 mw-100 desktop" src="assets/images/img/logo.svg" alt="logo">
                                <img class="offset-5 w-20 mw-100 mobile" src="assets/images/img/logo.svg" alt="logo">
                            </div>
                            <div class="my-3 col-xl-8 mx-auto">
                             <!--   <div class="row">
                                    <button class="btn btn-lg btn-primary font-1 bg-fb border-fb px-lg-5 mb-2 col-xl-12"
                                        (click)="signInWithFB()">
                                        <img class="w-7 mw-100" src="assets/images/img/fb.svg">
                                        <span class="go-book text-white ml-5">Sign in with
                                            Facebook</span>
                                    </button>
                                </div>-->
                                <div class="row">
                                    <button
                                        class="btn btn-lg btn-primary font-1 bg-white border-white px-lg-5 col-xl-12"
                                        (click)="signInWithGoogle()">
                                        <img class="w-15 mw-100" src="assets/images/img/google.svg">
                                        <span class="go-book text-231F20 ml-4">Sign in with
                                            Google</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-xl-10 mx-auto">
                                <p class="h4 go-book text-white">OR</p>
                            </div>
                        </div>
                        <div class="col-xl-8 mx-auto">
                            <form class="was-validated" role="form" method="post" accept-charset="UTF-8" id="login-nav"
                                noValidate [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="form-group form-row">
                                    <div class="input-group mb-2 mx-auto">


                                        <div class="input-group-prepend">
                                            <span *ngIf="!showflag"
                                                class="input-group-text bg-0F1628 border-primary border-right-0">
                                                <img *ngIf="!showflag" class="w-50 mw-100"
                                                    src="assets/images/img/ind.svg">
                                                <div *ngIf="!showflag">
                                                    <span class="go-book text-white font-weight-bolder ml-2">+91</span>
                                                </div>
                                            </span>

                                        </div>
                                        <input type="email" id="inputEmail" #userOption formControlName="email"
                                            class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary border-left-0 bg-0F1628 "
                                            placeholder="Email / Phone" aria-label="Email / Phone" (keyup)="
                    displayPassword(userOption.value)" autofocus="" aria-describedby="Email" required>

                                    </div>
                                    <span class="text-danger" *ngIf="
                  loginForm.controls['email'].hasError('required') &&
                  (loginForm.controls['email'].dirty ||
                  loginForm.controls['email'].touched)
                ">This field is required</span>
                                    <span class="text-danger" *ngIf="
                  loginForm.controls['email'].hasError('pattern') &&
                  (loginForm.controls['email'].dirty ||
                  loginForm.controls['email'].touched)
                ">Please enter valid email address
                                    </span>
                                    <span class="text-danger" *ngIf="loginService.errormsg">Invalid email or
                                        Password</span>
                                    <span class="text-danger" *ngIf="loginService.authenticateFlag">User Already
                                        Exist</span>
                                </div>
                            </form>
                            <form class="form" role="form" method="post" accept-charset="UTF-8" id="password-nav"
                                noValidate [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
                                <div class="form-group form-row">
                                    <div *ngIf="mailPassword" class="input-group mb-2 mx-auto">
                                        <ng-container *ngIf="!showPassword">
                                            <input id="inputPassword"
                                                class="form-control form-control-lg go-book text-primary font-weight-bolder border border-right-0 border-primary bg-0F1628 border-radius-right-0 rounded-left"
                                                placeholder="PasswordPassword" placeholder="Password" type="password"
                                                formControlName="password" aria-label="Password" id="pass"
                                                aria-describedby="pass" required>

                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text bg-0F1628 border-primary border-left-0 border-radius-left-0 rounded-right"
                                                    (click)="toggleShowPassword()" id=" pass">
                                                    <img class="w-75 mw-100" src="assets/images/img/visible.svg">
                                                </span>
                                            </div>
                                        </ng-container>

                                        <div id="pass" class="invalid-feedback">
                                            <div class="row align-items-center">
                                                <div class="col-xl-10">
                                                    <span> Please Enter a Email/Phone.</span>
                                                </div>
                                                <div class="col-xl-2 text-right">
                                                    <svg width="1.0625em" height="1em" viewBox="0 0 17 16"
                                                        class="bi bi-exclamation-triangle-fill" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="showPassword">
                                            <input id="inputPassword"
                                                class="form-control form-control-lg go-book text-primary font-weight-bolder border border-right-0 border-primary bg-0F1628 border-2"
                                                placeholder="Password" type="text" formControlName="password"
                                                aria-label="Password" id="pass" aria-describedby="pass" required>

                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text bg-0F1628 border-2 border-primary border-left-0"
                                                    (click)="toggleShowPassword()" id=" pass">
                                                    <img class="w-75 mw-100" src="assets/images/img/visible-hide.svg">
                                                </span>
                                            </div>
                                        </ng-container>
                                        <div class="col-xl-10" style="position: relative; right: 14px;">
                                            <span class="text-danger" *ngIf="
                    passwordForm.controls['password'].hasError('required') &&
                    (passwordForm.controls['password'].dirty ||
                    passwordForm.controls['password'].touched)
                  ">Password field is required</span>
                                            <span class="text-danger" *ngIf="
                    passwordForm.controls['password'].hasError('pattern') &&
                    (passwordForm.controls['password'].dirty ||
                    passwordForm.controls['password'].touched)
                  ">Please enter valid password
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- </div> -->
                            <div class="form-group mb-3 text-center">
                                <button type="button"
                                    class="btn gradient-button gradient-button-4 go-book lead  px-4 mb-3"
                                    (click)="otpTrigger()" (click)="startTimer()" *ngIf="!swithtoRegister"
                                    [disabled]="!loginForm.valid && !passwordForm.valid">Login</button>
                                <button type="submit"
                                    class="btn gradient-button gradient-button-4 go-book lead  px-4 mb-3"
                                    (click)="otpPhoneRegister()" (click)="startTimer()"
                                    *ngIf="swithtoRegister">Signup</button>
                                <div class="col-xl-11 mx-auto">
                                    <p class="go-light text-white mb-0">
                                        <a *ngIf="!swithtoRegister">New to Movie Panda?</a>&nbsp; <a
                                            class=" text-info text-decoration-none" *ngIf="swithtoRegister && signIn"
                                            (click)="loginorRegisterTitle('login')">Sign in</a>

                                        <a *ngIf="swithtoRegister"> Already have an account?</a>&nbsp; <a
                                            class="text-info text-decoration-none pointer"
                                            (click)="loginorRegisterTitle('register')" *ngIf="!swithtoRegister">Sign
                                            up</a>
                                        <a class=" text-info text-decoration-none pointer"
                                            *ngIf="swithtoRegister && !signIn"
                                            (click)="loginorRegisterTitle('login')">&nbsp;Sign in</a>
                                    </p>
                                    <p>
                                        <a class="go-light text-white text-decoration-none pointer"
                                            (click)="loginorRegisterTitle('forgotpass')" *ngIf="!swithtoRegister">Forgot
                                            Password?</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- OTP Modal -->
<div id="otp" data-backdrop="static" role="form" method="post" data-keyboard="false" tabindex="-1"
    aria-labelledby="otpLabel" aria-hidden="true" *ngIf="passwordOtp">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-custom-30">
        <div
            class="modal-content bg-transparent border-bottom border-primary border-2 border-top-0 border-left-0 border-right-0">
            <div class="container-fluid pt-4 pb-2 bg-0F1628">
                <div class="row">
                    <div class="col-xl-12 text-center">
                        <button type="button" class="close fs-20 text-right text-white text-shadow-0 mx-4"
                            aria-label="Close" (click)="close()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body bg-3e4453 text-left shadow-custom-9">
                <div class="">
                    <div class="col-xl-12 text-center my-4">
                        <div class="col-xl-10 mx-auto">
                            <p class="go-book text-primary h3 mb-5">
                                Verify your number
                            </p>
                            <p class="go-book text-white h5 mb-3">
                                Enter OTP sent to {{loginForm.value.email}}
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-9 mx-auto">
                        <form class="form d-flex flex-column" role="form" method="post" accept-charset="UTF-8"
                            autocomplete="off" id="otp-nav" noValidate [formGroup]="signForm">
                            <div class="form-group form-row my-5">
                                <!-- <div class="input-group mr-1 mx-auto col">

                  <input type="text"
                    class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary border"
                    #clearInput formControlName="otp" value="Clear" name="otp" maxlength="1" minlength="1"
                    [ngClass]="{ 'is-invalid': submitted && form.otp.errors }" (keypress)="keyPress($event)"
                    aria-label="Email / Phone" aria-describedby="Email" />
                </div> -->
                                <div class="input-group mr-1 mx-auto col"
                                    *ngFor="let input of formInput ;let i = index;">
                                    <input inputmode="numeric" type="tel" #formRow
                                        class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-0F1628 border"
                                        formControlName="{{input}}" name="input" maxlength="1"
                                        (keyup)="keyUpEvent($event, i);authenticateOtp()" (keypress)="keyPress($event)"
                                        aria-label="Email / Phone" aria-describedby="Email" />

                                </div>
                                <!-- <div class="input-group mr-1 mx-auto col">
                  <input type="text"
                    class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary border"
                    #clearInput formControlName="otp" value="Clear" name="otp" maxlength="1" minlength="1"
                    [ngClass]="{ 'is-invalid': submitted && form.otp.errors }" (keypress)="keyPress($event)"
                    aria-label="Email / Phone" aria-describedby="Email" />
                </div> -->
                                <!-- <div class="input-group mr-1 mx-auto col">
                  <input type="text"
                    class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary border"
                    #clearInput formControlName="otp" value="Clear" name="otp" maxlength="1" minlength="1"
                    [ngClass]="{ 'is-invalid': submitted && form.otp.errors }" (keypress)="keyPress($event)"
                    aria-label="Email / Phone" aria-describedby="Email" />
                </div>
                <div class="input-group mr-1 mx-auto col">
                  <input type="text"
                    class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary border"
                    #clearInput formControlName="otp" value="Clear" name="otp" maxlength="1" minlength="1"
                    [ngClass]="{ 'is-invalid': submitted && form.otp.errors }" (keypress)="keyPress($event)"
                    aria-label="Email / Phone" aria-describedby="Email" />
                </div>
                <div class="input-group mr-1 mx-auto col">
                  <input type="text"
                    class="form-control form-control-lg go-book text-primary font-weight-bolder border border-primary bg-secondary border"
                    #clearInput formControlName="otp" value="Clear" name="otp" maxlength="1" minlength="1"
                    [ngClass]="{ 'is-invalid': submitted && form.otp.errors }" (keypress)="keyPress($event)"
                    aria-label="Email / Phone" aria-describedby="Email" />
                </div> -->

                            </div>

                            <div class="text-danger" *ngIf="loginService.otpVerification">
                                {{"app.profile.otp-validation-error" | translate}}</div>
                            <div class="form-group mb-3 text-center">
                                <div class="col-xl-10 mx-auto">
                                    <p class="go-light text-white" *ngIf="timer">
                                        Didn't Receive OTP? <a class="text-info text-decoration-none ml-3"
                                            (click)="otpTrigger()" (click)="startTimer()">Resend
                                            OTP</a>
                                    </p>
                                </div>
                                <ng-container *ngIf="settimer">
                                    <p id="time" class="text-primary">
                                        {{"app.login.resend otp" | translate}} 00:{{ timeLeft }}
                                    </p>
                                </ng-container>
                                <button type="submit" class="btn btn-primary go-book lead px-4 mt-4 mb-3"
                                    [disabled]="!signForm.valid">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Forgot Password Model -->
<div *ngIf="switchtoForgotPassword && !NewPassword">
    <div id="signin" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="signinLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div
                class="modal-content shadow-custom-9 bg-3e4453 border-bottom border-primary border-2 border-top-0 border-left-0 border-right-0">
                <div class="container-fluid py-4 bg-0F1628 ">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <span class="modal-title go-medium text-primary text-center font-weight-bolder h3"
                                id="signinLabel">{{header}}</span>
                            <button type="button" class="close fs-20 text-right text-9F9F9F text-shadow-0"
                                aria-label="Close" (click)="close()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-left border-top border-primary bg-020A1C">
                    <div class="container-fluid pt-4 pb-2 ">
                        <div class="col-xl-12 text-center my-4">
                            <div class="col-xl-3 mx-auto p-4 rounded-circle">
                                <img class="w-100 mw-100" src="assets/images/img/logo.svg" alt="logo">
                            </div>
                        </div>
                        <div class="col-xl-8 mx-auto">
                            <form class="was-validated" role="form" method="post" accept-charset="UTF-8" id="login-nav"
                                noValidate [formGroup]="forgotPasswordForm">
                                <div class="form-group form-row">
                                    <div class="input-group mb-2 mx-auto bg-3e4453">

                                        <div class="input-group-prepend border border-primary">
                                        </div>
                                        <input type="email" class="form-control form-control-lg go-book text-primary 
                                        font-weight-bolder border border-primary border-left-0 bg-0F1628 "
                                            formControlName="emailPassword" placeholder="Email" aria-label="Email"
                                            autofocus="" aria-describedby="Email" required>

                                    </div>

                                    <span class="text-danger" *ngIf="
                  forgotPasswordForm.controls['emailPassword'].hasError('required') &&
                  (forgotPasswordForm.controls['emailPassword'].dirty ||
                  forgotPasswordForm.controls['emailPassword'].touched)
                ">This field is required</span>
                                    <span class="text-danger" *ngIf="
                  forgotPasswordForm.controls['emailPassword'].hasError('pattern') &&
                  (forgotPasswordForm.controls['emailPassword'].dirty ||
                  forgotPasswordForm.controls['emailPassword'].touched)
                ">Please enter valid email address
                                    </span>
                                    <span class="text-danger" *ngIf="NewPassword === false">
                                        User Not Exist
                                    </span>
                                </div>
                                <div class="form-group mb-3 text-center">
                                    <button type="button"
                                        class="btn btn-primary go-book lead font-weight-bolder px-4 mb-3"
                                        (click)="forgotPassword()">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Forgot password confirm popup -->
<div *ngIf="NewPassword">
    <div id="signin" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="signinLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div
                class="modal-content shadow-custom-9 bg-3e4453 border-bottom border-primary border-2 border-top-0 border-left-0 border-right-0">
                <div class="container-fluid py-4 bg-373D4A">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <span class="modal-title go-medium text-primary text-center font-weight-bolder h3"
                                id="signinLabel">{{header}}</span>

                        </div>
                    </div>
                </div>

                <div class="modal-body text-left border-top border-primary border-2 ">
                    <div class="container-fluid pt-4 pb-2 ">
                        <div class="col-xl-12 text-center my-4">
                            <!-- <div class="col-xl-3 mx-auto p-4 ">
                           
                        </div> -->
                        </div>
                        <div class="col-xl-9 mx-auto">
                            <div class="input-group mb-2 text-white  font-weight-bolder mx-auto col-xl-9 py-5">
                                "Your Password reset link has been sent to your registered Email Id"

                            </div>
                            <div class="form-group mb-3 text-center">
                                <button type="button" class="btn btn-primary go-book lead font-weight-bolder px-4 mb-3 "
                                    (click)="close()">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div *ngIf="isLoggedIn">
    <div class="modal-bg" id="signin" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="signinLabel" aria-hidden="true">
        <div class="modal-dialog-centered modal-dialog-scrollable modal-custom-30">
            <div
                class="modal-content shadow-custom-9 bg-modal-lin border-bottom border-primary border-4 border-top-0 border-left-0 border-right-0">
                <div class="container-fluid pt-4 pb-2 bg-0F1628 ">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <span class="modal-title go-medium text-primary text-center h3"
                                id="signinLabel">{{header}}</span>
                            <button type="button"
                                class="close fs-20 text-right text-9F9F9F text-shadow-0 position-absolute r-7"
                                aria-label="Close" (click)="close()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-left border-top border-primary bg-020A1C ">
                    <div class="">
                        <div class="col-xl-12 text-center mb-4">
                            <div class="col-xl-10 mx-auto">
                                <p class="go-light text-white mb-2">
                                    MOVIEPANDA
                                </p>
                                <p class="go-light text-white mb-1">
                                    For Signin Please fill out all fields below
                                </p>
                            </div>
                            <div class="col-xl-3 mx-auto logo">
                                <img class="w-100 mw-100 desktop" src="assets/images/img/logo.svg" alt="logo">
                                <img class="offset-5 w-20 mw-100 mobile" src="assets/images/img/logo.svg" alt="logo">
                            </div>
                         
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->