import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreType } from 'src/app/_core/constants/store-type.enum';
import { StoreService } from 'src/app/_core/state/store.service';
import { Booking } from 'src/app/_shared/booking/ model/booking';
import { ShowService } from '../service/show.service';
import { LocalStorageService } from 'src/app/_shared/service/localstorage.service';

@Component({
  selector: 'mp-popular-movies',
  templateUrl: './popular-movies.component.html',
  styleUrls: ['./popular-movies.component.scss']
})
export class PopularMoviesComponent implements OnInit {

  @ViewChild('monthScroll') movieScroll: ElementRef<any>;
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    // prevArrow:
    //   '<span class="slick-prev  position-absolute"><img class="mw-100" src="./assets/images/img/slider-left.svg"></span>',
    // nextArrow:
    //   '<span class="slick-next  position-absolute"><img class="mw-100" src="./assets/images/img/slider-right.svg"></span>',
    autoplay: false,
  };
  loading: boolean = true;
  imageLoader = true;
  upcoming: any;
  city: string;
  bookflag: boolean = false;
  selectedIndex = 1;
  fragment: any;
  popularMovieList: any =[];
  isPopularMoviesError: boolean = false;
  isLoading: boolean = false;
  @Input() isRecommendedMovies: boolean = true;
  @Input() isPopularMovies: boolean = true;
  constructor(
    public showService: ShowService, 
    private storeService: StoreService, 
    private router: Router,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getPopularMovies();
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.isLoading = true;
    setTimeout(() => (this.isLoading = false), 1000);
  }
  ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        document.querySelector('#' + this.fragment).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        this.route.queryParams.subscribe(c => {
          const params = Object.assign({}, c);
          delete params.dapp;
          this.router.navigate([], { relativeTo: this.route, queryParams: params });
        }).unsubscribe();
      }
    } catch (e) { }
  }
  onMovieSelect(movie: any) {
    const booking = {} as Booking;
    Object.assign(booking, movie);
    booking.city = this.city;
    this.showService.setBooking(booking);
    this.storeService.put('btnflag', this.bookflag, StoreType.LOCAL);
    this.router.navigate(["/shows"]);
  }

 async getPopularMovies() {
    this.loading = true;
    this.popularMovieList = [];
    this.isPopularMoviesError = false;
    let movies: any= [];
     movies = this.localStorageService.getItem('popularMovies');
    if(movies != null){
      this.loading = false;
      this.isPopularMoviesError = false;
      this.popularMovieList = movies;
      if(this.isRecommendedMovies){
        this.sortMovies( this.popularMovieList)
      };
    }else{
      if(this.popularMovieList.length == 0){
        await this.showService.getAllMovies().subscribe({
          next: (response) => {
            this.loading = false;
            if(response.movies.length > 0){
              this.isPopularMoviesError = false;
              this.popularMovieList = response.movies;
              if(this.isRecommendedMovies){
                this.sortMovies( this.popularMovieList)
              };
              this.localStorageService.setItem('popularMovies', this.popularMovieList)
              this.showService.setAllPopularMovies(this.popularMovieList);

            }else{
              this.isPopularMoviesError = true;
            }
          },
          error: (err) => {
            this.loading = false;
            this.isPopularMoviesError = true;
            console.log("erro in now showing fetching::", err);
          }
        });
      }
    }

  }

  sortMovies(data: any){
    data.sort((a: any, b: any) => {
      const dateA = new Date(a.releaseDate.split('-').reverse().join('-'));
      const dateB = new Date(b.releaseDate.split('-').reverse().join('-'));
    
      return dateB.getTime() - dateA.getTime();
    });
  }
}

