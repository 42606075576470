<!-- <div class="modal-body text-left pt-5 px-5 border-top border-primary border-2 border-bottom bg-373D4A">
    <button type="button" class="close text-right text-707070 text-shadow-0 position-absolute r-7" aria-label="Close"
        (click)="close()">
        <span aria-hidden="true">×</span>
    </button>
    <span class="go-medium text-primary text-white h3 ">
        <p>Address</p>
    </span>
    <p>
        <span class="go-medium text-white h3 ">
            #3C2,10th Floor,

            Seethakathi Bussiness Centre,<br>
            Anna Salai,
            ThousandLights,Chennai,
            Tamil Nadu, 600006.
        </span>
    </p>
    <span class="text-primary h4 ">
        <p>Contact Info</p>
    </span>
    <span class="go-medium text-white h5 ">

        Toll Free : 1800 3000 2006<br>

        Email : info@lixotechnologies.com<br>

        Tel: +91 -44 -48 59 86 86<br>
        Tel: +91 -44 -48 54 39 39<br>
        Tel: +91 -44 -29 86 86 86<br>
    </span>
</div> -->
<mp-header></mp-header>
<div class="container-fluid px-5">
    <div class="row px-5 bg-0F1628 shadow-custom-5 py-3">
        <div class="col-xl-12">
            <div class="row align-items-center">
                <div class="col-xl-1">
                    <div class="back-btn position-relative z-index-99" (click)="route()">
                        <svg id="Component_49_32" data-name="Component 49 – 32" xmlns="http://www.w3.org/2000/svg"
                            width="38" height="38" viewBox="0 0 38 38">
                            <circle id="Ellipse_39" data-name="Ellipse 39" class="cls-1" cx="19" cy="19" r="19">
                            </circle>
                            <g id="Group_450" data-name="Group 450" transform="translate(-134.505 -201.219)">
                                <path id="Polygon_9" data-name="Polygon 9" class="cls-2" d="M5.5,0,11,6H0Z"
                                    transform="translate(142.505 226.219) rotate(-90)"></path>
                                <rect id="Rectangle_60" data-name="Rectangle 60" class="cls-2" width="17.492"
                                    height="2.018" transform="translate(147.683 219.709)"></rect>
                            </g>
                        </svg>
                    </div>
                </div>
                <!-- <div class="col-xl-3 position-absolute text-center">
                    <img class="w-35 pt-3" src="assets/images/img/user.svg">
                </div>-->
                <div class="offset-2 col-xl-6 text-center">
                    <span class="go-book text-white h4 font-weight-bolder ml-4">
                        Contact Us
                    </span>
                </div>
                <div class="col-xl-3 text-right" (click)="route()">
                    <img class="mw-100 w-9 hdr-ico" src="assets/images/img/home.svg">
                </div>
            </div>
        </div>
    </div>
    <div class="row px-5">
        <div class="container-fluid mx-23">
            <div class="row m-4 mt-5 mb-2 px-5 pt-5 bg-0F1628 ">
                <div class="col-xl-12">
                    <p class="go-medium font-1-3 text-white">Feel free to contact us.</p>
                    <div class="py-5">
                        <div class="row no-gutters align-items-start mb-3">
                            <div class="col-xl-1">
                                <p class="d-inline-block align-text-top mb-0">
                                    <img class="mw-100 w-100" src="assets/images/img/home-contact.svg">
                                </p>
                            </div>
                            <div class="col-xl-2">
                                <p class="d-inline-block align-sub go-medium font-1-2 text-white mb-0">Address:</p>
                            </div>
                            <div class="col-xl-9">
                                <span class="go-book  text-white opacity-8 h6">#3C2, 10th Floor, Seethakathi Business
                                    Centre, Anna Salai <br /> Landmark: old Anand theatre, Thousand Lights, Chennai,
                                    Tamil Nadu 600006</span>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-start my-5">
                            <div class="col-xl-1">
                                <p class="d-inline-block align-text-top mb-0">
                                    <img class="mw-100 w-100" src="assets/images/img/mobile.svg">
                                </p>
                            </div>
                            <div class="col-xl-2">
                                <p class="d-inline-block align-sub go-medium font-1-2 text-white mb-0">Contact no:</p>
                            </div>
                            <div class="col-xl-9">
                                <span class="go-book font-1-0 text-white opacity-8">91-8939685520</span>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-start mt-3">
                            <div class="col-xl-1">
                                <p class="d-inline-block align-text-top mb-0">
                                    <img class="mw-100 w-100" src="assets/images/img/email.svg">
                                </p>
                            </div>
                            <div class="col-xl-2">
                                <p class="d-inline-block align-sub go-medium font-1-2 text-white mb-0">Email:</p>
                            </div>
                            <div class="col-xl-9">
                                <span class="go-book font-1-1 text-white opacity-8">info@lixotechnologies.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-4 mt-3 mb-6 px-5 pt-5 bg-0F1628 ">
                <form>
                    <div class="col-xl-12">
                        <p class="go-medium font-1-3 text-white">Get in Touch</p>
                        <div class="row form my-5">
                            <div class="offset-xl-1 col-xl-10 input-group mb-4">
                                <input type="text" id="con-form"
                                    class="form-control bg-transparent border-top-0 border-left-0 border-right-0 rounded-0 border-6A6A78 go-medium text-6A6A78"
                                    placeholder="Name">
                            </div>
                            <div class="offset-xl-1 col-xl-10 input-group mb-4">
                                <input type="text" id="con-form"
                                    class="form-control bg-transparent border-top-0 border-left-0 border-right-0 rounded-0 border-6A6A78 go-medium text-6A6A78"
                                    placeholder="Email">
                            </div>
                            <div class="offset-xl-1 col-xl-10 input-group mb-4">
                                <input type="text" id="con-form"
                                    class="form-control bg-transparent border-top-0 border-left-0 border-right-0 rounded-0 border-6A6A78 go-medium text-6A6A78"
                                    placeholder="Phone No.*">
                            </div>
                            <div class="offset-xl-1 col-xl-10 input-group mb-5">
                                <textarea type="text" id="con-form"
                                    class="form-control bg-transparent border-top-0 border-left-0 border-right-0 rounded-0 border-6A6A78 go-medium text-6A6A78"
                                    rows="4" placeholder="Messages"></textarea>
                            </div>

                            <div class="g-recaptcha offset-xl-1 col-xl-10 input-group mb-5"
                                data-sitekey="6Le22AofAAAAAJfuvHYEroocZiRfMhdOEfkPTaVn"></div>
                            <div class="offset-xl-1 col-xl-10 input-group mb-4">
                                <button class="btn btn-lg gradient-button gradient-button-4 rounded-0 px-4 py-3"
                                    type="submit">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<mp-footer></mp-footer>