<mp-header></mp-header>
<div class="container-fluid px-5">
    <div class="row px-5 bg-secondary shadow-custom-5 py-3">
        <div class="col-xl-12">
            <div class="row align-items-center">
                <div class="col-xl-1">
                    <div class="back-btn position-relative z-index-99" (click)="route()">
                        <svg id="Component_49_32" data-name="Component 49 – 32" xmlns="http://www.w3.org/2000/svg"
                            width="38" height="38" viewBox="0 0 38 38">
                            <circle id="Ellipse_39" data-name="Ellipse 39" class="cls-1" cx="19" cy="19" r="19">
                            </circle>
                            <g id="Group_450" data-name="Group 450" transform="translate(-134.505 -201.219)">
                                <path id="Polygon_9" data-name="Polygon 9" class="cls-2" d="M5.5,0,11,6H0Z"
                                    transform="translate(142.505 226.219) rotate(-90)"></path>
                                <rect id="Rectangle_60" data-name="Rectangle 60" class="cls-2" width="17.492"
                                    height="2.018" transform="translate(147.683 219.709)"></rect>
                            </g>
                        </svg>
                    </div>
                </div>
                <!-- <div class="col-xl-3 position-absolute text-center">
                    <img class="w-35" src="assets/images/img/user.svg">
                </div>
                <div class="offset-2 col-xl-6 text-center">
                    <span class="go-book text-white h3 ">
                            FAQ
                        </span>
                </div> -->
                <!-- <div class="col-xl-3 text-right" (click)="route()">
                    <img src="assets/images/img/home.svg">
                </div>
                <div class="col-xl-3 position-absolute text-center">
                    <img class="w-35" src="assets/images/img/user.svg">
                </div> -->
                <div class="offset-2 col-xl-6 text-center">
                    <span class="go-medium text-white h4 ">
                        FAQ
                    </span>
                </div>
                <div class="col-xl-3 text-right" (click)="route()">
                    <img class="pointer" src="assets/images/img/home.svg">
                </div>
            </div>
        </div>
    </div>
    <div class="row align-items-end pt-5 pb-5 px-5  bg-020A1C shadow-custom hm-pos-bg">
        <div class="col-xl-12 ">
            <div class="row no-gutters mx-4 p-5  shadow-custom">
                <div class="col-xl-12 px-4">
                    <div class="accordion pb-1">
                        <!-- <div class="card bg-transparent border-707070"> -->
                        <div class="card-header border-707070 h1">
                            <h4 class="mb-0">
                                <p class="go-medium  text-primary my-2 " data-toggle="collapse" data-target="#example1">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16"
                                        class="bi bi-caret-right-fill pointer" fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z">
                                        </path>
                                    </svg>
                                    <span class="ml-2 go-book font-1-4 pointer">Will Movie Panda ask for my personal and
                                        bank details to book
                                        tickets?</span>
                                </p>
                            </h4>
                        </div>
                        <div id="example1" class="collapse ">
                            <div class="card-body go-light lead text-ADADBE">
                                You can book tickets only through Movie Panda website and app. We do not process
                                phone/email requests from users. Please note that Movie Panda or its employees will
                                never contact you requesting for your bank account number/ card number/ card expiry
                                date/
                                CVV/ OTP/ net banking password to help you book movie tickets. Do not share these
                                details with anyone over phone or e-mail as this could lead to fraudulent transactions.
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row align-items-end pt-5 pb-5 px-5 bg-020A1C shadow-custom hm-pos-bg">
        <div class="col-xl-12">
            <div class="row no-gutters mx-4 p-5 shadow-custom">
                <div class="col-xl-12 px-4">
                    <div class="accordion pb-1">
                        <!-- <div class="card bg-transparent border-707070"> -->
                        <div class="card-header border-707070 h1">
                            <h4 class="mb-0">
                                <p class="go-medium  text-primary my-2" data-toggle="collapse" data-target="#example2">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16"
                                        class="bi bi-caret-right-fill pointer" fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z">
                                        </path>
                                    </svg>
                                    <span class="ml-2 go-book font-1-4 pointer">Will Movie Panda ask for my personal and
                                        bank details to book
                                        tickets?</span>
                                </p>
                            </h4>
                        </div>
                        <div id="example2" class="collapse">
                            <div class="card-body go-light lead text-ADADBE">
                                You can book tickets only through Movie Panda website and app. We do not process
                                phone/email requests from users. Please note that Movie Panda or its employees will
                                never contact you requesting for your bank account number/ card number/ card expiry
                                date/
                                CVV/ OTP/ net banking password to help you book movie tickets. Do not share these
                                details with anyone over phone or e-mail as this could lead to fraudulent transactions.
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
<mp-footer></mp-footer>