<div class="container-404">
<div class="align-self-center error-container">
    <span class="status-code">404</span>
    <img
        class="align-self-center img-1"
        src="../../../../assets/images/img/404.svg"
    />
    <img
    class="align-self-center img-2"
    src="../../../../assets/images/img/404-inner.svg"
/>
</div>

<h2 class="margin-left-40 text-white">We can't find that page</h2>
</div>