import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { WalletComponent } from './wallet.component';


const routes: Routes = [
  {
    path:"",
    component:WalletComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WalletRoutingModule { }
