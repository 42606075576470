<!-- venue page -->
<mp-header *ngIf="fromPage == 'movieShowTime'"></mp-header>
<div class="tab-pane  bg-020A1C mt-2 mb-5" id="messages" role="tabpanel" aria-labelledby="messages-tab">
  <div class="row bg-0F1628 shadow-custom-6 py-2">
    <div class="offset-lg-1 col-xl-10">
      <div class="row align-items-center">
        <div class="col-xl-6">

          <p class="font-1-4 text-white go-book">{{venueName}}<span class="font-0-875 go-light ml-2"> -
              {{venueAddress1}} {{venueAddress2}}</span></p>
          <div class="mt-3 d-none d-lg-block">
            <div class="d-inline-block align-middle">
              <img class="w-100 mw-100 sna-ico-bur" src="assets/images/img/burger.svg">
            </div>
            <div class="d-inline-block align-middle mx-2">
              <img class="w-100 mw-100 sna-ico-tic" src="assets/images/img/ticket.svg">
            </div>
            <div class="d-inline-block align-middle">
              <img class="w-100 mw-100 sna-ico-loc" src="assets/images/img/tlocation.svg">
            </div>
          </div>
        </div>

        <div class="offset-lg-2 col-xl-4" *ngIf="(!showTimeService.isErrorVenueShowDate) ">
          <ngx-slick-carousel class="venue-slider" *ngIf="showAllDates as venueShowDates" #slickModal="slick-carousel"
            [config]="slideConfig">
            <ng-container *ngFor="let show of venueShowDates; index as i">
              <div ngxSlickItem (click)="getVenueShowTime(show.showDate)" class="slide"
                [ngClass]="show.showDate == activeElement ? 'active' : 'text-noactive'">
                <div class="item">
                  <h3 class="go-bold text-center font-weight-bolder mb-0 font-1-2 pointer">{{ show.day }}</h3>
                  <!-- <span [ngClass]="show.showDate == activeElement ? 'active' : 'text-ADADBE'"> -->
                  <p class="go-bold text-center font-1-1 lead  mb-0 pointer">
                    {{ show.showDate | date: "dd" }}</p>
                  <p class="go-medium text-center font-weight-bolder mb-0 font-0-875 pointer">
                    {{ show.showDate | date: "MMM" }}
                  </p>
                  <!-- </span> -->
                </div>
              </div>
            </ng-container>
          </ngx-slick-carousel>
        </div>
        <div class="legend">

          <div class="dot available-status"></div>
          <div class="text-legend  ">Available</div>
          <div class="dot fast filling-status"></div>
          <div class="text-legend">Fast Filling</div>
          <div class="dot soldout-status"></div>
          <div class="text-legend">Sold Out</div>
          <div class="dot boxofficeonly-status"></div>
          <div class="text-legend">boxOfficeOnly</div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!showTimeService.isErrorVenueShowDate&&!showTimeService.isErrorVenueShowTime else noVenueData">
    <ng-container *ngIf=" loader else loaded">
      <div class="col-xl-12 pt-lg-3 pb-lg-2 mt-lg-5 ther-list" *ngFor="let number of [0,1,2,3,4,5,6,7]">
        <div class="row">
          <ng-container>
            <div class="col-xl-3">
              <p
                class=" font-1-5 text-white go-book text-right font-weight-bolder paceholder-venuename placeholder-bar">

              </p>
              <p
                class="font-0-875 text-white go-book text-right font-weight-bolder placeholder-address placeholder-bar">

              </p>
              <div class="text-right placeholder-fandb placeholder-bar">

              </div>
            </div>
            <div class="col-xl-8 mx-4 px-4">
              <div class="d-inline-block align-middle mr-2 mb-2" *ngFor="let number of [0,1,2,3,4,5,6,7]">
                <a class="btn go-book font-weight-bolder timing-btn placeholder-a placeholder-bar"></a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #loaded>
      <ng-container *ngIf="venueMovies as movies">

        <div
          class="row py-5 border-707070 border border-left-0 border-right-0 border-top-0 border-bottom bg-020A1C shadow-custom-6"
          *ngFor="let movie of movies">
          <div class="offset-lg-1 col-xl-11 col-12">
            <div class="row">
              <div class="col-xl-2 pr-5 col-6">
                <div class="position-relative">
                  <!-- <img class="w-100 mw-100 shadow-custom-2 posterimg" src="{{ movie.posterUrl }}" alt="home-play"> -->
                  <ngx-picture src="{{movie.posterUrl|splitAndGet:'~':0}}" alt="Movie Poster" class=""
                    [lazyLoad]="false">
                    <ng-template #imgTemplate let-imageData>
                      <img class="w-100 mw-100 shadow-custom-2 posterimg" [src]="imageData.src" [alt]="imageData.alt" />
                    </ng-template>
                  </ngx-picture>
                  <!-- <div class="overlay-40 position-absolute text-center">
                                    <img class="w-25 mw-100" src="assets/images/img/play.svg">
                                </div> -->
                </div>
              </div>
              <div class="col-xl-10 pl-lg-5 p-0 col-6">
                <div class="mb-3 pl-lg-3" (click)="onMovieSelect(movie)">
                  <h2 class="go-book text-white font-1-4 pointer">{{ movie.movieName }}
                  </h2>
                  <p class="font-weight-bolder text-8A8A8A font-0-875 go-light text-uppercase mb-1">
                    <span class="border-right pr-2 mr-2">{{ movie.language}} </span>
                    <span class="pr-2 mr-2">{{movie.dimension}} </span>
                  </p>
                  <p class="font-weight-bolder text-8A8A8A font-0-875 mb-1 go-light text-uppercase">
                    <span class="border-right pr-2 mr-2">{{movie.duration}} </span>
                    <span *ngFor="let genre of movie.genres; let isLast=last">{{genre.genreName}}{{isLast ? '' : ',
                      '}}</span>
                  </p>
                </div>
                <div class="row bg-0F1628">
                  <div class="col-xl-11 pt-3 pb-2 mt-5 ther-list bg-0F1628 ">
                    <div class="d-inline-block align-middle mr-4 mb-2" *ngFor="let show of movie.shows">
                      <div *ngFor="let saw of show.classes;let i = index">
                        <div *ngIf="i<1">


                          <span style="display: inline-block;border-radius: 6px;" [ngClass]="{'available':(show.classesValues / show.totalClassesValues) * 100 >=
                      50,
                      'fastfilling':(show.classesValues / show.totalClassesValues) * 100 >
                      10 &&
                      (show.classesValues / show.totalClassesValues)* 100 <
                      60 || (show.classesValues / show.totalClassesValues) * 100 < 10 ,
                      'soldout':(show.classesValues / show.totalClassesValues) * 100 <=
                        0
                    }">
                            <button class="btn btn-primary go-book font-weight-bolder timing-btn" [tooltip]="tooltip"
                              [ngClass]="show.showTime|daynight" (click)="proceedSeatSelection(movie, show)"
                              (mouseover)='over(show)' (click)="getBaseFare(show.classes)"><span [ngClass]="{'text-available':(saw.availableSeats / saw.totalSeats) * 100 >=
                          50,
                          'text-fastfilling':(saw.availableSeats / saw.totalSeats) * 100 >
                          10 &&
                      (saw.availableSeats / saw.totalSeats) * 100 <
                          50,
                          'text-soldout':(saw.availableSeats / saw.totalSeats) * 100 <=
                            0
                        }"> {{ show.showTime }}</span>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #tooltip>
              <mp-show-tooltip [classes]="classes" [legend]="legends"></mp-show-tooltip>
            </ng-template>
          </div>
        </div>

      </ng-container>
    </ng-template>
  </ng-container>
  <ng-template #noVenueData>
    <div class="container-fluid px-5 bg-020A1C">
      <div class="row px-5 pt-5">
        <div class="col-xl-12">
          <p class="go-medium text-white font-1-5 text-center pt-3">oops! <br /> No movies are available</p>
          <p class="text-center py-4-1">
            <img class="w-20 mw-100" src="assets/images/img/service-err.svg">
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #movieTerm>
  <mp-movie-shows-terms></mp-movie-shows-terms>
</ng-template>
<ng-template #venueTerm>
  <mp-venue-shows-terms></mp-venue-shows-terms>
</ng-template>
<ng-template #selectSeatCount>
  <mp-seatcount></mp-seatcount>
</ng-template>
<mp-footer *ngIf="fromPage == 'movieShowTime'"></mp-footer>