<!-- <main role="main">
  <div style="margin-top: 80px;">
    <div class="row">
      <div class="carousel-container">
        <div class="carousel-overlay">
          <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
          >
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
              <img src="{{ slide.img }}" alt="" />
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</main> -->
<!-- <div ngxSlickItem *ngFor="let slide of slides" class="slide">
<div class="container-fluid px-5 mt-n6 shadow-custom">
  <img class="w-100 mw-100" src="{{ slide.img }}" alt="home-banner">
</div>
</div> -->

<div class="container-fluid  mt-n6 shadow-custom homebanner d-lg-flex d-none">
  <img class="w-100 mw-100" src="assets/images/img/homebanner.jpg" alt="home-banner">
</div>