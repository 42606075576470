
<mp-header></mp-header>
    <div class="container-fluid mt-n6 shadow-custom-1">
        <div class="row">
            <img class="w-100 mw-100" *ngIf="largePoster == ''" src="./assets/images/img/movie-detail.png" alt="home-banner">
            <ngx-picture *ngIf="largePoster != ''" src="{{largePoster}}" alt="Movie Poster" class="" [lazyLoad]="false">
                <ng-template #imgTemplate let-imageData>
                    <img class="w-100 mw-100 banner" [src]="imageData.src" [alt]="imageData.alt" />
                    <div class="overlay-40 position-absolute text-center pointer" (click)="onClickPlay(trailerPlayer)">
                        <a class="w-10 mw-50 play-btn d-lg-none mobile"></a>
                    </div>
                </ng-template>
            </ngx-picture>
        </div>
    </div>
    <div class="container-fluid mt-13">
        <div class="row mx-lg-5 mt-lg-4 px-lg-5 align-items-end">
            <div class="col-xl-6">
                <div class="back-btn d-lg-inline-block align-middle mr-lg-4 pointer outline-none backdesktop">
                    <svg id="Component_49_32" data-name="Component 49 – 32" xmlns="http://www.w3.org/2000/svg" width="36"
                        height="36" viewBox="0 0 38 38">
                        <circle id="Ellipse_39" data-name="Ellipse 39" class="cls-1" cx="19" cy="19" r="19"
                            (click)="navigatehomes()" />
                        <g id="Group_450" data-name="Group 450" transform="translate(-134.505 -201.219)">
                            <path id="Polygon_9" data-name="Polygon 9" class="cls-2" d="M5.5,0,11,6H0Z"
                                transform="translate(142.505 226.219) rotate(-90)" />
                            <rect id="Rectangle_60" data-name="Rectangle 60" class="cls-2" width="17.492" height="2.018"
                                transform="translate(147.683 219.709)" />
                        </g>
                    </svg>
                </div>
                <div class="pointer outline-none d-lg-none backmobile " style="fill: #fff" (click)="navigatehomes()">
                    <svg id="Component_49_32" data-name="Component 49 – 32" xmlns="http://www.w3.org/2000/svg" width="36"
                        height="36" viewBox="0 0 38 38">
                        <g id="Group_450" data-name="Group 450" transform="translate(-134.505 -201.219)">
                            <path id="Polygon_9" data-name="Polygon 9" class="cls-2" d="M5.5,0,11,6H0Z"
                                transform="translate(142.505 226.219) rotate(-90)" />
                            <rect id="Rectangle_60" data-name="Rectangle 60" class="cls-2" width="17.492" height="2.018"
                                transform="translate(147.683 219.709)" />
                        </g>
                    </svg>
                </div>
                <ng-container *ngIf="movieService.movie | async as movie">
                    <div class="d-lg-inline-block d-none align-middle w-30 position-relative card px-lg-3">
                        <ngx-picture src="{{movie.posterUrl|splitAndGet:'~':0}}" alt="Movie Poster" class="" [lazyLoad]="false">
                            <ng-template #imgTemplate let-imageData>
                                <img class="w-100 mw-100 shadow-custom-2 posterimg moviecard d-none d-lg-flex"
                                    [src]="imageData.src" [alt]="imageData.alt" />
                            </ng-template>
                        </ngx-picture>
                        <div class="overlay-40 position-absolute text-center pointer" (click)="onClickPlay(trailerPlayer)">
                            <a class="w-25 mw-100 play-btn d-none d-lg-flex"></a>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="movieService.movie | async as movie">
                    <div class="d-inline-block align-bottom w-50 mx-lg-3">
                        <div>
                            <p class="d-lg-block d-inline-block ">
                                <a class="text-decoration-none d-none d-lg-block">
                                    <span class="go-book font-weight-bolder text-primary font-1-1">Released
                                        Date:{{movie.releaseDate.split('-')[0]+'-
                                        '+movie.releaseDate.split('-')[1]}}</span>
                                </a>
                            </p>
                            <h2 class="go-medium font-weight-bolder text-white font-1-5 my-lg-2">{{movie.movieName}}
                            </h2>
                            <p class="d-lg-block d-inline-block">
                                <a class="text-decoration-none">
                                    <span class="go-book font-weight-bolder text-primary font-1-1">{{movie.language}}
                                        {{movie.censorCertificate}}</span>
                                </a>
                            </p>
                            <p class="d-lg-block d-inline-block">
                                <a class="text-decoration-none">
                                    <span class="go-book font-weight-bolder text-primary mx-1 font-1-1"
                                        *ngFor="let genre of movie.genres;let isLast=last">{{genre.genreName}}{{isLast ?
                                        '' : '/'}}</span></a>
                            </p>
                            <p class="d-lg-block d-inline-block">
                                <span class="go-book font-weight-bolder text-primary font-1-1">{{movie.dimension}}</span>
                            </p>
                            <p>
                                <span
                                    class="go-book font-weight-bolder lead text-primary font-1-1">{{movie.duration}}</span>
                            </p>
                        </div>
                        <!-- <div class="font-0 ml-3 mt-5 d-none d-lg-flex">
                        <div class="d-inline-block align-top w-25">
                                <img class="w-100 mw-100 border-dark border-right px-3 h-4r"
                                    src="./assets/images/img/imdb.svg" alt="imdb">
                                <p
                                    class="lead text-primary text-center font-weight-bolder go-book mt-1 mb-0 d-none d-lg-flex">
                                    7.2</p>
                            </div>
                        </div>-->
                    </div>
                </ng-container>
            </div>
            <div class="col-xl-6 text-right">
                <!-- Button trigger modal -->
                <div *ngIf="!showModal" class="d-inline-block align-middle">
                    <a class="btn gradient-button gradient-button-4 rounded-pill go-book px-2 font-1-4 mr-3"
                    (click)="onClickPlay(disclaimer)">Rent</a>
                </div>
                <div *ngIf="showModal" class="d-inline-block align-middle">
                    <a class="btn gradient-button gradient-button-4 rounded-pill go-book px-2 font-1-4 mr-3"
                    (click)="updatePlaybackUrl();onClickPlay(moviePlayer)">Play</a>
                </div>
                <div *ngIf="!showModal" class="d-inline-block align-middle">
                    <a class="btn gradient-button gradient-button-4 rounded-pill go-book w-90 px-2 font-1-4 mr-5"
                    (click)="onClickPlay(disclaimer)">More Buy options</a>
                </div>

                <!-- Warranty Modal -->

                <!-- Seat Modal -->

                <div class="d-inline-block align-middle ml-3">
                    <img class="mw-100 w-100 hdr-ico outline-none pointer d-none d-lg-flex"
                        src="./assets/images/img/home.svg" [routerLink]="['/']" alt="home-button">
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative mt-lg-5 pt-lg-5">
        <div class="container-fluid px-5">
            <div class="border-dark border-bottom border-2">
                <div class="row">
                    <ul class="nav nav-tabs border-0 offset-lg-1 col-xl-10 col hm-tab" id="myTab" role="tablist">
                        <li class="nav-item text-center col-lg-2 col-4" role="presentation">
                            <a class="nav-link active bg-transparent border-0 text-white" id="home-tab" role="tab"
                                aria-controls="home" aria-selected="true" (click)="switchTab('summary')" [ngClass]="{
                                'active': navTab == 'summary'
                            }">
                                <div class="hm-tab-ico text-ADADBE">
                                    <p class="mt-1 mb-0 h4 go-medium font-1-3 pointer">Summary</p>
                                </div>
                            </a>
                        </li>
                    <!--<li class="nav-item text-center col-lg-2 col-4" role="presentation" *ngIf="checkbookbutton">
                            <a class="nav-link bg-transparent border-0 text-white" id="profile-tab" role="tab"
                                aria-controls="profile" aria-selected="false" (click)="switchTab('userReview')" [ngClass]="{
                                    'active': navTab == 'userReview'
                                }">
                                <div class="hm-tab-ico text-ADADBE">
                                    <p class="mt-1 mb-0 h4 go-medium font-1-3 pointer">User Reviews <span></span></p>
                                </div>
                            </a>
                        </li>--> 
                        <li class="nav-item text-center col-lg-2 col-4" role="presentation" *ngIf="checkbookbutton">
                            <a class="nav-link bg-transparent border-0 text-white" id="messages-tab" role="tab"
                                aria-controls="messages" aria-selected="false" (click)="switchTab('criticReview')"
                                [ngClass]="{
                                    'active': navTab == 'criticReview'
                                }">
                                <div class="hm-tab-ico text-ADADBE">
                                    <p class="mt-1 mb-0 h4 go-medium font-1-3 pointer">Critic Reviews <span></span></p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <ng-container *ngIf="!movieService.isErrorFetchingMovieDeatils else noShows">
                <ng-container *ngIf="movieService.movie | async as movie">
                    <div class="container-fluid p-0 shadow-custom hm-pos-bg">
                        <div class="tab-content">
                            <div class="tab-pane" [ngClass]="{'active': navTab == 'summary'}" id="home" role="tabpanel"
                                aria-labelledby="home-tab" *ngIf="navTab=='summary'">
                                <div class="container-fluid p-0 border-363944 border-bottom border-2 pt-lg-5 pb-lg-3">
                                    <div class="row no-gutters">
                                        <div class="offset-lg-1 col-xl-10">
                                            <ng-container>
                                                <div class="text-white font-1 go-medium  text-uppercase mb-4">
                                                    SYNOPSIS
                                                </div>
                                                <p class="text-white font-1 go-book">
                                                    {{movie.synopsis}}
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                                <div class="container-fluid p-0 border-363944 border-bottom border-2 py-lg-5">
                                    <ng-container>
                                        <div class="row no-gutters">
                                            <div class="offset-lg-1 col-xl-10">
                                                <div class="text-white font-1 go-medium text-uppercase mb-3">
                                                    Cast</div>
                                                <div *ngIf="movie.casts == undefined || movie.casts == 0 || movie.casts == null || movie.casts.length == 0"
                                                    class="text-white font-1 text-centergo-medium text-uppercase mb-3 font-weight-bolder">
                                                    <img class="w-25 mw-20 "
                                                        src="../../../../assets/images/img/no-date.svg"> No cast Available
                                                </div>
                                                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                                    [config]="slideConfig">

                                                    <div ngxSlickItem *ngFor="let cast of movie.casts" class="slide">
                                                        <div class="item">
                                                            <img class="w-65 mw-100 border-2 border-primary border rounded-circle m-auto"
                                                                src="{{ cast.profileImage }}">
                                                            <p
                                                                class="go-book text-center font-1 text-dark mb-0 text-ADADBE mt-2">
                                                                {{cast.cineastName}}
                                                            </p>
                                                            <p class="go-book text-center text-secondary mb-0 font-1">
                                                                <span
                                                                    class="border-ADADBE text-ADADBE pr-2 mr-2">{{cast.roleName}}</span>

                                                            </p>
                                                        </div>
                                                    </div>

                                                </ngx-slick-carousel>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="container-fluid p-0 border-363944 border-bottom border-2 py-lg-5">
                                    <div class="row no-gutters">
                                        <div class="offset-lg-1 col-xl-10">
                                            <div class="text-white font-1 go-medium text-uppercase mb-5">Crew
                                            </div>
                                            <div *ngIf="movie.crews == undefined || movie.crews == 0 || movie.crews == null || movie.crews.length == 0"
                                                class="text-white font-1 text-centergo-medium text-uppercase mb-3 font-weight-bolder">
                                                <img class="w-25 mw-20 " src="../../../../assets/images/img/no-date.svg"> No
                                                crews Available
                                            </div>
                                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                                [config]="slideConfig">
                                                <div ngxSlickItem *ngFor="let crews of movie.crews" class="slide">
                                                    <div class="item">
                                                        <img class="w-65 mw-100 border-2 border-primary border rounded-circle m-auto"
                                                            src="{{crews.profileImage}}">
                                                        <p
                                                            class="go-book text-center font-1 text-dark mb-0 text-ADADBE mt-2">
                                                            {{crews.cineastName}}
                                                        </p>
                                                        <p class="go-book text-center text-secondary mb-0 font-1">
                                                            <span
                                                                class="border-right border-ADADBE text-ADADBE pr-2 mr-2">{{crews.roleName}}</span>
                                                            <span class="text-ADADBE"> {{crews.roleType}} </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </ngx-slick-carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="navTab=='userReview'" class="tab-pane active"
                                [ngClass]="{'active': navTab == 'userReview'}" id="profile" role="tabpanel"
                                aria-labelledby="profile-tab">


                                <div class="container-fluid px-0 pb-5">
                                    <div class="row no-gutters">
                                        <div class="offset-1 col-xl-10 py-3 pt-4">
                                            <div class="row align-items-start">
                                                <div class="col-xl-12 text-right">
                                                    <p class="font-0-875 go-thin text-white">

                                                        <button *ngIf="userReviewForm"
                                                            class="btn btn-primary text-white font-0-875 rounded-5 wr-rw-tab py-2 px-4 ml-4">
                                                            <span (click)="review()">Write a Review</span>
                                                        </button>
                                                    </p>
                                                </div>
                                                <div class="col-xl-7 py-3"
                                                    *ngFor="let reviews of movie.userReviews; index as i">
                                                    <div class="row align-items-end" *ngIf="i<=selectedIndex">
                                                        <div class="col-xl-2 m-auto userimg">
                                                            <img class="w-100 mw-100 desktop"
                                                                src="./assets/images/img/guest.svg">
                                                            <img class="mobile" src="./assets/images/img/guest.svg">
                                                        </div>
                                                        <div class="col-xl-10">
                                                            <p class="font-1-5 text-primary go-medium mb-0">
                                                                {{ reviews.userId }}</p>
                                                            <p class="font-1 go-book text-white text-uppercase text-left ">
                                                                {{ reviews.title }}</p>
                                                            

                                                            <p>                                   
        
                                                                <ng-template [ngIf]="reviews.rating == 20" [ngIfElse]="twostar"> 
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                </ng-template>
                                                                <ng-template #twostar>
                                                                    <ng-template [ngIf]="reviews.rating == 40" [ngIfElse]="threestar"> 
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    </ng-template>
                                                                </ng-template>
                                                                
                                                                
                                                                <ng-template #threestar>
                                                                    <ng-template [ngIf]="reviews.rating == 60" [ngIfElse]="fourstar"> 
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    </ng-template>
                                                                </ng-template>
                                                                
                                                                
                                                                <ng-template #fourstar>
                                                                    <ng-template [ngIf]="reviews.rating == 80" [ngIfElse]="fivestar"> 
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    </ng-template>
                                                                </ng-template>
                                                                
                                                                
                                                                <ng-template #fivestar>
                                                                    <ng-template [ngIf]="reviews.rating == 100"> 
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    <span><img class="mw-100" src="./assets/images/img/star-yellow.svg"></span>
                                                                    </ng-template>
                                                                </ng-template>
                                                                
                                                                
                                                                
                                                                
                                                                
                                                                

                                                                <span class="text-white go-book  ml-3">{{reviews.reviewDate | date: 'dd-MMM-yyyy'}}</span>
                                                            </p>
                                                            <p class="font-1 go-light text-white text-left ">{{
                                                                reviews.comments }} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="offset-2 col-xl-3 text-center mt-3" *ngIf="addReview">
                                                    <section id="like" class="rating mw-25">
                                                        <input
                                                            class="form-control form-control-lg go-book text-primary font-weight-bolder border border-right-0 border-primary bg-secondary border-2 "
                                                            type="radio" id="heart_5" name="like" />
                                                        <label for="heart_5" title="Five"
                                                            (click)="onClick(100)">&#10084;</label>
                                                        <input type="radio" id="heart_4" name="like" />
                                                        <label for="heart_4" title="Four"
                                                            (click)="onClick(80)">&#10084;</label>
                                                        <input type="radio" id="heart_3" name="like" />
                                                        <label for="heart_3" title="Three"
                                                            (click)="onClick(60)">&#10084;</label>
                                                        <input type="radio" id="heart_2" name="like" />
                                                        <label for="heart_2" title="Two"
                                                            (click)="onClick(40)">&#10084;</label>
                                                        <input type="radio" id="heart_1" name="like" />
                                                        <label for="heart_1" title="One"
                                                            (click)="onClick(20)">&#10084;</label>
                                                    </section>
                                                    <div class="text-white font-1-2 "> Percentage:{{rating}}%</div>

                                                    <div [formGroup]="login_form">
                                                        <input type="text"
                                                            class="form-control bg-343A47 border border-primary col-xl-12  text-white font-1-2"
                                                            formControlName="title" [(ngModel)]="titleComment"><br>
                                                        <textarea
                                                            class="form-control bg-343A47 border border-primary col-xl-12  text-white font-1-2 "
                                                            rows="8" formControlName="description" cols="40" minlength="0"
                                                            maxlength="500" [(ngModel)]="description"></textarea>
                                                    </div>
                                                    <button class="btn btn-primary font-0-875 mt-4 go-book px-4"
                                                        (click)="userReviewComments(booking.movieId)">Submit</button>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 text-center">
                                                <button class="btn btn-primary go-book font-weight-bolder my-4"
                                                    (click)="showMoreDetails()" *ngIf='showmore'>SHOW ALL</button>
                                                <button (click)="showLessDetails()" *ngIf='showless'
                                                    class="btn btn-primary go-book font-weight-bolder my-4">SHOW
                                                    LESS</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" [ngClass]="{'active': navTab == 'criticReview'}" id="messages"
                                role="tabpanel" aria-labelledby="messages-tab" *ngIf="navTab=='criticReview'">
                                <div class="container-fluid px-0 pb-5">
                                    <ng-container>
                                        <div *ngIf="movie.criticReviews === undefined || movie.criticReviews === 0 || movie.criticReviews === null || movie.criticReviews.length == 0"
                                            class="offset-3 col-xl-8 text-white font-1 text-centergo-medium text-uppercase mb-3 font-weight-bolder pt-5">
                                            <img class="w-25 mw-20 " src="../../../../assets/images/img/no-date.svg"> No
                                            critic review Available
                                        </div>
                                        <div class="row no-gutters" *ngFor="let reviews of movie.criticReviews; index as i">
                                            <div class="offset-1 col-xl-10 pt-3" *ngIf="i<=selectedIndex">
                                                <div class="row align-items-end">
                                                    <div class="col-xl-12 text-right">
                                                    </div>
                                                    <div class="col-xl-12">
                                                        <p class="font-1 go-book text-white text-uppercase text-left">
                                                            {{ reviews.criticName }}</p>
                                                        <p>
                                                            <span><img class="mw-100"
                                                                    src="./assets/images/img/star-yellow.svg"></span>
                                                            <span><img class="mw-100"
                                                                    src="./assets/images/img/star-yellow.svg"></span>
                                                            <span><img class="mw-100"
                                                                    src="./assets/images/img/star-yellow.svg"></span>
                                                            <span><img class="mw-100"
                                                                    src="./assets/images/img/star-yellow.svg"></span>
                                                            <span><img class="mw-100"
                                                                    src="./assets/images/img/star-white.svg"></span>
                                                        </p>
                                                        <p class="font-1 go-light text-white text-left">
                                                            {{reviews.comments}}
                                                        </p>
                                                        <p class="text-right">
                                                            <a class="font-1 go-medium text-primary text-decoration-none"
                                                                aria-expanded="false" aria-controls="timesofindia">
                                                                … See full review
                                                            </a>
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 text-center">
                                            <button class="btn btn-primary go-book font-weight-bolder my-4"
                                                (click)="showMoreDetails()" *ngIf='showmore'>SHOW ALL</button>
                                            <button (click)="showLessDetails()" *ngIf='showless'
                                                class="btn btn-primary go-book font-weight-bolder my-4">SHOW LESS</button>

                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #noShows>
                <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="container-fluid px-0 pt-5 pb-5">
                        <div class="row no-gutters">
                            <div class="offset-1 col-xl-10 py-3">
                                <p class="go-medium text-white font-1-5 text-center pt-3">oops! <br /> Service unavailable.
                                    Please try again later.</p>
                                <p class="text-center py-4-1">
                                    <img class="w-40 mw-100" src="assets/images/img/no-date.svg">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <ng-template #trailerPlayer>
        <div class="" id="trailerPlayer" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="termsLabel" aria-hidden="true">
            <button type="button" class="closelarge close fs-20 text-right text-white text-shadow-0 mx-4 pointer"
                (click)="movieModalClose()">
                <span aria-hidden="true">×</span>
            </button>
            <div class="position-relative video">
                <iframe height="500" width="100%" [src]="movieTrailerUrl" style="border: none;" allowfullscreen>
                </iframe>
            </div>
        </div>
    </ng-template>
<mp-footer></mp-footer>


<!--  ====== Modal Popup for Disclaimer =====  -->

<ng-template #disclaimer >
    <div class="" id="disclaimer" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="seatLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable mobile-dialog">
        <div class="modal-content shadow-custom-9 border-bottom border-primary border-4 border-top-0 border-left-0 border-right-0 ">
          <div class="container-fluid pt-4 pb-2 bg-0F1628 ">
            <div class="row">
              <div class="col-xl-12 text-center">
                <span class="modal-title go-medium text-primary text-center h3" id="seatLabel">Choose a watch option</span>
                <button type="button" class="close fs-20 text-right text-9F9F9F text-shadow-0 position-absolute r-5"
                  aria-label="Close" (click)="movieModalClose()">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body text-left px-lg-5 border-top border-primary bg-020A1C">
            <div class="text-center mt-lg-3" style="color: white">
              <div class="text d-flex flex-column align-items-center">
                <h4 class="w-100 text-left">Rent</h4>
                <p class="w-100">
                    You can rent this movie for 30 dayss but will have 2 dayss to watch it once you start playback (available for download)
                </p>
                <div class="price-tag w-30 d-flex justify-content-between">
                    <select class="form-select shadow-none" [(ngModel)]="selectedMovieQuality">
                        <option value="" selected>Select Movie Quality</option>
                        <option *ngFor="let option of movieFareData" [value]="option">
                            <h5 class="quality">{{option?.quality?.qualityName}}</h5>
                            <h5>-</h5>
                            <h5 class="price">₹{{option?.fare?.totalAmount}}</h5>
                        </option>
                    </select>
                </div>
                <div class="disclaimer-text w-100">
                    Streaming supported on mobile, website, Android TV, Apple TV, and Fire TV. Plays on <a href="#">compatible devices</a>
                    By continuing, I agree to  <a href="#">Terms and Conditions</a> and express my consent to complete the transaction.
                </div>
              </div>
                <button class="btn gradient-button gradient-button-4 rounded-pill go-book px-2 font-1-4 mr-5" 
                    (click)="checkUserInfo()">{{user?.primaryEmail && user?.primaryPhoneNumber? 'Proceed to payment': 'Continue'}}
                </button>
            </div>
          </div>
        </div>
      </div>
</div>
</ng-template>

<!-- ====== Modal popup for videoplayer ====== -->
<ng-template #moviePlayer>
    <div class="" id="moviePlayer" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="termsLabel" aria-hidden="true">
        <button type="button" class="closelarge close fs-20 text-right text-white text-shadow-0 mx-4 pointer"
            (click)="movieModalClose()">
            <span aria-hidden="true">×</span>
        </button>
        <div style="position:relative;padding-top:56.25%;">
            <iframe 
            src="https://iframe.mediadelivery.net/embed/235148/2799f257-6e4c-48cf-a18e-0fd28ec50013?token=a5bac2a92a96f6a4197d376dcf4d083aa22b4e6e8e72037cf565d544f61cf37f&expires=1717027200"
            loading="lazy" 
            style="border:0;position:absolute;top:0;height:100%;width:96%;" 
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
            allowfullscreen="true">
            </iframe>
        </div>
    </div>
</ng-template>

<ng-template #login>
    <mp-login [modalRef]="modalRef" (isLoggedIn)="isUserInfo"></mp-login>
</ng-template>
<ng-template #loginTemplate>
    <mp-login [modalRef]="modalRef" (isLoggedIn)="isUserInfo"></mp-login>
</ng-template>
<ng-template #userInfoTemplate>
    <div class="modal-bg" id="userInfoTemplate" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="signinLabel" aria-hidden="true">
        <div class="modal-dialog-centered modal-dialog-scrollable modal-custom-30">
            <div class="modal-content shadow-custom-9 bg-modal-lin border-bottom border-primary border-4 border-top-0 border-left-0 border-right-0">
                <div class="container-fluid pt-4 pb-2 bg-0F1628 ">
                    <div class="row">
                        <div class="col-xl-12 text-center">
                            <span class="modal-title go-medium text-primary text-center h3" id="seatLabel">Update contact details</span>
                            <button type="button" class="close fs-20 text-right text-9F9F9F text-shadow-0 position-absolute r-5"
                              aria-label="Close" (click)="movieModalClose()">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-left border-top border-primary bg-020A1C ">
                    <div class="">
                        <div class="col-xl-12 text-center mb-4">
                            <div class="col-xl-10 mx-auto">
                            </div>
                            <div class="form">
                                <div class="form-group">
                                    <label for="email" class="text-primary w-100 text-left">Email Address</label>
                                    <input type="email" id="email" class="form-control" [ngClass]="primaryEmail? '' : 'error-broder'" [(ngModel)]="primaryEmail" placeholder="Enter your Email">
                                </div>
                                <div class="form-group">
                                    <label for="mobile" class="text-primary w-100 text-left">Phone Number</label>
                                    <input type="text" id="mobile" class="form-control" [ngClass]="primaryNumber? '' : 'error-broder'" [(ngModel)]="primaryNumber" placeholder="Enter your Mobile">
                                </div>
                                <div class="btn-group">
                                    <button class="btn gradient-button gradient-button-4 rounded-pill go-book px-2 w-100 font-1-2 mr-5" 
                                    (click)="updatePhoneNumberEmail(); proceedToPay()" [disabled]="!primaryEmail || !primaryNumber">Proceed to payment</button>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</ng-template>

<mp-card *ngIf="isLoading"></mp-card>
