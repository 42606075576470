<mp-header></mp-header>

<div class="container-fluid px-5 bg-020A1C ">
    <div class="row px-5 bg-secondary shadow-custom-5 py-3">
        <div class="col-xl-12">
            <div class="row align-items-center">
                <div class="col-xl-1">
                    <div class="back-btn position-relative z-index-99">
                        <svg id="Component_49_32" data-name="Component 49 – 32" xmlns="http://www.w3.org/2000/svg"
                            width="38" height="38" viewBox="0 0 38 38">
                            <circle id="Ellipse_39" (click)="route()" data-name="Ellipse 39" class="cls-1" cx="19"
                                cy="19" r="19"></circle>
                            <g id="Group_450" data-name="Group 450" transform="translate(-134.505 -201.219)">
                                <path id="Polygon_9" data-name="Polygon 9" class="cls-2" d="M5.5,0,11,6H0Z"
                                    transform="translate(142.505 226.219) rotate(-90)"></path>
                                <rect id="Rectangle_60" data-name="Rectangle 60" class="cls-2" width="17.492"
                                    height="2.018" transform="translate(147.683 219.709)"></rect>
                            </g>
                        </svg>
                    </div>
                </div>
                <!-- <div class="col-xl-3 position-absolute text-center">
                    <img class="w-35" src="assets/images/img/user.svg">
                </div> -->
                <div class="offset-2 col-xl-6 text-center">
                    <span class="go-medium text-white h3 ">
                        Terms and Conditions
                    </span>
                </div>
                <div class="col-xl-3 text-right">
                    <img (click)="route()" class="pointer" src="assets/images/img/home.svg">
                    <!--  <img src="assets/images/img/home.svg" [routerLink]="['/']"> -->
                </div>
            </div>
        </div>
    </div>
    <div class="row px-5 shadow-custom-5 py-5">
        <!-- <div class="col-xl-12 px-5 mx-5 pointer">
            <ul class="nav nav-tabs offset-1 col-xl-10 hm-tab border-0 mt-6  mx-5" id="myTab" role="tablist">
                <li class="nav-item text-center" role="presentation">
                    <a class="nav-link bg-transparent border-0 text-white active show" id="home-tab" role="tab"
                        aria-controls="home" aria-selected="true" (click)="switchTab('summary')" [ngClass]="{
                        'active': navTab == 'summary'
                    }">
                        <div class="hm-tab-ico">
                            <h5 class="mt-1 lead go-book font-weight-bold">Online booking</h5>
                        </div>
                    </a>
                </li>
                <li class="nav-item text-center mx-5 px-5" role="presentation">
                    <a class="nav-link bg-transparent border-0 text-white" id="profile-tab" role="tab"
                        aria-controls="profile" aria-selected="false" (click)="switchTab('userReview')" [ngClass]="{
                        'active': navTab == 'userReview'
                    }">
                        <div class="hm-tab-ico">
                            <h5 class="mt-1 go-book font-weight-bold">Contest and Promotions</h5>
                        </div>
                    </a>
                </li>
                <li class="nav-item text-center" role="presentation">
                    <a class="nav-link bg-transparent border-0 text-white" id="messages-tab" role="tab"
                        aria-controls="messages" aria-selected="false" (click)="switchTab('criticReview')" [ngClass]="{
                        'active': navTab == 'criticReview'
                    }">
                        <div class="hm-tab-ico">
                            <h5 class="mt-1 go-book font-weight-bold">Privilege Program Terms &amp; Conditions</h5>
                        </div>
                    </a>
                </li>
            </ul>
        </div> -->
        <div class="container-fluid">
            <div class="row mb-5">
                <div class="col-xl-12">
                    <div class="tab-content mx-5">
                        <!-- <div *ngIf="navTab=='userReview'" [ngClass]="{'active': navTab == 'userReview'}"
                            class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row align-items-end">
                                <div class="col-xl-12 pt-5 pb-5 px-5 shadow-custom hm-pos-bg">
                                    <div class="row no-gutters">
                                        <div class="col-xl-12 px-5">
                                            
                                            <div class="go-book text-white lead  my-4">By accessing and using this
                                                website the user accepts and agrees to be bound by the Terms and
                                                Conditions. Users should check for current Terms and Conditions as these
                                                can be updated and changed from time to time.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Definitions
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Movie Panda means and includes all the brands working under the head of
                                                Movie Panda Limited.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                User means the person accessing the website and availing the online
                                                booking facility.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Customer includes all the patrons of Movie Panda and also includes the
                                                user defined above.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Website means <a class="go-book text-primary lead  text-decoration-none"
                                                    href="www.moviepanda.in">Movie Panda</a>.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Purchasing tickets online
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Children aged 3 years and above will require a separate ticket.Only UP
                                                Locations Ticket is compulsory for children of 5 years &amp; above</div>
                                            <div class="go-book text-white lead  my-4">
                                                The 3D glasses will be available at the cinema for 3D films and must be
                                                returned before you exit the premises. 3D Glasses are chargeable
                                                (refundable/non-refundable) as per individual cinema policies.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Items like laptop, cameras, knifes, lighter, match box, cigarettes,
                                                firearms and all types of inflammable objects are strictly prohibited.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Items like carrybags eatables, helmets, handbags are not allowed inside
                                                the theaters are strictly prohibited. Kindly deposit at the baggage
                                                counter of mall/ cinema.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Booking of Tickets
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The user must provide Movie Panda with the correct information
                                                pertaining to the cardholders name, card type, the card number, the card
                                                expiry date and the security code on the back of the card (if any) in
                                                order to make payment to Movie Panda Cinemas.
                                                The user shall be responsible for the correctness of the information
                                                provided and shall not be indemnified if the information is wrongly
                                                entered whether intentional or unintentional.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The user must ensure the correctness of all details of the booking
                                                before finally booking their tickets as Movie Panda will accept no
                                                responsibility and will not issue a refund for wrong bookings that are
                                                the fault of the user. If the user experiences
                                                problems with the booking process, they are to call the helpline number
                                                of Movie Panda specified on the booking page. The Portal shall not
                                                cancel any bookings once the transaction is completed.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Once the booking has been processed, the user will receive a
                                                confirmation e-mail to their e-mail address with all the relevant
                                                details of their booking. Movie Panda is not responsible and will not
                                                issue a refund to the user for selecting the wrong tickets
                                                or if the user does not meet the minimum age requirements for that
                                                particular film. The online booking is non transferable and cannot be
                                                further sold.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                To collect the tickets the user must present the credit/debit card that
                                                was used to book the tickets in that particular cinema. The user has to
                                                carry the print out of the confirmation mail. The user may procure the
                                                ticket from the automatic ticket collection
                                                points or they can present the print out to the Movie Panda
                                                representative at the ticket counter of the concerned Cineplex. The user
                                                shall also carry proof of age for movies certified 'A'.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Unless specified otherwise User is subscribed to Movie Panda &amp; Movie
                                                Panda Partner promotions related communication through SMS &amp; Email.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Cancellation of tickets
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The booking shall be deemed to be cancelled in the following
                                                circumstances:-
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The booking is valid only for the viewing of the film at a specified
                                                multiplex of Movie Panda. The booking shall become valueless and non
                                                refundable if not used on the date specified on its face.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                If, in the opinion of a representative of the Movie Panda, the user is
                                                in breach of these Online Booking Terms or is under the influence of
                                                drugs or alcohol, or that it is necessary for the safety or comfort or
                                                security of other customers or for the protection
                                                of property, the representative reserves the right to refuse the entry
                                                or request the Customer to leave the multiplex and may if necessary
                                                physically remove the Customer from the multiplex or physically
                                                restrain the Customer.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Movie Panda is required to abide by and enforce the age restrictions as
                                                specified by the Law for the time being in force. In the event that an
                                                authorised Movie Panda representative is of the opinion that the user
                                                does not meet the minimum age requirement
                                                and the user cannot provide photographic proof that they are of the
                                                required age, Movie Panda will not permit entry to that performance or
                                                film.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The customer has an option to cancel the ticket booked online on
                                                www.pvrcinemas.com.., Movie Panda mobile App, in.bookmyshow.com or
                                                BookMyShow App on the terms as mentioned herein-below. However, no
                                                ticket and F&amp; B cancellation will be entertained
                                                once patron enters the cinema premises. This facility is currently only
                                                available in all theatres barring Movie Panda Opulent.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Users can cancel tickets only 5 times every quarter. Post that their
                                                profile will be blocked from making further cancellations until the
                                                start of the next quarter.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Users can contact Movie Panda customer care for authentication to get
                                                their profiles unlocked.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Unlocking may take up to 7 business days
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Terms of cancellation are as follows:
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                <ul class="list-unstyled">
                                                    <li class="my-4">Transaction can be cancelled only after 10 minutes
                                                        of booking the ticket/F&amp;B.</li>
                                                    <li class="my-4">No cancellation will be allowed within 20 minutes
                                                        and movie start time.</li>
                                                    <li class="my-4">For tickets cancelled 2 hours before show start
                                                        time, 75% of ticket value and 100% of F&amp;B value will be
                                                        refunded.</li>
                                                    <li class="my-4">For tickets cancelled from 20 mins to 2 hours
                                                        before show start time, 50% of ticket value, 100% of F&amp;B
                                                        value will be refunded.</li>
                                                    <li class="my-4">In case of F&amp;B booking (without ticket) through
                                                        any mode, there is no cancellation available.</li>
                                                    <li class="my-4">No refund will be given for booking done through or
                                                        amount paid by M-coupon/Gift card/Star Pass/Voucher/Promo.Also
                                                        ticket cancellation cannot be applied/clubbed on a booking done
                                                        through or an offer
                                                        given by us or facilitate for business partner.</li>
                                                    <li class="my-4">No partial cancellation is allowed.The patron will
                                                        have to cancel the complete transaction.</li>
                                                    <li class="my-4">Convenience fee and taxes applicable thereon will
                                                        not be refunded in case of cancellation</li>
                                                    <li class="my-4">The refund for the cancelled ticket will be
                                                        processed in minimum 7 working days.</li>
                                                    <li class="my-4">This is an offer / facility given by Company, which
                                                        can be withdrawn anytime without giving prior intimation to
                                                        patron.</li>
                                                </ul>
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                General Conditions
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Movie Panda will do everything possible to ensure the show and operating
                                                times of the cinema are true to the advertisements. However due to
                                                circumstances beyond Movie Panda's control there may be times that a
                                                film has to be cancelled or shown at a different
                                                time. In this instance the user may be given a refund of the ticket
                                                only. The mode of refund shall be at the sole discretion of Movie Panda.
                                                If any show is cancelled or cancellation is done from the management
                                                of Movie Panda then the formalities for the same will be taken in to
                                                consideration. The user agrees not to bring in any action against Movie
                                                Panda in such instance.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The user who has booked for a movie certified as 'A', must provide Movie
                                                Panda Cinemas with the relevant proof of entitlement when the tickets
                                                are being collected or upon entry to the cinema.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The Company reserves its rights to suspend the operations of any
                                                Cineplex/ Multiplex/Audi due to some exigencies and any offer, scheme,
                                                promotions shall not be valid/ applicable in respect of such Multiplex/
                                                Cineplex/ Audi. The Company shall not be liable
                                                for any losses/ damages arising \ connected with such suspension. This
                                                clause shall also be applicable and binding on all Company's agents,
                                                partners and agents, partners of its affiliate/ group companies.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Warranties and Indemnification
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The user represents that he/she is of sufficient legal age to use this
                                                service, and he/she possess the legal right and ability to create
                                                binding obligations for any liability he/she may incur as a result of
                                                the use of this service.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The user understands that he/she is financially responsible for all uses
                                                of this service by him/her and those using his/her login information.
                                                The user will supervise all usage of the booking facility under his/her
                                                name or account.
                                            </div>
                                            <div class="go-book text-white lead  my-4">The user warrants that all
                                                information supplied by him/her and members of his/her household in
                                                using the booking facility are true and accurate.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div [ngClass]="{'active': navTab == 'criticReview'}" *ngIf="navTab=='criticReview'"
                            class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div class="row align-items-end">
                                <div class="col-xl-12 pt-5 pb-5 px-5 shadow-custom hm-pos-bg">
                                    <div class="row no-gutters">
                                        <div class="col-xl-12 px-5">
                                            <div class="go-book text-white lead  my-4">
                                                General Terms and conditions
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Before entering a promotion, contest read these rules and the site's
                                                terms, conditions and privacy information. by entering the promotion,
                                                contest you agree to comply with the rules and the site's terms,
                                                conditions and privacy information.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Contest and promotions are open for participation of all entrants who
                                                are 18 years of age or older at the time of entry in the contest.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Directors, officers, employees, contractors, and agents of Movie Panda
                                                Limited ( hereinafter referred as" Company") and/or its associate,
                                                subsidiary or joint ventures, agencies, dealers, retailers, sponsors and
                                                any company associated with the promotion/
                                                contest, employees and members of their immediate families (spouses,
                                                parents, siblings, and children) are not eligible to participate in this
                                                contest.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                This contest is valid for resident citizens of India Only.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Each Promotion will contain a specific time period/ promotion period
                                                within which entries will be accepted. The Promotion Period for each
                                                Promotion shall be posted on the Company's website with that particular
                                                Promotion. Only entries received during the
                                                Promotion Period will be accepted and entertained.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                An individual may enter a Promotion/contest once only. The use of any
                                                automated launching or entry software or any other means that permits an
                                                entrant to automatically enter repeatedly or in excess of the entry
                                                limitations is prohibited. Company is not
                                                responsible for lost, late, illegible, stolen, incomplete, invalid,
                                                unintelligible, misdirected, technically corrupted or garbled entries,
                                                which will be disqualified, or for problems of any kind whether
                                                mechanical, human or electronic. Proof of submission will not be deemed
                                                to be proof of receipt by Company. All entries must be in English.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                The selection of contest prizes shall be the exclusive prerogative of
                                                Company and Company reserves the absolute right to add or modify the
                                                terms and conditions of the present Contest, selection of prizes without
                                                giving any notice to the participants and
                                                also reserves the absolute right to cancel any part or all of the
                                                prizes. Further if the specified prize is unavailable due to unforeseen
                                                circumstances Company may substitute another prize of similar or
                                                equal value.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Company shall conduct the lucky draw (s) as per its sole discretion to
                                                announce the winner of the contest. No correspondence, queries on the
                                                method/ manner of conducting the luck draw(s) shall be entertained by
                                                Company from any party/ individual in any
                                                circumstances whatsoever. The winner name(s) shall be intimated
                                                individually either through phone call or SMS on the mobile number given
                                                by the participant or any other mode as decided by the Company with
                                                that particular Promotion/ contest. If in the course of the contest,
                                                prior to confirming the winner's identification and address, the phone
                                                line drops out or becomes disconnected for any reason, Company
                                                shall not be responsible for awarding such prize to that caller and will
                                                go to next available caller. The winner shall be responsible to provide
                                                the certified ID proof , residence proof, PAN details as and
                                                when demanded by the Company.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                All prizes must be collected with in 60 days of wining or as mentioned
                                                with that particular Promotion. Company shall not notify the winner
                                                about the time remaining it shall be the sole responsibility of the
                                                winner(s) to claim their prize(s). All unclaimed
                                                prizes after the 60 days from wining date will be forfeited/ disposed of
                                                by Company. Further in the event winner choose not to accept / claim the
                                                prize then it may be awarded to the next winner/ runner-up
                                                at the sole discretion of the Company.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Prizes shall be delivered at the address given by the participants, in
                                                the event of time based contests, which include events/ screening of
                                                movies( cinematographic films)it shall be the sole responsibility of the
                                                winner(s) to collect the prize(s) ( passes
                                                etc.) from the related screening movie theatre within the specified time
                                                with that particular Promotion. Further if winner is unable to provide
                                                the Company with sufficient proofs etc. as demanded for identification
                                                purpose then Company reserves the forfeit participant (s) / winner claim
                                                and further it may be awarded to the next winner/ runner-up at the sole
                                                discretion of the Company on the fulfillment of terms as conditions.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Winner shall be solely responsible for any additional cost inclusive of
                                                transportation, insurance incidental expenses ( if any) to be required
                                                to claim/ avail the winning prize. The winner have to bear all the all
                                                the applicable tax component as per the
                                                Income tax prevailing rules from time to time. All gift/ prices shall be
                                                subject to TDS as per income tax act 1961.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Prizes/ gifts are subject to availability ,non- negotiable, non
                                                transferable, and not redeemable for cash. The person who originally
                                                entered in the contest/ promotion can be awarded the prize. Further no
                                                other family members, friends, associate or any
                                                other person will be able to participate on other person's behalf in any
                                                circumstances whatsoever.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                By participating in the contest / promotion all winner grants Company to
                                                use their names photograph, likeness, voice, prize information, and
                                                biographical information for publicity, marketing and promotional
                                                purposes for this and other future contests/
                                                promotions and waive any claim of royalty , right or remuneration for
                                                such use. Shall always.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Information details of various third parties and product on its(
                                                Company's) theatre premises or website is for informational purpose only
                                                and constitute neither endorsement nor a recommendation. Licenses and
                                                warranties , if any regarding the third Party(
                                                ies) product/ services shall always bind and take effect between the
                                                participant and the third Party . Winner expressly agreed that any claim
                                                with respect to third Party (ies) Product/ services as wining
                                                prize/ gift including but not limited to defect in manufacturing
                                                /quality/ services / item/ warranty shall be raised directly to the
                                                third Party(ies) or it supplier directly and Company shall not be
                                                responsible
                                                in any circumstances whatsoever. Winner also agrees to use the Product/
                                                services at its own risk and responsibility.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Company shall not be responsible/ liable in the event of any loss,
                                                damage ( including but not limited to direct, indirect or consequential
                                                damage) arising from personal injury or death, or loss, or damage to
                                                property which is suffered or sustained in
                                                connection with the contest/ promotion or the prize(s) gifts.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                These terms and conditions are governed in accordance with the laws of
                                                India. Any Dispute shall be subject to jurisdiction of exclusive courts
                                                in Delhi.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Participants in this contest implies acceptance of all the terms and
                                                conditions hereof
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                In addition to above stated general terms and conditions , other
                                                conditions specific to the contest may be imposed by the Company,
                                                sponsors. Participants are requested to refer to such terms and
                                                conditions which may be displayed separately on the relevant
                                                contest page.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Any failure by an entrant, including any prize winner, to comply with
                                                any of the Rules or the Site's Terms, Conditions and Privacy Information
                                                may result in disqualification from the Promotion. All entries, whether
                                                they are eligible entries or ineligible
                                                entries, are the exclusive property of Company. Company is not
                                                responsible for any typographical errors in the Rules or in any other
                                                communication surrounding a Promotion or for any technical malfunction
                                                or error relating to the Promotion. Company reserves the right to amend
                                                or interpret the Rules at any time, upon published notice to
                                                participants on its website. Promotion participants agree to release,
                                                indemnify, and hold harmless Company and its directors, officers,
                                                employees and agents from any and all liability regarding the Promotion,
                                                including any injuries, losses, or damages (compensatory, direct,
                                                incidental, consequential, or otherwise) regarding the use or misuse of
                                                any Prize, any event beyond Company's control resulting in the
                                                disruption, cancellation, or postponement of the receipt of the Prize,
                                                or any typographical errors or technical malfunctions associated with
                                                the Promotion. Company reserves the right to disqualify any entry that
                                                it, in its sole discretion, determines :
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                <ol>
                                                    <li>to be in violation of the Rules,</li>
                                                    <li>submitted by fraud or by tampering with the entry process, or
                                                    </li>
                                                    <li>contains inaccurate or fraudulent information. any attempt by
                                                        any individual to deliberately damage the site or undermine the
                                                        legitimate operation of the promotion is a violation of criminal
                                                        and civil
                                                        laws. in the event such an attempt occurs, company reserves the
                                                        right to seek damages from such individual to the fullest extent
                                                        permitted by law.</li>
                                                </ol>
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Comments and materials sent to Company including feedback and other
                                                communications of any kind as well as submissions of work as an entry in
                                                any contest / promotion held on this website or theatre premises, shall
                                                be deemed to be non confidential. Further
                                                Company shall be free to reproduce, distribute, publicly display such
                                                feedback, contest entries without limitations or obligations of any
                                                kinds as also to use any ideas, concept, know how or techniques
                                                contained
                                                in such feedback or contest or entry for any purpose.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                Company also reserves the right to summarize analyze data that we
                                                collect and also reserves the right to publish, distribute , share
                                                summaries of the data collected with sponsors or other business partners
                                                without limitation or obligations of any kind.
                                            </div>
                                            <div class="go-book text-white lead  my-4">
                                                These rules may be amended time to time at the sole discretion of
                                                Company. Company reserves the right to discontinue the promotion
                                                /contest at any stage.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="tab-pane" [ngClass]="{'active': navTab == 'summary'}" *ngIf="navTab=='summary'"
                            id="messages" role="tabpanel" aria-labelledby="messages-tab">
                            <div class="col-xl-12 pt-5 pb-5 px-5  bg-020A1C shadow-custom hm-pos-bg">
                                <div class="row no-gutters">
                                    <div class="col-xl-12 px-5">
                                        <div class="go-book text-white lead  my-4">
                                            Acceptance of terms
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The moviepanda.in website ("Website") is an internet based entertainment
                                            ticketing portal owned and operated by Lixo Technologies Private Limited
                                            ("Lixo Technology"), a company incorporated under the laws of India, with
                                            its registered office at #3C2,
                                            10th Floor, Seethakathi Business Centre, Anna Salai, Thousand Lights,
                                            Chennai, Tamil Nadu 600006.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Through the Website, Lixo Technology shall provide you ("User")
                                            entertainment-related information, pricing, availability and reservations
                                            for ticket for cinemas, plays, concerts, sports events across cities and
                                            rural areas throughout India ("Service"),
                                            as more particularly described and defined in the terms of service ("TOS")
                                            relating to such Service, This Service may be availed by the User, his
                                            family members </div>
                                        <div class="go-book text-white lead  my-4">
                                            This User Agreement ("Agreement") sets out the terms and conditions on which
                                            Lixo Technology shall provide the Services to the User through the Website.
                                            In addition to this Agreement and depending on the Services opted for by the
                                            User, the User shall
                                            be required to read and accept the relevant TOS for such Service, which may
                                            be updated or modified by Lixo Technology from time to time. Such TOS shall
                                            be deemed to be a part of this Agreement and in the event
                                            of a conflict between such TOS and this Agreement, the terms of this
                                            Agreement shall prevail. </div>
                                        <div class="go-book text-white lead  my-4">
                                            Use of the Website is offered to the User conditioned on acceptance without
                                            modification of all the terms, conditions and notices contained in this
                                            Agreement and the TOS, as may be posted on the Website from time to time.
                                            For the removal of doubts, it
                                            is clarified that use of the Website by the User constitutes an
                                            acknowledgement and acceptance by the User of this Agreement and the TOS. If
                                            the User does not agree with any part of such terms, conditions and
                                            notices, the User must not use the Website.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Additionally, the Service Provider
                                            may provide terms and guidelines that govern particular features, offers or
                                            the operating rules and policies applicable to each Service (for example,
                                            movie tickets, gift vouchers,
                                            Combo’s, etc.). The User shall be responsible for ensuring compliance with
                                            the terms and guidelines or operating rules and policies of the Service
                                            Provider with whom the User elects to deal, including terms
                                            and conditions set forth in a Service Providers. In the event that any of
                                            the terms, conditions and notices contained in this Agreement or the TOS
                                            conflict with the additional/other terms and guidelines specified
                                            by the Service Provider, then the latter terms/guidelines shall prevail.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Modification of terms</div>
                                        <div class="go-book text-white lead  my-4">Lixo Technology reserves the right to
                                            change the terms, conditions and notices under which the Services are
                                            offered through the Website, including but not limited to the charges for
                                            the Services provided through
                                            the Website. The User shall be responsible for regularly reviewing these
                                            terms and conditions.</div>
                                        <div class="go-book text-white lead  my-4">Privacy policy</div>
                                        <div class="go-book text-white lead  my-4">
                                            The User hereby consents, expresses and agrees that he has read and fully
                                            understands the Privacy Policy of Lixo Technology in respect of the Website,
                                            The User further consents that the terms and contents of such Privacy Policy
                                            are acceptable to him.</div>
                                        <div class="go-book text-white lead  my-4">
                                            Any customer grievances can be reported to the Grievance Officer
                                            specifically dedicated to address all customer concerns. You may contact the
                                            Grievance Officer at <a
                                                class="go-book text-primary lead  text-decoration-none"
                                                [href]="mailto('allears@moviepanda.in', '')">allears@moviepanda.in</a>.

                                        </div>
                                        <div class="go-book text-white lead  my-4">Limited user
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The User agrees and undertakes not to sell, trade or resell or exploit for
                                            any commercial purposes, any portion of the Service. For the removal of
                                            doubt, it is clarified that the Website is not for commercial use but is
                                            specifically meant for personal
                                            use only.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The User further agrees and undertakes not to reverse engineer, modify,
                                            copy, distribute, transmit, display, perform, reproduce, publish, license,
                                            create derivative works from, transfer, or sell any information, software,
                                            products or services obtained
                                            from the Website. For the removal of doubt, it is clarified that unlimited
                                            or wholesale reproduction, copying of the content for commercial or
                                            non-commercial purposes and unwarranted modification of data and
                                            information within the content of the Website is not permitted.

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Disclaimer of warranties/Limitation of liability
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology has endeavored to ensure that all the information on the
                                            website is correct, but Lixo Technology neither warrants nor makes any
                                            representations regarding the quality, accuracy or completeness of any data
                                            or information contained. Lixo
                                            Technology makes no warranty, express or implied, concerning the website
                                            and/or its contents and disclaims all warranties of fitness for a particular
                                            purpose and warranties of merchantability in respect of services,
                                            including any liability, responsibility or any other claim, whatsoever, in
                                            respect of any loss, whether direct or consequential, to any user or any
                                            other person, arising out of or from the use of the information
                                            contained in the website.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Since Lixo Technology acts only as a booking agent, it shall not have any
                                            liability whatsoever for any aspect of the arrangements between the service
                                            provider and the user as regards the standards of services provided by the
                                            service providers. In no circumstances
                                            shall Lixo Technology be liable for the services provided by the service
                                            provider.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Although Lixo Technology makes reasonable commercial efforts to ensure that
                                            the description and content in the TOS and on each page of the website is
                                            correct, it does not, however, take responsibility for changes that occurred
                                            due to human or data entry
                                            errors or for any loss or damages suffered by any user due to any
                                            information contained herein. Also, Lixo Technology is not the service
                                            provider and cannot therefore control or prevent changes in the published
                                            descriptions which are based upon information provided by

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology does not endorse any advertiser on its website in any
                                            manner.The users are requested to verify the accuracy of all information on
                                            their own before undertaking any reliance on such information. </div>
                                        <div class="go-book text-white lead  my-4">
                                            In no event shall Lixo Technology be liable for any direct, indirect,
                                            punitive, incidental, special, consequential damages or any other damages
                                            resulting from: (a) the use or the inability to use the services; (b) the
                                            cost of procurement of substitute
                                            goods and services or resulting from any goods, information or services
                                            purchased or obtained or messages received or transactions entered into
                                            through the services; (c) unauthorized access to or alteration
                                            of the user's transmissions or data; (d) any other matter relating to the
                                            services; including, without limitation, damages for loss of use, data or
                                            profits, arising out of or in any way connected with the use
                                            or performance of the website. Neither shall Lixo Technology be responsible
                                            for the delay or inability to use the website or related services, the
                                            provision of or failure to provide services, or for any information,
                                            software, products, services and related graphics obtained through the
                                            website, or otherwise arising out of the use of the website, whether based
                                            on contract, tort, negligence, strict liability or otherwise.
                                            further, Lixo Technology shall not be held responsible for non-availability
                                            of the website during periodic maintenance operations or any unplanned
                                            suspension of access to the website that may occur due to technical
                                            reasons or for any reason beyond Lixo Technology’s control. the user
                                            understands and agrees that any material and/or data downloaded or otherwise
                                            obtained through the website is done entirely at their own discretion
                                            and risk and they will be solely responsible for any damage to their
                                            computer systems or loss of data that results from the download of such
                                            material and/or data. These limitations, disclaimer of warranties
                                            and exclusions apply without regard to whether the damages arise from (a)
                                            breach of contract, (b) breach of warranty, (c) negligence, or (d) any other
                                            cause of action, to the extent such exclusion and limitations
                                            are not prohibited by applicable law.the maximum liability on part of Lixo
                                            Technology arising under any circumstances, in respect of any services
                                            offered on the site, shall be limited to the refund of total
                                            amount received from the customer for availing the services less any
                                            cancellation, refund or others charges, as may be applicable. in no case the
                                            liability shall include any consequential loss, damage or additional
                                            expense whatsoever.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Links to third party sites
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The Website may contain links to other websites ("Linked Sites"). The Linked
                                            Sites are not under the control of Lixo Technology or the Website and Lixo
                                            Technology is not responsible for the contents of any Linked Site, including
                                            without limitation any
                                            link contained in a Linked Site, or any changes or updates to a Linked Site.
                                            Lixo Technology is not responsible for any form of transmission, whatsoever,
                                            received by the User from any Linked Site. Lixo Technology
                                            is providing these links to the User only as a convenience, and the
                                            inclusion of any link does not imply endorsement by Lixo Technology or the
                                            Website of the Linked Sites or any association with its operators
                                            or owners including the legal heirs or assigns thereof.

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology is not responsible for any errors, omissions or
                                            representations on any Linked Site. Lixo Technology does not endorse any
                                            advertiser on any Linked Site in any manner. The Users are requested to
                                            verify the accuracy of all information on
                                            their own before undertaking any reliance on such information.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Prohibition against unlawful use
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            As a condition of the use of the Website, the User warrants that they will
                                            not use the Website for any purpose that is unlawful or illegal under any
                                            law for the time being in force within or outside India or prohibited by
                                            this Agreement and/or the TOS
                                            including both specific and implied. In addition, the Website shall not be
                                            used in any manner, which could damage, disable, overburden or impair it or
                                            interfere with any other party's use and/or enjoyment of
                                            the Website. The User shall refrain from obtaining or attempting to obtain
                                            any materials or information through any means not intentionally made
                                            available or provided for or through the Website.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Use of communication services
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The Website may contain services such as email, chat, WhatsApp, bulletin
                                            board services, information related to recommendations, news groups, forums,
                                            communities, personal web pages, calendars, and/or other messages
                                            (hereinafter collectively referred
                                            to as "Communication Services"). The User agrees and undertakes to use the
                                            Communication Services only to post, send and receive messages and material
                                            that are proper and related to the particular Communication
                                            Service.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            By way of example, and not as a limitation, the User agrees and undertakes
                                            that when using a Communication Service, the User will not: defame, abuse,
                                            harass, stalk, threaten or otherwise violate the legal rights of others;
                                            upload files that contain software
                                            or other material protected by intellectual property laws unless the User
                                            owns or controls the rights thereto or have received all necessary consents;
                                            upload or distribute files that contain viruses, corrupted
                                            files, or any other similar software or programs that may damage the
                                            operation of the Website or another's computer; advertise or offer to sell
                                            or buy any goods or services for any business purpose, unless such
                                            Communication Service specifically allows such messages; conduct or forward
                                            surveys, contests, pyramid schemes or chain letters; download any file
                                            posted by another user of a Communication Service that the User
                                            know, or reasonably should know, cannot be legally distributed in such
                                            manner; falsify or delete any author attributions, legal or other proper
                                            notices or proprietary designations or labels of the origin or
                                            source of software or other material contained in a file that is uploaded;
                                            violate any code of conduct or other guidelines, which may be applicable for
                                            or to any particular Communication Service; violate any
                                            applicable laws or regulations for the time being in force in or outside
                                            India; and violate any of the terms and conditions of this Agreement or any
                                            other terms and conditions for the use of the Website contained
                                            elsewhere herein.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology has no obligation to monitor the Communication Services.
                                            However, Lixo Technology reserves the right to review materials posted
                                            through Communication Service and to remove any materials in its sole
                                            discretion. Lixo Technology reserves
                                            the right to terminate the User's access to any or all of the Communication
                                            Services at any time without notice for any reason whatsoever.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology reserves the right at all times to disclose any information
                                            as is necessary to satisfy or comply with any applicable law, regulation,
                                            legal process or governmental request, or to edit, refuse to post or to
                                            remove any information or materials,
                                            in whole or in part, in Lixo Technology 's sole discretion.

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology does not control or endorse the content, messages or
                                            information found in any communication service and, therefore, Lixo
                                            Technology specifically disclaims any liability or responsibility whatsoever
                                            with regard to the communication services
                                            and any actions resulting from the user's participation in any communication
                                            service.

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Materials uploaded to a Communication Service may be subject to posted
                                            limitations on usage, reproduction and/or dissemination. User is responsible
                                            for keeping himself updated of and adhering to such limitations if they
                                            download the materials.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Termination/Access restriction
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology reserves the right, in its sole discretion, to terminate the
                                            access to the website and the related services or any portion thereof at any
                                            time, without notice. </div>
                                        <div class="go-book text-white lead  my-4">
                                            Fees payment
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology reserves the right to charge listing fees for certain
                                            listings, as well as transaction fees based on certain completed
                                            transactions using the Lixo Technology Services. Lixo Technology further
                                            reserves the right to alter any and all fees
                                            from time to time, without notice.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The User shall be liable to pay all applicable charges, fees, duties, taxes,
                                            levies and assessments for availing the Lixo Technology Services. </div>
                                        <div class="go-book text-white lead  my-4">
                                            User's obligation and user account
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            In consideration of use of the Website, the User represents and confirms
                                            that the User is of legal age to enter into a binding contract and is not a
                                            person barred from using the Website and/or receiving the Services under the
                                            laws of India or other applicable
                                            law.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            To avail a Service the User has and must continue to maintain at his sole
                                            cost: (a) all the necessary equipment including a computer and modem etc. to
                                            access the Website/avail Services; (b) own access to the World Wide Web. The
                                            User shall be responsible
                                            for accessing the Services and that access may involve third party fees
                                            including, airtime charges or internet service providers charges which are
                                            to be exclusively borne by the User.

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The user also understands that the services may include certain
                                            communications from Lixo Technology as service announcements and
                                            administrative messages. the user understands and agrees that the services
                                            are provided on an "as is" basis and that Lixo
                                            Technology does not assume any responsibility for deletions, mis-delivery or
                                            failure to store any user communications or personalized settings.

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Registration of the User on the Website is optional. If the User opts to
                                            register himself on the Website, upon completion of the registration
                                            process, the User shall receive a user id and password. The User agrees and
                                            undertakes at all times to be responsible
                                            for maintaining the confidentiality of the password and user id, and shall
                                            be fully responsible for all activities that occur by use of such password
                                            or user id. Further, the User agrees not to use any other
                                            party's user id and password for any purpose whatsoever without proper
                                            authorization from such party. You are responsible for the security of your
                                            password and for all transactions undertaken using your password
                                            through our service. The Password entered by you is transmitted in one- way
                                            encrypted form to our database and stored as such. Thus the Password will
                                            not be known even to Lixo Technology. You confirm that you
                                            are the authorised holder of the credit card or the original account holder
                                            used in the transactions you make using the Lixo Technology services. Lixo
                                            Technology will not be responsible for any financial loss,
                                            inconvenience or mental agony resulting from misuse of your
                                            ID/password/credit card number/account details number for using Lixo
                                            Technology Services.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The user also agrees and undertakes to immediately notify Lixo Technology of
                                            any unauthorized use of the user's password or user id and to ensure that
                                            the user logs off at the end of each session at the website. Lixo Technology
                                            shall not be responsible
                                            for any, direct or indirect, loss or damage arising out of the user's
                                            failure to comply with this requirement.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The User also agrees to: (a) provide true, accurate and complete information
                                            about himself and his beneficiaries as prompted by the registration form
                                            ("Registration Data") on the Website; and (b) maintain and promptly update
                                            the Registration Data to keep
                                            it true, accurate, current and complete. If the User provide any information
                                            that is untrue, inaccurate, not current or incomplete or Lixo Technology has
                                            reasonable grounds to suspect that such information is
                                            untrue, inaccurate, not current or incomplete, Lixo Technology has the right
                                            to suspend or terminate the User's registration and refuse any and all
                                            current or future use of the Website and/or any Service.

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Furthermore, the User grants Lixo Technology the right to disclose to third
                                            parties Registration Data to the extent necessary for the purpose of
                                            carrying out the Services. </div>
                                        <div class="go-book text-white lead  my-4">
                                            breach
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Without prejudice to the other remedies available to Lixo Technology under
                                            this agreement, the TOS or under applicable law, Lixo Technology may limit
                                            the user's activity, or end the user's listing, warn other users of the
                                            user's actions, immediately temporarily/indefinitely
                                            suspend or terminate the user's registration, and/or refuse to provide the
                                            user with access to the website if:
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            (a) the user is in breach of this agreement, the tos and/or the documents it
                                            incorporates by reference;
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            (b) Lixo Technology is unable to verify or authenticate any information
                                            provided by the user; or

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            (c) Lixo Technology believes that the user's actions may infringe on any
                                            third party rights or breach any applicable law or otherwise result in any
                                            liability for the user, other users of the website and/or Lixo Technology.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology may at any time in its sole discretion reinstate suspended
                                            users. Once the user has been indefinitely suspended the user may not
                                            register or attempt to register with Lixo Technology or use the website in
                                            any manner whatsoever until such
                                            time that the user is reinstated by Lixo Technology. notwithstanding the
                                            foregoing, if the user breaches this agreement, the tos or the documents it
                                            incorporates by reference, Lixo Technology reserves the right
                                            to recover any amounts due and owing by the user to Lixo Technology and/or
                                            the service provider and to take strict legal action as Lixo Technology
                                            deems necessary.

                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Proprietary rights
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            Lixo Technology may provide the User with content such as sound,
                                            photographs, graphics, video or other material contained in sponsor
                                            advertisements or information. This material may be protected by copyrights,
                                            trademarks or other intellectual property
                                            rights and laws. The User may use this material only as expressly authorized
                                            by Lixo Technology and shall not copy, transmit or create derivative works
                                            of such material without express authorization from Lixo
                                            Technology.
                                        </div>
                                        <div class="go-book text-white lead  my-4">
                                            The User acknowledges and agrees that they shall not upload, post, reproduce
                                            or distribute any content on or through the Website that is protected by
                                            copyright or other proprietary right of a third party, without obtaining the
                                            permission of the owner
                                            of such right. Any copyrighted or other proprietary content distributed on
                                            or through the Website with the consent of the owner must contain the
                                            appropriate copyright or other proprietary rights notice. The
                                            unauthorized submission or distribution of copyrighted or other proprietary
                                            content is illegal and could subject the User to personal liability or
                                            criminal prosecution.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Relationship</div>
                                        <div class="go-book text-white lead  my-4">None of the provisions of this
                                            Agreement, terms and conditions, notices or the right to use the Website by
                                            the User contained herein or any other section or pages of the Website
                                            and/or the Linked Sites, shall be
                                            deemed to constitute a partnership between the User and Lixo Technology and
                                            no party shall have any authority to bind or shall be deemed to be the agent
                                            of the other in any way. It may be noted, however, that
                                            if by using the Website, the User authorizes Lixo Technology and its agents
                                            to access third party sites designated by them or on their behalf for
                                            retrieving requested information, the User shall be deemed to
                                            have appointed Lixo Technology and its agents as their agent for this
                                            purpose.</div>
                                        <div class="go-book text-white lead  my-4">Headings</div>
                                        <div class="go-book text-white lead  my-4">The headings and subheadings herein
                                            are included for convenience and identification only and are not intended to
                                            describe, interpret, define or limit the scope, extent or intent of this
                                            Agreement, the TOS or the
                                            right to use the Website by the User contained herein or any other section
                                            or pages of the Website or any Linked Sites in any manner whatsoever.
                                        </div>
                                        <div class="go-book text-white lead  my-4">The terms and conditions herein shall
                                            apply equally to both the singular and plural form of the terms defined.
                                            Whenever the context may require, any pronoun shall include the
                                            corresponding masculine and feminine.
                                            The words "include", "includes" and "including" shall be deemed to be
                                            followed by the phrase "without limitation". Unless the context otherwise
                                            requires, the terms "herein", "hereof", "hereto", "hereunder" and
                                            words of similar import refer to this Agreement as a whole.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Indemnification</div>
                                        <div class="go-book text-white lead  my-4">The User agrees to indemnify, defend
                                            and hold harmless Lixo Technology from and against any and all losses,
                                            liabilities, claims, damages, costs and expenses (including legal fees and
                                            disbursements in connection
                                            therewith and interest chargeable thereon) asserted against or incurred by
                                            Lixo Technology that arise out of, result from, or may be payable by virtue
                                            of, any breach or non-performance of any representation,
                                            warranty, covenant or agreement made or obligation to be performed by the
                                            User pursuant to this Agreement and/or the TOS.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Severability</div>
                                        <div class="go-book text-white lead  my-4">If any provision of this Agreement is
                                            determined to be invalid or unenforceable in whole or in part, such
                                            invalidity or unenforceability shall attach only to such provision or part
                                            of such provision and the remaining
                                            part of such provision and all other provisions of this Agreement shall
                                            continue to be in full force and effect.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Termination of agreement and services
                                        </div>
                                        <div class="go-book text-white lead  my-4">Either the User or Lixo Technology
                                            may terminate this Agreement and a Service with or without cause at any time
                                            to be effective immediately.</div>
                                        <div class="go-book text-white lead  my-4">The User agrees that Lixo Technology
                                            may under certain circumstances and without prior notice, immediately
                                            terminate the User's user id and access to the Website/Services. Causes for
                                            termination may include, but
                                            shall not be limited to, breach by the User of this Agreement or the TOS,
                                            requests by enforcement or government agencies, requests by the User,
                                            non-payment of fees owed by the User in connection with the Services
                                            as specified in the applicable TOS.</div>
                                        <div class="go-book text-white lead  my-4">This Agreement may be terminated by
                                            either the User or Lixo Technology through a written notice to the other.
                                            Lixo Technology shall not be liable to the User or any third party for
                                            termination of any Service. Should
                                            the User object to any terms and conditions of this Agreement, any TOS or
                                            become dissatisfied with the Service in any way, the User's only recourse is
                                            to immediately: (a) discontinue use of the Website/Service;
                                            and (b) notify Lixo Technology of such discontinuance.

                                        </div>
                                        <div class="go-book text-white lead  my-4">Upon termination of the Service,
                                            User's right to use the Website/Services and software shall immediately
                                            cease. The User shall have no right and Lixo Technology shall have no
                                            obligation thereafter to execute any
                                            of the User's uncompleted tasks or forward any unread or unsent messages to
                                            the User or any third party. Once the User's registration or the Services
                                            are terminated, cancelled or suspended, any data that the
                                            User has stored on the Website may not be retrieved later.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Notices</div>
                                        <div class="go-book text-white lead  my-4">If to Lixo Technology, at
                                            notice@moviepanda.in or at the address posted on the Website.

                                        </div>
                                        <div class="go-book text-white lead  my-4">If to Lixo Technology, at <a
                                                class="go-book text-primary lead  text-decoration-none"
                                                [href]="mailto('notice@moviepanda.in', '')">notice@moviepanda.in</a> or
                                            at the address posted on the Website.</div>
                                        <div class="go-book text-white lead  my-4">If to a non registered User, at the
                                            communication and/or email address specified in the application form
                                            availing of a Lixo Technology Service.</div>
                                        <div class="go-book text-white lead  my-4">If to a registered User, at the
                                            communication and/or email address specified in the registration form.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Notice shall be deemed to have been
                                            served 48 hours after it has been sent, dispatched, displayed, as the case
                                            may be, unless, where notice has been sent by email, it comes to the
                                            knowledge of the sending party,
                                            that the email address is invalid.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Governing law</div>
                                        <div class="go-book text-white lead  my-4">This agreement and each TOS shall be
                                            governed by and constructed in accordance with the laws of India without
                                            reference to conflict of laws principles and disputes arising in relation
                                            hereto shall be subject to
                                            the exclusive jurisdiction of the courts of<a
                                                class="go-book text-primary lead  text-decoration-none mx-2">Chennai.</a>
                                        </div>
                                        <div class="go-book text-white lead  my-4">Purchase Policy</div>
                                        <div class="go-book text-white lead  my-4">Payment Methods</div>
                                        <div class="go-book text-white lead  my-4">Movie Panda accepts Visa, MasterCard,
                                            American Express, select Debit Cards and Net Banking. Other payment methods
                                            may be added from time to time. Who You Are Buying From Movie Panda acts as
                                            the agent to those who
                                            are promoting the events for which you purchase tickets ("Event Organizer")
                                            and for movie halls (Cinemas). When you purchase a ticket for an
                                            event/movie, Movie Panda will be handling the transaction and collecting
                                            payment for the organizer/Cinema Pricing and Availability Movie Panda sells
                                            tickets on behalf of Event organizers/Cinemas and does not control the
                                            inventory or its availability and pricing.

                                        </div>
                                        <div class="go-book text-white lead  my-4">Age Limit</div>
                                        <div class="go-book text-white lead  my-4">Children aged 3 years and above will
                                            require a separate ticket.</div>
                                        <div class="go-book text-white lead  my-4">State Punjab 5 Years & above Himachal
                                            Pradesh 3 Years & above Haryana 3 Years & above UP 5 Years & above Rajasthan
                                            3 Years & above Uttarakhand 5 Years & above Jarakhand 3 Years & above Bihar
                                            3 Years & above
                                        </div>
                                        <div class="go-book text-white lead  my-4">Internet handling fees and Order
                                            Processing Fees Tickets purchased on Movie Panda are subject to a per ticket
                                            internet handling fee and a non-refundable per order processing fee.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Amount of Tickets Per Customer or
                                            "Ticket Limits" When purchasing tickets on Movie Panda you are limited to a
                                            specified number of tickets for each event/movie.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Censor warning</div>
                                        <div class="go-book text-white lead  my-4">Please check the censor warning
                                            before buying the tickets. Children below the age of 18 will not be allowed
                                            entry for an {{ '{A}' }}rated movie. Also please check for any additional
                                            ratings/entry restrictions that
                                            may be applicable for events/movies from time to time.</div>
                                        <div class="go-book text-white lead  my-4">
                                            Order Confirmation
                                        </div>
                                        <div class="go-book text-white lead  my-4">If you do not receive a confirmation
                                            number (in the form of a confirmation page or email) after submitting
                                            payment information, or if you experience an error message or service
                                            interruption after submitting payment
                                            information, it is your responsibility to confirm the same form your booking
                                            history or with the Customer Service Department whether or not your order
                                            has been placed. Only you may be aware of any problems that
                                            may occur during the purchase process. Movie Panda will not be responsible
                                            for losses (monetary or otherwise) if you assume that an order was not
                                            placed because you failed to receive confirmation.</div>
                                        <div class="go-book text-white lead  my-4">Refunds and Exchanges</div>
                                        <div class="go-book text-white lead  my-4">Before purchasing tickets, carefully
                                            review your booking details. Movie Panda will be unable to process exchanges
                                            or refunds after a ticket has been purchased or for lost, stolen, damaged or
                                            destroyed tickets.</div>
                                        <div class="go-book text-white lead  my-4">Billing Information Verification
                                        </div>
                                        <div class="go-book text-white lead  my-4">Some Orders will be processed only
                                            after a billing address, and other billing information, has been verified.
                                            Occasionally, we receive incorrect billing or credit card account
                                            information for a ticket order that
                                            can delay processing and delivery. In these cases, Movie Panda customer
                                            service will attempt to contact you, using the information provided at the
                                            time of purchase. If Movie Panda is unable to reach you after
                                            its initial attempt, Movie Panda may cancel your order and may sell your
                                            tickets to another customer.</div>
                                        <div class="go-book text-white lead  my-4">Delivery</div>
                                        <div class="go-book text-white lead  my-4">Movie Panda offers multiple delivery
                                            options. The options may vary from for different events/movies. Currently
                                            the following delivery options are available:</div>
                                        <div class="go-book text-white lead  my-4">Email confirmation:Your booking
                                            confirmation will be sent via an email. This confirmation needs to be
                                            printed and shown at the ticket counter to get a physical ticket.</div>
                                        <div class="go-book text-white lead  my-4">SMS confirmation:Your booking
                                            confirmation will be sent via an SMS. The SMS details need to be shown at
                                            the ticket counter to get a physical ticket.</div>
                                        <div class="go-book text-white lead  my-4">Important</div>
                                        <div class="go-book text-white lead  my-4">In all the cases you will need to
                                            produce the credit/debit card used to purchase the tickets for picking up
                                            the tickets at the venue. In case the credit/debit card user is not picking
                                            up the tickets, an authorization
                                            with the photocopy of this credit/debit card signed by the credit/debit card
                                            holder needs to be produced at the time of picking up the tickets. Please
                                            note that the signature on the card must match the signature
                                            used for authorization. Physical Tickets will be delivered to your address
                                            confirmed on the website.</div>
                                        <div class="go-book text-white lead  my-4">Pricing and Other Errors</div>
                                        <div class="go-book text-white lead  my-4">If the amount you pay for a ticket is
                                            incorrect regardless of whether because of an error in a price posted on
                                            this web site or otherwise communicated to you, or you are able to order a
                                            ticket before its scheduled
                                            on-sale or presale date or you are able to order a ticket that was not
                                            supposed to have been released for sale, then: Movie Panda will have the
                                            right to cancel that ticket (or the order for that ticket) and
                                            refund to you the amount that you paid. This will apply regardless of
                                            whether because of human error or a transactional malfunction of this web
                                            site.</div>
                                        <div class="go-book text-white lead  my-4">Cancelled/Postponed Events</div>
                                        <div class="go-book text-white lead  my-4">Occasionally, events/movies are
                                            canceled or postponed by the promoter/cinema. Should this occur, we will
                                            attempt to contact you and refund the amount as per the policy of the
                                            organizer/cinema.</div>
                                        <div class="go-book text-white lead  my-4">Customer Care</div>
                                        <div class="go-book text-white lead  my-4">If you need help regarding your order
                                            or any other issue, the best in the company is available. We guarantee a
                                            response time of one working day and you can email us to reach our customer
                                            service department.</div>
                                        <div class="go-book text-white lead  my-4">Gift Vouchers</div>
                                        <div class="go-book text-white lead  my-4">Movie Panda vouchers are issued by
                                            Movie Panda and may only be redeemed through our website, www.moviepanda.in
                                            towards the purchase of movie tickets on moviepanda.in for participating
                                            theaters located in India.
                                            Gift Vouchers cannot be redeemed directly at any of our partner theaters, or
                                            any other website operated by third-party merchants accessible from our
                                            site.</div>
                                        <div class="go-book text-white lead  my-4">If the available amount on a Movie
                                            Panda vouchers claim code is LESS than the transaction amount, the balance
                                            of the order must be paid with a valid credit card.
                                        </div>
                                        <div class="go-book text-white lead  my-4">In addition to these Movie Panda
                                            vouchers Terms and Conditions, Movie Panda vouchers and their use on our
                                            website are also subject to Movie Panda’s general Terms of Use. You agree to
                                            comply with the Terms of Use.</div>
                                        <div class="go-book text-white lead  my-4">Movie Panda is not responsible for
                                            lost or stolen Movie Panda vouchers, except as required by law. In certain
                                            circumstances, Movie Panda may replace or cancel lost or stolen Movie Panda
                                            vouchers with proof of purchase
                                            acceptable to Movie Panda.</div>
                                        <div class="go-book text-white lead  my-4">Movie Panda may change (add to,
                                            delete, or amend) these terms from time to time. Unless we state otherwise,
                                            the changes will apply to any new Movie Panda vouchers that you purchase.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Movie Panda vouchers are for
                                            personal, non-commercial use and enjoyment only. They may be shared with
                                            family and friends, but may not be advertised, sold or used as promotional
                                            items by the purchaser or anyone else
                                            without Movie Panda´s prior written consent. Your purchase or use of Movie
                                            Panda vouchers will be deemed your agreement to these terms.</div>
                                        <div class="go-book text-white lead  my-4">Buyer beware</div>
                                        <div class="go-book text-white lead  my-4">Read Through our Terms and Conditions
                                            Carefully. Accepting the Terms and conditions while booking a ticket online
                                            makes it a valid contract between you and us.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Booking ID's generated only through
                                            moviepanda.in shall be considered by us as a valid Booking ID.</div>
                                        <div class="go-book text-white lead  my-4">Ensure the personal details entered
                                            including the card details are accurate. Booking Id's once generated cannot
                                            be cancelled/refunded/exchanged.</div>
                                        <div class="go-book text-white lead  my-4">Ensure the Booking Id's are kept safe
                                            and always in your or such person's possession who is considered as your
                                            authorized/rightful holder of the same. Anybody in possession of the Booking
                                            Id shall be entitled to
                                            get the same exchanged for a valid Ticket without further security checks on
                                            the title of such holder.</div>
                                        <div class="go-book text-white lead  my-4">Ensure that the Booking Id's are not
                                            compromised or resold.</div>
                                        <div class="go-book text-white lead  my-4">Do not entertain any person who sells
                                            printouts or sms of Booking ID’s generated by us. If you come across any
                                            person undertaking such activity, immediately report the same to the local
                                            police station and to us
                                            at support.moviepanda.in</div>
                                        <div class="go-book text-white lead  my-4">We never sell a Ticket at a price
                                            higher than the price mentioned in our website.</div>
                                        <div class="go-book text-white lead  my-4">We will never ask for your Personal
                                            Identification Number (PIN) details over the phone or through any means
                                            except through the transaction page on the website when you book online.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Do not encourage any persons posing
                                            as our authorised representative/agent trying to sell you special offer
                                            vouchers/gift vouchers/physically printed booking id's/winpins/voucher
                                            e-codes etc. Report the same immediately
                                            to us at support.moviepanda.in</div>
                                        <div class="go-book text-white lead  my-4">Booking ID's generated by us entitles
                                            the holder to exchange it for a valid Ticket. The same does not constitute a
                                            valid Ticket in itself. We will never sell you Booking Id's as Tickets.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Booking Id's generated entitled the
                                            holder thereof to a valid ticket. It is your responsibility to ensure that
                                            the holder is the rightful holder of the same. Do not compromise or misuse
                                            the Booking ID's.</div>
                                        <div class="go-book text-white lead  my-4">Prohibited electronic equipments
                                        </div>
                                        <div class="go-book text-white lead  my-4">Customers are advised to avoid
                                            carrying Laptops, Handycams, iPads, Tablets and Digital Cameras to the
                                            cinema hall. Customers can, however, keep their mobile phones with them.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Terms and Conditions list of cards
                                            accepted</div>
                                        <div class="go-book text-white lead  my-4">Debit cards (Visa & Mastercard)</div>
                                        <div class="go-book text-white lead  my-4">Andhra Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">
                                            Axis Bank Mastercard Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">Axis Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Barclays Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Bank of India Mastercard Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">Canara Bank Mastercard Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">Canara Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Central Bank of India Mastercard
                                            Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Citibank Maestro Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Citibank Mastercard Debit Card</div>
                                        <div class="go-book text-white lead  my-4">City Union Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Corporation Bank Mastercard Debit
                                            Card</div>
                                        <div class="go-book text-white lead  my-4">Corporation Bank Visa Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">Deutsche Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Dhanlaxmi Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">GE Money Financial Services Debit
                                            Card</div>
                                        <div class="go-book text-white lead  my-4">HDFC Bank Mastercard Debit Card</div>
                                        <div class="go-book text-white lead  my-4">HDFC Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">ICICI Bank Mastercard Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">ICICI Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">IDBI Bank Mastercard Debit Card</div>
                                        <div class="go-book text-white lead  my-4">IDBI Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Indian Bank Mastercard Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">Indian Overseas Bank Visa Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">Indusind Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Karnataka Bank Visa Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Kotak Virtual Visa Cards Visa Debit
                                            Card</div>
                                        <div class="go-book text-white lead  my-4">Punjab National Bank Mastercard Debit
                                            Card</div>
                                        <div class="go-book text-white lead  my-4">Royal Bank of Scotland Mastercard
                                            Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Standard Chartered Bank Mastercard
                                            Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Standard Chartered Bank Visa Debit
                                            Card</div>
                                        <div class="go-book text-white lead  my-4">State Bank of India Mastercard Debit
                                            Card</div>
                                        <div class="go-book text-white lead  my-4">State Bank of India Visa Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">The Federal Bank Mastercard Debit
                                            Card</div>
                                        <div class="go-book text-white lead  my-4">The Federal Bank Visa Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">The Karur Vysya Bank Visa Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">The Syndicate Bank Visa Debit Card
                                        </div>
                                        <div class="go-book text-white lead  my-4">The Union Bank of India Visa Debit
                                            Card</div>
                                        <div class="go-book text-white lead  my-4">The Union Bank of India Mastercard
                                            Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Yes Bank Mastercard Debit Card</div>
                                        <div class="go-book text-white lead  my-4">Other Visa debit Cards</div>
                                        <div class="go-book text-white lead  my-4">Credit cards (Visa, mastercard &
                                            Amex)</div>
                                        <div class="go-book text-white lead  my-4">Visa</div>
                                        <div class="go-book text-white lead  my-4">Master Card</div>
                                        <div class="go-book text-white lead  my-4">American Express</div>
                                        <div class="go-book text-white lead  my-4">Notice & Take Down Policy</div>
                                        <div class="go-book text-white lead  my-4"><a>AS AN INTERMEDIARY, LIXO
                                                TECHNOLOGY IS NOT LIABLE FOR ANY THIRD-PARTY CONTENT UPLOADED,
                                                TRANSMITTED, DISPLAYED, PUBLISHED OR SHARED ON ITS PLATFORM. BY USING
                                                MOVIEPANDA.COM,</a>, YOU AGREE TO THE CONDITIONS SET
                                            OUT IN THIS NOTICE AND TAKE DOWN POLICY</div>
                                        <div class="go-book text-white lead  my-4">1.COPYRIGHT INFRINGEMENT NOTIFICATION
                                        </div>
                                        <div class="go-book text-white lead  my-4">If the owner of a copyright-protected
                                            work or an agent authorized to act on the copyright owner's behalf (the
                                            "Owner") believes the work posted on moviepanda.in ("the Site") is
                                            infringing the Owner's copyright under
                                            the relevant law, the Owner may submit a copyright infringement
                                            notification.</div>
                                        <div class="go-book text-white lead  my-4">2.COPYRIGHT INFRINGEMENT NOTIFICATION
                                            PROCEDURE</div>
                                        <div class="go-book text-white lead  my-4">Lixo Technology recommends Owners of
                                            copyright-protected content to report alleged copyright infringement by
                                            submitting a written complaint (the "Complaint") either through a web-form
                                            (recommended) or through email
                                            on <a class="go-book text-primary lead  text-decoration-none"
                                                [href]="mailto('legal@moviepanda.in ', '')">legal@moviepanda.in </a>The
                                            Complaint should include the following:-</div>
                                        <div class="go-book text-white lead  my-4">(a)Contact Information: The Owner
                                            will need to provide information that will allow Lixo Technology and the
                                            uploader(s) of the video(s) that the Owner's objects to, to contact the
                                            Owner regarding the Complaint, such
                                            as an email address, physical address or telephone number</div>
                                        <div class="go-book text-white lead  my-4">(b)Description of Work: The Owner
                                            must provide in the complaint, a complete and accurate description of the
                                            copyrighted content that the Owner is seeking to protect. This MUST also
                                            include the specific URL of the
                                            video that the Owner believes to be infringing. This is necessary in order
                                            to aid Lixo Technology to locate the allegedly infringing video. General
                                            information about the video, such as a channel URL or username
                                            alone is not adequate. The URL should be in the following format.<a
                                                class="go-book text-primary lead  text-decoration-none"
                                                href="http://www.moviepanda.in">
                                                http://www.moviepanda.in</a>/_____________/</div>
                                        <div class="go-book text-white lead  my-4">(c)Necessary Format: The Complaint
                                            should include the following statements – "I have a good faith belief that
                                            the use of the material in the manner complained of is not authorized by the
                                            copyright owner, its agent,
                                            or the law." AND "The information in this notification is accurate, and I am
                                            the owner, or an agent authorized to act on behalf of the owner, of an
                                            exclusive right that is allegedly infringed."</div>
                                        <div class="go-book text-white lead  my-4">(d)Signature: The Complaint must
                                            include the physical or electronic signature/digital signature of the Owner.
                                            To satisfy this requirement, the Owner may type their full legal name (a
                                            first and last name, not a company
                                            name) as the signature at the bottom of the complaint</div>
                                        <div class="go-book text-white lead  my-4">3.TAKE-DOWN OF COPYRIGHT INFRINGING
                                            WORK</div>
                                        <div class="go-book text-white lead  my-4">In case of a Complaint by the Owner
                                            that is submitted in accordance with the procedure in Clause 2, and found to
                                            be valid, Lixo Technology may refrain from facilitating access to the
                                            allegedly infringing work for
                                            a period of 21 (twenty one) days or until an order from a competent court is
                                            received, whichever is earlier. In case Lixo Technology receives no such
                                            order before the expiry of twenty-one days, Lixo Technology
                                            may reinstate access to the said work.</div>
                                        <div class="go-book text-white lead  my-4">4.NOTIFICATION OF OBJECTIONABLE
                                            CONTENT AND TAKE DOWN BY LIXO TECHNOLOGY</div>
                                        <div class="go-book text-white lead  my-4">Users may submit a complaint against
                                            objectionable content by submitting a written complaint (the "Complaint")
                                            either through a web-form (recommended) or through email on <a
                                                class="go-book text-primary lead  text-decoration-none"
                                                [href]="mailto('legal@moviepanda.in', '')">legal@moviepanda.in </a>The
                                            Complaint should include the following:
                                        </div>
                                        <div class="go-book text-white lead  my-4">(a)Contact Information: The
                                            Complainant will need to provide information that will allow Lixo Technology
                                            to contact the Complainant regarding the Complaint, such as an email
                                            address, physical address or telephone
                                            number.
                                        </div>
                                        <div class="go-book text-white lead  my-4">(b)Description of Work: The
                                            Complainant must provide in the complaint, a description of the
                                            objectionable content that the Complainant is seeking to protest. This MUST
                                            also include the specific URL of the video
                                            that the Complainant believes to be objectionable. This is necessary in
                                            order to aid Lixo Technology to locate the allegedly objectionable video.
                                            General information about the video, such as a channel URL or
                                            username alone shall not be adequate. The URL should be in the following
                                            format:<a class="go-book text-primary lead  text-decoration-none"
                                                href="http://www.moviepanda.in">
                                                http://www.moviepanda.in</a>/_____________/</div>
                                        <div class="go-book text-white lead  my-4">(c)Necessary Format: The Complaint
                                            should include the following statements – "I have a good faith belief that
                                            the use of the material in the manner complained of violates any law, or the
                                            conditions in Clause 4 of
                                            Lixo Technology’s Notice and Take Down Policy" AND "The information in this
                                            notification is accurate to my knowledge"</div>
                                        <div class="go-book text-white lead  my-4">If Lixo Technology determines, in its
                                            sole discretion and judgment, that the User Content violates any of the
                                            aforementioned conditions, it shall be entitled to immediately take down or
                                            otherwise block access to
                                            User Content</div>
                                        <div class="go-book text-white lead  my-4">5.LIXO TECHNOLOGY'S IMMUNITY FROM
                                            LIABILITY</div>
                                        <div class="go-book text-white lead  my-4">Lixo Technology is an intermediary
                                            and shall not be liable for any act of copyright infringement or any other
                                            objectionable User Content, as provided under the Copyright Act, 1957,
                                            Information Technology Act, 2000
                                            and Rules thereunder.</div>
                                        <div class="go-book text-white lead  my-4">Acceptance Of These Terms Of Use
                                        </div>
                                        <div class="go-book text-white lead  my-4">By accessing or using the Platform
                                            and/or by downloading any Content from or to the Platform, you represent
                                            that you have read this Agreement and agree to be bound by the same and all
                                            terms incorporated by reference.
                                            You further represent and warrant that you are either 18 years of age or
                                            more, or the applicable age of majority in your jurisdiction, or if you are
                                            under 18 years of age or the age of majority in your jurisdiction,
                                            you are 13 years of age or more and have your parent(s)' or legal
                                            guardian(s)' permission to use the Platform. If you do not agree with all of
                                            the use requirements and restrictions described herein, do not use
                                            and/or access the Platform.</div>
                                        <div class="go-book text-white lead  my-4">This Agreement does not alter in any
                                            way the terms or conditions of any other agreement you may have with Lixo
                                            Technology in so far as the same are not inconsistent with the terms and
                                            conditions of this Agreement.
                                            In the event of a conflict, the terms and conditions of this Agreement shall
                                            supersede any other agreement you may have with Lixo Technology.</div>
                                        <div class="go-book text-white lead  my-4">Changes To Terms Of Use</div>
                                        <div class="go-book text-white lead  my-4">The Company reserves the right to
                                            change or modify any of the terms and conditions contained in this Agreement
                                            including any other policy incorporated herein by reference, at any time and
                                            in its sole discretion.
                                            Any changes or modification will be effective immediately and you waive any
                                            right you may have to receive specific notice of such changes or
                                            modifications. You are free to decide whether or not to accept the
                                            changed/modified version of this Agreement, but accepting such a
                                            changed/modified Agreement is essential and required for you to continue
                                            using the Platform. You may have to click "accept" or "agree" to show
                                            your acceptance of any changes/modifications made to this Agreement. In the
                                            event that you are not required to expressly click any such "accept" or
                                            "agree" option, your continued use of this Platform following
                                            the posting of changes or modifications will confirm your acceptance of such
                                            changes or modifications. Therefore, you agree that you will periodically
                                            review this Agreement from time-to- time to understand the
                                            terms and conditions that apply to your use of the Platform. If you do not
                                            agree to the terms of this Agreement or to any changed/amended/modified
                                            version of this Agreement, your sole recourse is to terminate
                                            your use of the Platform, in which case you will no longer have access to
                                            the Platform, including any account you might have opened to access the
                                            Platform. Except as otherwise expressly stated, any use of the
                                            Platform, is subject to the version of this Agreement in effect at the time
                                            of use. This Agreement does not alter in any way the terms or conditions of
                                            any other agreement you may have with Lixo Technology in
                                            so far as the same are not inconsistent with the terms and conditions of
                                            this Agreement. In the event of a conflict, the terms and conditions of this
                                            Agreement shall supersede any other agreement you may have
                                            with Lixo Technology.</div>
                                        <div class="go-book text-white lead  my-4">Governing Law</div>
                                        <div class="go-book text-white lead  my-4">
                                            This Agreement shall be governed by and constructed in accordance with the
                                            laws of India without reference to conflict of laws principles and disputes
                                            arising in relation hereto shall be subject to the exclusive jurisdiction of
                                            the courts of Chennai.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Miscellaneous</div>
                                        <div class="go-book text-white lead  my-4">Relationship: None of the provisions
                                            of this Agreement, terms and conditions, notices or the right to use the
                                            Platform by the User contained herein or any other section or pages of the
                                            Platform, shall be deemed
                                            to constitute a partnership between the User and Lixo Technology and no
                                            party shall have any authority to bind or shall be deemed to be the agent of
                                            the other in any way. It may be noted, however, that if by
                                            using the Platform, the User authorizes Lixo Technology and its agents to
                                            access third party sites designated by them or on their behalf for
                                            retrieving requested information, the User shall be deemed to have
                                            appointed Lixo Technology and its agents as their agent for this purpose.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Headings: The headings and
                                            subheadings herein are included for convenience and identification only and
                                            are not intended to describe, interpret, define or limit the scope, extent
                                            or intent of this Agreement, or the
                                            right to use the Platform by the User contained herein.</div>
                                        <div class="go-book text-white lead  my-4">Severability: If any provision of
                                            this Agreement is determined to be invalid or unenforceable in whole or in
                                            part, such invalidity or unenforceability shall attach only to such
                                            provision or part of such provision
                                            and the remaining part of such provision and all other provisions of this
                                            Agreement shall continue to be in full force and effect.</div>
                                        <div class="go-book text-white lead  my-4">Amendments: We reserve the right to
                                            change or amend the terms of this Agreement at any time. It is your
                                            responsibility to check this Agreement on a regular basis, including,
                                            without limitation, prior to using the
                                            Platform. Your continued use of the Platform constitutes your acceptance of
                                            the revised terms.</div>
                                        <div class="go-book text-white lead  my-4">Notices: All notices and
                                            communications (including those related to changes in the Agreement, etc.,)
                                            shall be in writing, in English and shall deemed given if delivered
                                            personally or by commercial messenger or courier
                                            service, or mailed by registered or certified mail (return receipt
                                            requested) or sent via email/facsimile (with acknowledgment of complete
                                            transmission) to the following address: If to Lixo Technology, at
                                            <a class="go-book text-primary lead  text-decoration-none"
                                                [href]="mailto('notice@moviepanda.in', '')"> notice@moviepanda.in</a> or
                                            at the address posted on the Platform. Notice shall be deemed to have been
                                            served 48 hours after it has been sent, dispatched, displayed, as the case
                                            may be, unless, where notice has been sent by email, it comes to the
                                            knowledge of the sending party, that the email address is invalid.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Assignment and Delegation: You must
                                            not transfer your User Account or assign any of your rights or delegate any
                                            of your duties under this Agreement without our prior written approval. We
                                            may freely transfer, assign
                                            or delegate this Agreement or its rights and duties under this Agreement.
                                            Subject to the foregoing, this Agreement will be binding upon and will
                                            insure to the benefit of the parties and their respective representatives,
                                            heirs, administrators, successors and permitted assigns.</div>
                                        <div class="go-book text-white lead  my-4">Confidentiality: You must keep any
                                            information you obtain relating to our Platform confidential and will not
                                            use such information for any purpose that is not specifically provided for
                                            in this Agreement or authorized
                                            by us in writing.</div>
                                        <div class="go-book text-white lead  my-4">No Waiver: Our failure or delay to
                                            exercise or enforce any right or claim does not constitute a waiver of such
                                            right or claim and shall in no way affect our right to later enforce or
                                            exercise it. Prevailing Language
                                            : The English language version of this Agreement shall be controlling in all
                                            respects and shall prevail in case of any inconsistencies with translated
                                            versions, if any.
                                        </div>
                                        <div class="go-book text-white lead  my-4">All customer data shall be collected,
                                            stored, protected and used in the manner indicated in Movie Panda’s Privacy
                                            Policy (as updated from time to time) available at
                                            https://moviepanda.in/privacy.</div>
                                        <div class="go-book text-white lead  my-4">The customer solicits and authorizes
                                            Movie Panda to contact him by voice call, sms and/ or email. Customer hereby
                                            unconditionally consents that such contact by Movie Panda is (a) upon the
                                            request and authorization
                                            of the Customer, (b) 'transactional' and not an 'unsolicited commercial
                                            communication' as per the guidelines of Telecom Regulation Authority of
                                            India (TRAI) and (c) in compliance with the relevant guidelines
                                            of TRAI or such other authority in India and abroad. The Customer will
                                            indemnify Movie Panda against all types of losses and damages incurred by
                                            Movie Panda due to any action taken by TRAI, Access Providers
                                            (as per TRAI regulations) or any other authority due to any erroneous
                                            compliant raised by the Customer on Movie Panda with respect to the
                                            intimations mentioned above or due to a wrong number or email id being
                                            provided by the customer for any reason whatsoever.</div>
                                        <div class="go-book text-white lead  my-4">Watch Guide</div>
                                        <div class="go-book text-white lead  my-4">By using the Movie Panda services,
                                            you agree to follow these Terms. These Terms also include the following
                                            guidelines and terms:</div>
                                        <div class="go-book text-white lead  my-4">Movie Panda is not responsible for
                                            the content shown in the platform as it is aggregated from various OTT
                                            players sites. We do not host any copyrighted video, movie or content on our
                                            platform. We are not responsible
                                            for any loss data or revenue.</div>
                                        <div class="go-book text-white lead  my-4">Rules of Usage</div>
                                        <div class="go-book text-white lead  my-4">The content is not intended for users
                                            under the age of 18, and we do not knowingly collect personally identifiable
                                            information from users under the age of 18. Such users are expressly
                                            prohibited from using the service.
                                            For this reason, parents who wish to allow their children access to Movie
                                            Panda should assist them in setting up accounts, supervise their access to
                                            Movie Panda & its services and determine as legal guardians
                                            whether any of the Movie Panda services and its content are appropriate for
                                            them. By allowing their child access to the Movie Panda services, parents
                                            acknowledge and accept that they will be able to access all
                                            of the Movie Panda content.</div>
                                        <div class="go-book text-white lead  my-4">By using this service, you agree to
                                            notify us immediately if you learn of or suspect a security breach or any
                                            illegal activity in connection with the Service.</div>
                                        <div class="go-book text-white lead  my-4">When you use any of our services, you
                                            are shown contents from other service provider (OTT/SVOD/AVOD) channels,
                                            further we are not responsible for anything after we redirect you to their
                                            site.</div>
                                        <div class="go-book text-white lead  my-4">We reserve the right to refuse
                                            service, terminate accounts, remove or edit content in our sole discretion.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Third Party Sites</div>
                                        <div class="go-book text-white lead  my-4">This Site may contain links to other
                                            websites ("Third Party Sites"). We do not own or operate the Third Party
                                            Sites, and we have not reviewed, and cannot review, all of the material,
                                            including goods or services,
                                            made available through Third-Party Sites. The availability of these links on
                                            this Site does not represent, warrant or imply that we endorse any Third
                                            Party Sites or any materials, opinions, goods or services
                                            available on them. Third party materials accessed through or used by means
                                            of the Third Party Sites may also be protected by copyright and other
                                            intellectual property laws. THIS AGREEMENT DOES NOT APPLY TO THIRD
                                            PARTY SITES. BEFORE VISITING A THIRD PARTY SITE BY MEANS OF THIS SITE OR A
                                            LINK LOCATED ON THIS SITE, USERS SHOULD REVIEW THE THIRD PARTY SITE'S TERMS
                                            AND CONDITIONS, PRIVACY POLICY AND ALL OTHER SITE DOCUMENTS,
                                            AND INFORM THEMSELVES OF THE REGULATIONS, POLICIES AND PRACTICES OF THESE
                                            THIRD PARTY SITES.</div>
                                        <div class="go-book text-white lead  my-4">Disclaimers</div>
                                        <div class="go-book text-white lead  my-4">ALL CONTENT OR CONTRIBUTIONS OR ANY
                                            OTHER MATERIALS OR ITEMS PROVIDED THROUGH OUR PLATFORM BY US ARE PROVIDED
                                            "AS IS" AND "AS AVAILABLE," WITHOUT WARRANTY OR CONDITIONS OF ANY KIND. BY
                                            OPERATING OUR PLATFORM, WE
                                            DO NOT REPRESENT OR IMPLY THAT WE ENDORSE ANY CONTRIBUTIONS OR ANY OTHER
                                            MATERIALS OR ITEMS AVAILABLE ON OR LINKED TO BY OUR PLATFORM, INCLUDING
                                            WITHOUT LIMITATION, CONTENT HOSTED ON THIRD PARTY SITES, OR THAT
                                            WE BELIEVE CONTENT OR ANY OTHER MATERIALS OR ITEMS TO BE ACCURATE, USEFUL OR
                                            NON-HARMFUL. WE CANNOT GUARANTEE AND DO NOT PROMISE ANY SPECIFIC RESULTS
                                            FROM USE OF OUR PLATFORM. NO ADVICE OR INFORMATION, WHETHER
                                            ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR OUR PLATFORM SHALL CREATE ANY
                                            WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. YOU AGREE THAT YOUR USE OF
                                            OUR PLATFORM AND SERVICES WILL BE AT YOUR SOLE RISK. WE DO
                                            NOT WARRANT THAT OUR PLATFORM OR SERVICES WILL BE AVAILABLE FOR USE, AND WE
                                            DO NOT MAKE ANY WARRANTIES AS TO THE QUALITY OF THE SITE, SERVICES OR ITS
                                            CONTENT. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
                                            ALL WARRANTIES, EXPRESS OR IMPLIED IN CONNECTION WITH OUR PLATFORM AND YOUR
                                            USE THEREOF.
                                        </div>
                                        <div class="go-book text-white lead  my-4">WE MAKE NO WARRANTIES OR
                                            REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, TIMELINESS OR COMPLETENESS
                                            OF OUR PLATFORM'S CONTENT, THE CONTENT OF ANY SITE LINKED TO OUR PLATFORM,
                                            CONTRIBUTIONS, INFORMATION OR ANY OTHER
                                            ITEMS OR MATERIALS ON THIS PLATFORM OR LINKED TO BY OUR PLATFORM. WE ASSUME
                                            NO LIABILITY OR RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES OR INACCURACIES
                                            OF CONTENT, CONTRIBUTIONS AND MATERIALS, (B) PERSONAL INJURY
                                            OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
                                            AND USE OF OUR PLATFORM OR SERVICES, (C) ANY ILLEGAL OR UNAUTHORIZED ACCESS
                                            TO OR USE OF OUR SECURE SERVERS AND ALL PERSONAL INFORMATION
                                            STORED THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
                                            OUR PLATFORM, (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY
                                            BE TRANSMITTED TO OR THROUGH OUR PLATFORM BY ANY THIRD
                                            PARTY, AND/OR (F) ANY ERRORS OR OMISSIONS IN ANY CONTRIBUTIONS, CONTENT AND
                                            MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
                                            USE OF ANY CONTENT, CONTRIBUTIONS, OR MATERIALS POSTED,
                                            TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA OUR PLATFORM.
                                        </div>
                                        <div class="go-book text-white lead  my-4">WE WILL NOT BE LIABLE TO YOU FOR ANY
                                            LOSS OF ANY DATA (INCLUDING CONTENT) OR FOR LOSS OF USE OF THIS SITE.</div>
                                        <div class="go-book text-white lead  my-4">Data & Product Uses:</div>
                                        <div class="go-book text-white lead  my-4">We don't allow any kind of illegal
                                            uses of software or scrapers to scrap our data without our express written
                                            permission.</div>
                                        <div class="go-book text-white lead  my-4">We might share basic information with
                                            our business partners, like when you are redirected to any OTT platform. We
                                            might also use your usage, and location details for ads targeting purposes.
                                        </div>
                                        <div class="go-book text-white lead  my-4">Image & Posters fair usage policy.
                                        </div>
                                        <div class="go-book text-white lead  my-4">All the used Images/Posters,
                                            Backgrounds and other data are copyright and trademarks of their respective
                                            Publishers/Owners.</div>
                                        <div class="go-book text-white lead  my-4">All the above images are either
                                            retrieved from http://tmdb.org/ or directly from the respective OTT
                                            providers. We cache these images on our cdn servers to load them quickly.
                                        </div>
                                        <div class="go-book text-white lead  my-4">If the owner of a copyright-protected
                                            work or an agent authorized to act on the copyright owner's behalf (the
                                            "Owner") believes the work posted on the Site is infringing the Owner's
                                            copyright under the relevant
                                            law, the Owner may submit a copyright infringement notification in the
                                            manner set forth in the Notice and Take Down Policy.</div>
                                        <div class="go-book text-white lead  my-4">For more information on Movie
                                            Posters, Images, Titles and any other data, please check
                                            https://www.themoviedb.org/terms-of-use</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<mp-footer></mp-footer>