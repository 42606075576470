<mp-header></mp-header>
<div class="container-fluid homerp">
    <div class="row bg-020A1C shadow-custom hm-pos-bg">
        <div class="offset-lg-1 col-xl-10 my-lg-5">
            <div class="container-fluid">
                <div class="row">
                    <ng-container *ngIf="!isPopularMoviesError else noMoviesTemplate">
                        <ng-container *ngIf="loading else loaded">
                            <div class="col-xl-3 p-01" *ngFor="let number of [0,1,2,3,4,5,6,7]">

                                <div class="hm-pos-box rounded">
                                    <div class="p-4 position-relative">
                                        <div class="circle">
                                            <div class="show-loader-div pulse"></div>
                                        </div>
                                    </div>
                                    <div class="container-fluid bg-secondary ">
                                        <div class="row align-items-center">
                                            <div class="col-xl-8 hm-pos-box-lft">
                                                <h5 class="pt-3 text-white go-medium text-truncate"
                                                    class="show-loader-h5 placeholder-bar">
                                                </h5>
                                                <h6 class="font-weight-bolder text-white go-light text-uppercase"
                                                    class="show-loader-h6 placeholder-bar"></h6>
                                            </div>
                                            <div class="col-xl-4 text-center hm-pos-box-rgt py-3 d-none d-xl-flex">
                                                <a class="btn text-white">
                                                    <span class="d-block go-medium lead text-uppercase pt-3">
                                                    </span>
                                                    <svg id="Group_37" data-name="Group 37"
                                                        xmlns="http://www.w3.org/2000/svg" width="24.95" height="5.78"
                                                        viewBox="0 0 24.95 5.78">
                                                        <circle id="Ellipse_10" data-name="Ellipse 10" cx="2.89"
                                                            cy="2.89" r="2.89" fill="#606060" />
                                                        <ellipse id="Ellipse_10_copy" data-name="Ellipse 10 copy"
                                                            cx="2.87" cy="2.89" rx="2.87" ry="2.89"
                                                            transform="translate(9.62)" fill="#606060" />
                                                        <ellipse id="Ellipse_10_copy_2" data-name="Ellipse 10 copy 2"
                                                            cx="2.87" cy="2.89" rx="2.87" ry="2.89"
                                                            transform="translate(19.21)" fill="#606060" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </ng-container>
                        <ng-template #loaded>
                            <ng-container *ngIf="isRecommmended">
                                <div class="col-xl-3 col-6 p-01 pointer" *ngFor="let movie of popularMovieList | slice:0:10; index as i">
                                    <div class="hm-pos-box rounded" (click)="onMovieSelect(movie)">
                                        <div class="p-3 position-relative bg-0F1628">
                                            <div class="show-loader-div" [hidden]="!imageLoader"></div>
                                            <div class="circle">
                                                <!-- <img class="w-100 mw-100 rounded-circle circle1 p-2" src="{{movie.posterUrl}}" alt="Poster" [hidden]="imageLoader" (load)="this.imageLoader = false;" /> -->
                                                <ngx-picture src="{{movie.posterUrl|splitAndGet:'~':0}}" alt="Movie Poster"
                                                    class="" [lazyLoad]="false">
                                                    <ng-template #imgTemplate let-imageData>
                                                        <img class="w-100 mw-100 rounded-circle circle1 p-2"
                                                            [src]="imageData.src" [alt]="imageData.alt"
                                                            [hidden]="imageLoader" (load)="this.imageLoader = false;" />
                                                    </ng-template>
                                                </ngx-picture>
                                            </div>
                                        </div>

                                        <div class="container-fluid bg-secondary px-3">
                                            <div class="row align-items-center">
                                                <div class="col-xl-8 hm-pos-box-lft">
                                                    <h5 class="pt-3 text-white go-medium font-1-2 text-truncate">
                                                        {{ movie.movieName }}
                                                    </h5>
                                                    <h6 class="text-white go-light text-uppercase font-0-875">
                                                        <span class="border-right pr-2 mr-2">{{ movie.duration }}
                                                        </span>
                                                        <span *ngFor="let genre of movie.genres; let i = index">
                                                            <span *ngIf="i < 1" style="font-size: 12px">
                                                                {{ genre.genreName }}</span>
                                                        </span>
                                                    </h6>
                                                </div>
                                                <div class="col-xl-4 text-center hm-pos-box-rgt py-3 d-none d-lg-block">
                                                    <a class="btn text-white">
                                                        <span class="d-block go-medium font-1-1 text-uppercase pt-3">
                                                            Rent
                                                        </span>
                                                        <svg id="Group_37" data-name="Group 37"
                                                            xmlns="http://www.w3.org/2000/svg" width="24.95" height="5.78"
                                                            viewBox="0 0 24.95 5.78">
                                                            <circle id="Ellipse_10" data-name="Ellipse 10" cx="2.89"
                                                                cy="2.89" r="2.89" fill="#606060" />
                                                            <ellipse id="Ellipse_10_copy" data-name="Ellipse 10 copy"
                                                                cx="2.87" cy="2.89" rx="2.87" ry="2.89"
                                                                transform="translate(9.62)" fill="#606060" />
                                                            <ellipse id="Ellipse_10_copy_2" data-name="Ellipse 10 copy 2"
                                                                cx="2.87" cy="2.89" rx="2.87" ry="2.89"
                                                                transform="translate(19.21)" fill="#606060" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!isRecommmended">
                                <div class="col-xl-3 col-6 p-01 pointer" *ngFor="let movie of popularMovieList; index as i">
                                    <div class="hm-pos-box rounded" (click)="onMovieSelect(movie)">
                                        <div class="p-3 position-relative bg-0F1628">
                                            <div class="show-loader-div" [hidden]="!imageLoader"></div>
                                            <div class="circle">
                                                <!-- <img class="w-100 mw-100 rounded-circle circle1 p-2" src="{{movie.posterUrl}}" alt="Poster" [hidden]="imageLoader" (load)="this.imageLoader = false;" /> -->
                                                <ngx-picture src="{{movie.posterUrl|splitAndGet:'~':0}}" alt="Movie Poster"
                                                    class="" [lazyLoad]="false">
                                                    <ng-template #imgTemplate let-imageData>
                                                        <img class="w-100 mw-100 rounded-circle circle1 p-2"
                                                            [src]="imageData.src" [alt]="imageData.alt"
                                                            [hidden]="imageLoader" (load)="this.imageLoader = false;" />
                                                    </ng-template>
                                                </ngx-picture>
                                            </div>
                                        </div>
    
                                        <div class="container-fluid bg-secondary px-3">
                                            <div class="row align-items-center">
                                                <div class="col-xl-8 hm-pos-box-lft">
                                                    <h5 class="pt-3 text-white go-medium font-1-2 text-truncate">
                                                        {{ movie.movieName }}
                                                    </h5>
                                                    <h6 class="text-white go-light text-uppercase font-0-875">
                                                        <span class="border-right pr-2 mr-2">{{ movie.duration }}
                                                        </span>
                                                        <span *ngFor="let genre of movie.genres; let i = index">
                                                            <span *ngIf="i < 1" style="font-size: 12px">
                                                                {{ genre.genreName }}</span>
                                                        </span>
                                                    </h6>
                                                </div>
                                                <div class="col-xl-4 text-center hm-pos-box-rgt py-3 d-none d-lg-block">
                                                    <a class="btn text-white">
                                                        <span class="d-block go-medium font-1-1 text-uppercase pt-3">
                                                            Rent
                                                        </span>
                                                        <svg id="Group_37" data-name="Group 37"
                                                            xmlns="http://www.w3.org/2000/svg" width="24.95" height="5.78"
                                                            viewBox="0 0 24.95 5.78">
                                                            <circle id="Ellipse_10" data-name="Ellipse 10" cx="2.89"
                                                                cy="2.89" r="2.89" fill="#606060" />
                                                            <ellipse id="Ellipse_10_copy" data-name="Ellipse 10 copy"
                                                                cx="2.87" cy="2.89" rx="2.87" ry="2.89"
                                                                transform="translate(9.62)" fill="#606060" />
                                                            <ellipse id="Ellipse_10_copy_2" data-name="Ellipse 10 copy 2"
                                                                cx="2.87" cy="2.89" rx="2.87" ry="2.89"
                                                                transform="translate(19.21)" fill="#606060" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                    <ng-template #noMoviesTemplate>
                        <div class="col-xl-12 ">
                            <div class="go-medium text-white font-1-5 text-center py-3 pt-3">No Movies Available for
                                Selected Location</div>
                            <p class="text-center py-4 mb-0">
                                <img class="w-50 mw-100" src="assets/images/img/no-date.svg">
                            </p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf=isLoading>
    <div id="pause" class="d-flex align-items-center justify-content-center">
        <img src="../../../assets/images/img/Preloader-03.gif" id="spinner" />

    </div>
</div> -->
<!-- <mp-card *ngIf=isLoading></mp-card> -->
<mp-footer></mp-footer>