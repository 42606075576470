<mp-header></mp-header>

<div class="container-fluid px-5">
    <div class="row px-5 bg-secondary shadow-custom-5 py-4">
        <div class="col-xl-12">
            <div class="row align-items-center">
                <div class="col-xl-1">
                    <div class="back-btn position-relative z-index-99">
                        <svg id="Component_49_32" data-name="Component 49 – 32" xmlns="http://www.w3.org/2000/svg"
                            width="38" height="38" viewBox="0 0 38 38">
                            <circle id="Ellipse_39" data-name="Ellipse 39" class="cls-1" cx="19" cy="19" r="19"
                                (click)="route()"></circle>
                            <g id="Group_450" data-name="Group 450" transform="translate(-134.505 -201.219)">
                                <path id="Polygon_9" data-name="Polygon 9" class="cls-2" d="M5.5,0,11,6H0Z"
                                    transform="translate(142.505 226.219) rotate(-90)"></path>
                                <rect id="Rectangle_60" data-name="Rectangle 60" class="cls-2" width="17.492"
                                    height="2.018" transform="translate(147.683 219.709)"></rect>
                            </g>
                        </svg>
                    </div>
                </div>
                <!-- <div class="col-xl-3 position-absolute text-center">
                    <img class="w-35 pt-3" src="assets/images/img/user.svg">
                </div> -->
                <div class="offset-2 col-xl-6 text-center">
                    <span class="go-medium text-white h4 ">
                        Privacy Policy
                    </span>
                </div>
                <div class="col-xl-3 text-right" (click)="route()">
                    <img class="mw-100 w-9 hdr-ico" src="assets/images/img/home.svg">
                </div>
            </div>
        </div>
    </div>
    <div class="row px-5 shadow-custom-5 py-5">
        <div class="offset-1 col-xl-10">
            <div class="go-book text-white lead  my-4">
                Privacy policy
            </div>
            <div class="go-book text-white lead  my-4">

                Effective & last updated September 20, 2020

            </div>
            <div class="go-book text-white lead  my-4">
                This Privacy Policy applies to the services offered on the www.moviepanda.in website operated by Lixo
                Technology Pvt. Ltd., Please read this Privacy Policy carefully before using the Movie Panda site. Usage
                of the Movie Panda website is conditional upon you agreeing to be bound by this privacy policy.
            </div>
            <div class="go-book text-white lead  my-4">
                Movie Panda respects your privacy and recognizes the need to protect your personal information (any
                information by which you can be identified, such as name, address, financial information, and telephone
                number) you share with us. We would like to assure you that we follow appropriate standards when it
                comes to protecting your privacy on our web sites and applications.
            </div>
            <div class="go-book text-white lead  my-4">
                In general, you can visit Movie Panda without telling us who you are or revealing any personal
                information about yourself. We track the Internet address of the domains from which people visit us and
                analyze this data for trends and statistics, but the individual user remains anonymous.
            </div>
            <div class="go-book text-white lead  my-4">
                Please note that our Privacy Policy forms part of our Terms and conditions available at <a
                    class="go-book text-primary lead  text-decoration-none"
                    href="https://www.moviepanda.in/#/termsandconditions">https://www.moviepanda.in/#/termsandconditions</a>
            </div>
            <div class="go-book text-white lead  my-4">
                A transaction on Movie Panda is to be conducted by persons above the age of 18 years only. If you are
                under 18 years of age, you are not allowed to make a transaction in Movie Panda. It is the duty of the
                legal guardians of all persons below 18 years of age to ensure that their wards do not make a
                transaction without their supervision on Movie Panda. It shall be automatically deemed that by allowing
                any person below the age of 18 years to transact on Movie Panda, their legal guardians have expressly
                consented to their use and we disclaim any liability arising from your failure to do so.
            </div>
            <div class="go-book text-white lead  my-4">
                We may collect your personal data when you:
            </div>
            <div class="go-book text-white lead  my-4">
                a) create an account with us or</div>
            <div class="go-book text-white lead  my-4">
                b) otherwise provide us with your personal data or</div>
            <div class="go-book text-white lead  my-4">
                c) use of any related services connected to Movie Panda or</div>
            <div class="go-book text-white lead  my-4">
                d) participate in contests and surveys, apply for a job, or otherwise participate in activities we
                promote that might require information about you; or</div>
            <div class="go-book text-white lead  my-4">
                e) complete contact forms or request newsletters or other information from us (ex: email)
                When you access Movie Panda, we will automatically collect your computer data, which may include
            </div>
            <div class="go-book text-white lead  my-4">When you access Movie Panda, we will automatically collect your
                computer data, which may include</div>
            <div class="go-book text-white lead  my-4">
                a) browser type</div>
            <div class="go-book text-white lead  my-4">
                b) IP address</div>
            <div class="go-book text-white lead  my-4">
                c) language</div>
            <div class="go-book text-white lead  my-4">
                d) operating system</div>
            <div class="go-book text-white lead  my-4">
                e) cookies and the ID and location of your device</div>
            <div class="go-book text-white lead  my-4">
                f) the state or country from which you accessed Movie Panda</div>
            <div class="go-book text-white lead  my-4">
                g) the pages/videos you view</div>
            <div class="go-book text-white lead  my-4">
                h) length of time spent on pages/videos</div>
            <div class="go-book text-white lead  my-4">
                i) the services you use and the manner in which you use such services (e.g., the content you access,
                view, click on, search for, transact etc.)</div>
            <div class="go-book text-white lead  my-4">
                j) the date and time of your visit</div>
            <div class="go-book text-white lead  my-4">
                k) metadata, logs files, error logs</div>
            <div class="go-book text-white lead  my-4">
                l) other geographic and demographic information; and</div>
            <div class="go-book text-white lead  my-4">
                m) other hardware and software information.</div>
            <div class="go-book text-white lead  my-4">
                n) which pop up or push messages you might have seen and responded to

            </div>
            <div class="go-book text-white lead  my-4">
                This computer data is collected for analysis and evaluation in order to help us improve Movie Panda, the
                services we provide and to enhance your experience by providing you with better services and benefits
                that you shall be entitled to.</div>
            <div class="go-book text-white lead  my-4">

                We also collect information about your movie preferences. For example, we may collect, among other
                things, information such as what movies you purchase tickets to or what theatres you attend. We may save
                information sent or posted to the Service. For example, we may save messages posted in our chat rooms or
                other message areas or feedback left for other users. We may collect correspondence, such as emails or
                letters, sent to us. We may collect user responses to online polls, ads, surveys, electronic newsletters
                and questionnaires.

            </div>
            <div class="go-book text-white lead  my-4">
                To the extent that such computer data is maintained in a manner that identifies your name or contact
                information, it will be treated as personal data; otherwise, such computer data will be treated as
                non-personal data.
            </div>
            <div class="go-book text-white lead  my-4">
                The personal data we collect from you will be used, or shared with third parties (including related
                companies and third party service providers), for some or all of the following purposes:
            </div>
            <div class="go-book text-white lead  my-4">
                a. creating or maintaining any account or profile you may have with us</div>
            <div class="go-book text-white lead  my-4">
                b. to track your activity on Movie Panda</div>
            <div class="go-book text-white lead  my-4">
                c. verifying and carrying out financial transactions in relation to any payments or transfers you make
            </div>
            <div class="go-book text-white lead  my-4">
                d. carrying out research and analytics on our users’ demographics and behaviour</div>
            <div class="go-book text-white lead  my-4">
                e. to personalise and/tailor any communications that we may send you and provide you with products,
                services or information we think you may find useful or which you have requested from us or have
                expressed an interest in</div>
            <div class="go-book text-white lead  my-4">
                f. to personalise and enhance user experience</div>
            <div class="go-book text-white lead  my-4">
                g. determining and verifying your eligibility for certain marketing or transaction events and other
                features of Movie Panda</div>
            <div class="go-book text-white lead  my-4">
                h. enforcing our terms of use; and/or</div>
            <div class="go-book text-white lead  my-4">
                i. communicating with you in relation to your account and alerting you to the latest developments</div>
            <div class="go-book text-white lead  my-4">
                j. to enable us to administer any competitions or other offers/promotions which you enter into for fraud
                screening and prevention purposes

            </div>
            <div class="go-book text-white lead  my-4">
                When you register an account or otherwise provide us with your personal data, we may also use the
                personal data to send to you marketing and/or promotional materials about us and our services from time
                to time. You can unsubscribe from receiving the marketing information at any time by using the
                unsubscribe function within the electronic marketing material.</div>
            <div class="go-book text-white lead  my-4">
                In order to provide our products and services to you or to otherwise fulfil contractual arrangements
                that we have with you, we may need to appoint other organisations to carry out some of the data
                processing activities on our behalf. These may include, for example, payment processing organisations,
                delivery organisations, fraud prevention and screening and credit risk management companies, and mailing
                houses.
            </div>
            <div class="go-book text-white lead  my-4">
                We may share your data with advertising networks and/or social media platforms for the purposes of
                selecting and serving relevant adverts to you via those networks/platforms, and to search engine and
                analytics providers.
            </div>
            <div class="go-book text-white lead  my-4">
                We as an intermediary are responsible to facilitate a transaction between you and the ultimate event
                organiser, cinema theatre and/or other entertainment provider so as to enable you to book a ticket for
                an entertainment event, movie, sport etc. For us to be able to facilitate this transaction and otherwise
                fulfil our contractual arrangements with these ultimate entertainment providers, we may have to share
                your personal data with them so that they are able to verify the accuracy of the information you have
                shared with us while issuing your valid entry/ticket, to provide you with services and benefits that you
                may be entitled to and to conduct their own analysis.
            </div>
            <div class="go-book text-white lead  my-4">
                Movie Panda and its services may contain links to third-party websites, including identity verification
                and social networking websites. Your use of these features may result in the collection or sharing of
                information about you, depending on the feature. Please be aware that we are not responsible for the
                content or privacy practices of other websites or services to which we link. We do not endorse or make
                any representations about third-party websites or services. The personal data you choose to provide to
                or that is collected by these third parties is not covered by our Privacy Statement. We strongly
                encourage you to read such third parties’ privacy statements.
            </div>
            <div class="go-book text-white lead  my-4">
                We may share:
            </div>
            <div class="go-book text-white lead  my-4">
                (A) your personal data with any person or entity where we believe in good faith that such disclosure is
                necessary to:
            </div>
            <div class="go-book text-white lead  my-4">
                (i) comply with the law or in response to a subpoena, court order, government request, or other legal
                process.</div>
            <div class="go-book text-white lead  my-4">
                (ii) produce relevant documents or information in connection with litigation, arbitration, mediation,
                adjudication, government or internal investigations, or other legal or administrative proceedings;</div>
            <div class="go-book text-white lead  my-4">
                (iii) protect the interests, rights, safety, or property of Movie Panda or others;</div>
            <div class="go-book text-white lead  my-4">
                (iv) otherwise as permitted under applicable law.

            </div>
            <div class="go-book text-white lead  my-4"> (B) personal data about our visitors, customers, or former
                customers with the following types of
                companies that perform services on our behalf or with whom we have joint marketing agreements:</div>
            <div class="go-book text-white lead  my-4">
                (i) Non-financial companies such as envelope stuffers, fulfilment service providers, payment processors,
                data processors, customer/support services, etc., and/or</div>
            <div class="go-book text-white lead  my-4">
                (ii) Financial service providers such as companies engaged in banking/payments/facilitating financial
                transactions.

            </div>
            <div class="go-book text-white lead  my-4">In sharing your personal data with such parties, we will
                reasonably endeavour to ensure that the third
                parties and our affiliates keep your personal data secure from unauthorised access, collection, use,
                disclosure, or similar risks and retain your personal data only for as long as they need your personal
                data to achieve the abovementioned purposes.</div>
            <div class="go-book text-white lead  my-4">
                You acknowledge that, notwithstanding this Privacy Policy, we have at all times the right to disclose
                your personal data to any legal, regulatory, governmental, tax, law enforcement or other authorities
                pursuant to applicable law and our legal obligations. This may arise from any investigation, order, or
                request by such parties. To the furthest extent permissible by law, you agree not to take any action
                and/or waive your rights to take any action against us for the disclosure of your personal data in these
                circumstances.
            </div>
            <div class="go-book text-white lead  my-4">
                If any disclosure of your personal data involves the transfer of your personal data by Movie Panda out
                of India, we will take steps to reasonably ensure that the receiving jurisdiction has in place a
                standard of protection accorded to personal data that is comparable to the protection under India’s data
                protection laws.
            </div>
            <div class="go-book text-white lead  my-4">
                We have implemented reasonable security arrangements including physical, administrative, technical, and
                electronic security measures to protect against the loss, misuse, and alteration of your personal data.
                We are PCI DSS certified which means that the data you submit to us is secure and protected against loss
                or theft in accordance with the globally accepted data security standards. Despite our best efforts,
                however, no security measures are perfect or impenetrable. In the event where you believe your privacy
                has been breached, please contact us immediately.
            </div>
            <div class="go-book text-white lead  my-4">
                It is your responsibility to protect any passwords you require to access your account, on Movie Panda.
                Please use unique numbers, letters and special characters, and do not share your password with anyone.
                If you do share your password with others, you will be responsible for all actions taken in the name of
                your account and the consequences. If you lose control of your password, you may lose substantial
                control over your personal data and other information submitted to us. You could also be subject to
                legally binding actions taken on your behalf. Therefore, if your password has been compromised for any
                reason or if you have grounds to believe that your password has been compromised, you should immediately
                contact us and change your password.
            </div>
            <div class="go-book text-white lead  my-4">
                You undertake to treat your password and other confidential information in relation to the use of Movie
                Panda and its services confidentially, and we disclaim any liability arising from your failure to do so.
            </div>
            <div class="go-book text-white lead  my-4">
                Some of our web pages use "cookies" so that we can better serve you with customized information when you
                return to our site. Cookies are identifiers which a web site can send to your browser to keep on your
                computer to facilitate your next visit to our site. You can set your browser to notify you when you are
                sent a cookie, giving you the option to decide whether or not to accept it. The information we collect
                and analyze is used to improve our service to you. Please note, if you refuse cookies this may mean that
                you can’t use some of the additional features of our website and may not be able to access certain parts
                of the website.
            </div>
            <div class="go-book text-white lead  my-4">
                You may update any of your personal data we possess by contacting us at <a
                    class="go-book text-primary lead  text-decoration-none"
                    [href]="mailto('helpdesk@moviepanda.in', '')"> helpdesk@moviepanda.in</a>and we
                will endeavour to serve your request for updation. Lixo Technologies Pvt Ltd reserves the right to accept or reject
                requests made for updating of personal data and in case of any such refusal we will also endeavour to
                inform you the reason for such refusal.
            </div>
            <div class="go-book text-white lead  my-4">
                You may communicate your objection to our continual use and/or disclosure of your personal data for any
                of the purposes and in the manner as stated above at any time by contacting us at <a
                    class="go-book text-primary lead  text-decoration-none"
                    [href]="mailto('support@moviepanda.in', '')">support@moviepanda.in</a>
            </div>
            <div class="go-book text-white lead  my-4">
                If you do not wish for us to continue using your information, and/or disclose your personal data for any
                of the purposes and in the manner as stated above at any time, you may opt out of providing the same by
                contacting us at <a class="go-book text-primary lead  text-decoration-none"
                    [href]="mailto('support@moviepanda.in', '')">support@moviepanda.in</a>. Please note that if you
                choose not to provide the Information
                we request, you can still visit Movie Panda, but you may not be able to access certain options, offers
                and services. Movie Panda reserves all legal rights and remedies in such an event.
            </div>
            <div class="go-book text-white lead  my-4">
                We retain personal data only for as long as necessary to provide the services you have requested and
                thereafter for a variety of legitimate legal or business purposes. These might include retention
                periods:
            </div>
            <div class="go-book text-white lead  my-4">
                a. mandated by law, contract or similar obligations applicable to our business operations;</div>
            <div class="go-book text-white lead  my-4"> b. for preserving, resolving, defending or enforcing our
                legal/contractual rights; or needed to maintain
                adequate and accurate business and financial records.
                If our privacy policy changes in the future, it will be posted here and a new effective date will be
                shown. You should access our privacy policy regularly to ensure you understand our current policies.
                Please reference the privacy policy in your subject line. Movie Panda will attempt to respond to all
                reasonable concerns or inquiries within 30 business days of receipt.
            </div>
            <div class="go-book text-white lead  my-4">
                If you have an enquiry or a complaint about the way we handle your personal information, or to seek to
                exercise your privacy rights in relation to the personal information we hold about you, you may contact
                our customer grievance officer as follows:
            </div>
            <div class="go-book text-white lead  my-4">We use standard security measures.</div>
            <div class="go-book text-white lead  my-4">We have security measures in place to protect your information.
                The standard security measures we use will depend on the type of information collected. However, the
                Internet is not 100% secure. We cannot promise that your use of our sites will be completely safe. We
                encourage you to use caution when using the Internet. This includes not sharing your passwords. If you
                think that an unauthorized account has been created using your name, contact us.</div>
            <div class="go-book text-white lead  my-4">We store information both in and outside of India.</div>
            <div class="go-book text-white lead  my-4">If you live outside of India, you understand and agree that we
                may transfer your information to India. This site is subject to Indian laws, which may not afford the
                same level of protection of those in your country.</div>
            <div class="go-book text-white lead  my-4">What we will do if there is an update to this policy.</div>
            <div class="go-book text-white lead  my-4">From time to time we may change our privacy practices. We will
                also post an updated copy on our website. Please check our site periodically for updates.</div>
            <div class="go-book text-white lead  my-4">By viewing this site you are deemed to agree to jurisdiction of
                the courts at Chennai, India in respect of any action arising therefrom or related thereto.</div>
            <div class="go-book text-white lead  my-4">By Email:<a
                    class="go-book text-primary lead  text-decoration-none"
                    [href]="mailto('support@moviepanda.in', '')"> support@moviepanda.in</a></div>
            <div class="go-book text-white lead  my-4">By Mail: Lixo Technologies Pvt Ltd.,</div>
            <div class="go-book text-white lead  my-4">#3C2, Seethakathi Business Center,</div>
            <div class="go-book text-white lead  my-4">10th Floor, Annasalai, Chennai, 60006</div>
            <div class="go-book text-white lead  my-4">For the purposes of the GDPR, our Customer Grievance Officer is
                also our Data Protection Officer (DPO).</div>
            <div class="go-book text-white lead  my-4">
                We will respond to all requests, inquiries or concerns within a reasonable period.

            </div>
            <div class="go-book text-white lead  my-4">Movie Panda Name and Logo</div>
            <div class="go-book text-white lead  my-4">Lixo Technology is a registered trademark of Movie Panda.</div>
        </div>
    </div>
</div>



<mp-footer></mp-footer>